import { createSlice } from '@reduxjs/toolkit';
import {
  BanUserBody,
  GetUserSevicesParams,
  GetUsersParams,
  SendPushBody,
  UpdateUserBalance,
  UpdateUserSevicesParams,
  User
} from '../../resources/types/usersTypes';
import { AppThunk, RootState } from '../store';
import axios from '../../resources/axios';
import { handleDefaultErrors } from '../../resources/functions';
import { Pagination } from '../../resources/types/commonTypes';
import { PAGINATION } from '../../resources/constants';

interface UsersState {
  items: User[];
  totalBalance: number;
  pagination: Pagination;
  userById: User;
}

const initialState: UsersState = {
  items: [],
  totalBalance: 0,
  pagination: PAGINATION,
  userById: {
    middleName: '',
    firstName: '',
    lastName: '',
    type: 'normal',
    balance: 0,
    birthday: '',
    email: '',
    password: '',
    phone: ''
  }
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getUsers: (state, action) => {
      state.items = action.payload.users;
      state.pagination = action.payload.pagination;
      state.totalBalance = action.payload.userBalanceSum;
    },
    getUser: (state, action) => {
      state.userById = action.payload;
    },
    updateUser: (state, action) => {
      state.userById = { ...state.userById, ...action.payload };
    },
    clearUser: state => {
      state.userById = initialState.userById;
    }
  }
});

const { getUsers, getUser } = usersSlice.actions;

export const { clearUser, updateUser } = usersSlice.actions;

export const getUsersAsync =
  (params?: GetUsersParams): AppThunk =>
  async dispatch => {
    try {
      const response = await axios.get('/users', { params });
      dispatch(getUsers(response.data));
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const getUserAsync =
  (userId: string): AppThunk =>
  async dispatch => {
    try {
      const response = await axios.get(`/users/${userId}`);
      dispatch(getUser(response.data.user));
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const updateUserAsync =
  (userId: string, body: User, success?: () => void): AppThunk =>
  async () => {
    try {
      await axios.put(`/users/${userId}`, body);
      if (success) success();
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const updateUserBalanceAsync =
  (userId: string, body: UpdateUserBalance, success?: () => void): AppThunk =>
  async () => {
    try {
      await axios.put(`/users/${userId}/balance`, body);
      if (success) success();
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const sendPushAsync =
  (body: SendPushBody, success?: () => void): AppThunk =>
  async () => {
    try {
      await axios.post('/admins/push_notifications', body);
      if (success) success();
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const sendToUserPushAsync =
  (userId?: string, text?: string, success?: () => void): AppThunk =>
  async () => {
    if (!text || !userId) return;
    try {
      await axios.post('/admins/push_notifications', {
        text,
        userIds: [userId]
      });
      if (success) success();
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const expireUserSession =
  (userId?: string): AppThunk =>
  async () => {
    if (!userId) return;
    try {
      await axios.post('/admins/logout_user', {
        userId
      });
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const banUser =
  (userId: string, body: BanUserBody): AppThunk =>
  async () => {
    if (!userId) return;
    try {
      await axios.put(`/users/${userId}/ban`, body);
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const getUserSessionsAsync = async (params?: GetUserSevicesParams) => {
  try {
    const response = await axios.get('/admins/user_devices', { params });
    return response.data;
  } catch (e) {
    handleDefaultErrors(e);
  }
};

export const updateUserSessionsAsync = async (
  id: string,
  params?: UpdateUserSevicesParams
) => {
  try {
    const response = await axios.put(`/admins/user_devices/${id}`, params);
    return response.data;
  } catch (e) {
    handleDefaultErrors(e);
  }
};

export const selectUsers = (state: RootState) => state.users;

export default usersSlice.reducer;
