import { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getContractsStatusesAsync } from '../../redux/slices/contractsSlice';
import { Promocode, IPromoCodeType } from '../../resources/types/balanceTypes';
import PromocodeModal from '../../modals/PromocodeModal';
import PromoCodesListWrapper from './components/PromoCodesListWrapper';
import { Pagination } from '@mui/material';
import { getPromocodesAsync, selectPromocodes } from '../../redux/slices/promocodesSlice';

const PREFIX = 'PromocodesPage';

const classes = {
  header: `${PREFIX}-header`,
  addButton: `${PREFIX}-addButton`,
  tableContainer: `${PREFIX}-tableContainer`
};

const Root = styled('div')({
  [`& .${classes.header}`]: {
    display: 'flex',
    alignItems: 'center'
  },
  [`& .${classes.addButton}`]: {
    marginLeft: 20
  },
  [`& .${classes.tableContainer}`]: {
    marginTop: 40,
    marginBottom: 40
  }
});

const newPromocode: Promocode = {
  code: "", 
  type: IPromoCodeType.addBalance,
  amount: 0,
  expiresAt: "",
  description: "",
  usingLimit: 0,
  city: "",
  active: true,
};

const PromocodesPage = () => {
  const dispatch = useDispatch();


  const { limit, total, items } = useSelector(selectPromocodes);
  const [page, setPage] = useState(1);
  const [includeReferal, setIncludeReferal] = useState(false);

  const paginationCount = useMemo(
    () => Math.ceil(total / limit),
    [total, limit]
  );

  const onChangePage = useCallback((e: any, nextPage: number) => {
    setPage(nextPage);
  }, []);

  const fetchPromocodes = (page: number, limit: number) => {
    dispatch(getPromocodesAsync({ page, limit, includeReferal }));
  }

  useEffect(() => {
    fetchPromocodes(page, limit)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit]);

  useEffect(() => {
    dispatch(getContractsStatusesAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [open, setOpen] = useState(false);
  const [selectedPromocode, setSelectedPromocode] = useState<Promocode | null>(
    null
  );

  return (
    <Root>
      <PromoCodesListWrapper
        title={'Промокоди'}
        createButtonTitle={'Створити промокод'}
        onCreate={() => {
          setSelectedPromocode(newPromocode);
          setOpen(true);
        }}
        onEdit={(promocode: Promocode) => {
          setOpen(true);
          setSelectedPromocode(promocode);
        }}
        promocodes={items ?? []}
        includeReferal={includeReferal}
        setIncludeReferal={setIncludeReferal}
      />
      <Pagination count={paginationCount} onChange={onChangePage} />
      <PromocodeModal
        selectedPromocode={selectedPromocode}
        open={open}
        setOpen={setOpen}
        setSelectedPromocode={setSelectedPromocode}
        onSuccess={() => fetchPromocodes(page, limit)}
      />
    </Root>
  );
};

export default PromocodesPage;
