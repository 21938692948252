import {
  Box,
  Button,
  Grid,
  Modal,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { styles } from './styles';
import TableCell from '../../components/TableCell';
import { getContractsHistory } from '../../redux/slices/contractsSlice';
import { RootState } from '../../redux/store';
import { Props } from './interface';
import { convertSecondsToHHMMSS } from '../../helpers/time';

const ContractsHistoryModal = (props: Props) => {
  const { userId, scooterId, ...restProps } = props;

  const dispatch = useDispatch();

  const { items: contracts, pages } = useSelector(
    (state: RootState) => state.contracts.history
  );

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (props.open) {
      dispatch(
        getContractsHistory({
          userId,
          scooterId,
          page,
          limit: 20,
          active: false
        })
      );
    } else {
      setPage(1);
    }
  }, [dispatch, userId, scooterId, props.open, page]);

  return (
    <Modal {...restProps}>
      <Box sx={styles.modal}>
        <Typography sx={styles.title}>Історія</Typography>

        <Grid container spacing={2} direction='column' sx={{}}>
          <Grid item overflow={'auto'}>
            <TableContainer sx={styles.tableContainer}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell sx={styles.tableCell}>Клієнт</TableCell>
                    <TableCell sx={styles.tableCell}>Телефон</TableCell>
                    <TableCell sx={styles.tableCell}>Номер скутера</TableCell>
                    <TableCell sx={styles.tableCell}>Початок поїздки</TableCell>
                    <TableCell sx={styles.tableCell}>Кінець поїздки</TableCell>
                    <TableCell sx={styles.tableCell}>Час у дорозі</TableCell>
                    <TableCell sx={styles.tableCell}>Час у паузі</TableCell>
                    <TableCell sx={styles.tableCell}>
                      Час у бронюванні
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      Тривалість поїздки
                    </TableCell>
                    <TableCell sx={styles.tableCell}>Відстань</TableCell>
                    <TableCell sx={styles.tableCell}>Вартість</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contracts.map(contract => (
                    <TableRow key={contract._id}>
                      <TableCell sx={styles.tableCell}>
                        {contract.user.lastName} {contract.user.firstName}{' '}
                        {contract.user.middleName}
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        {contract.user.phone}
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        {contract.scooter?.name}
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        {moment(contract.createdAt).format(
                          'DD MMM YYYY, HH:mm:ss'
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        {moment(contract.closedDate).format(
                          'DD MMM YYYY, HH:mm:ss'
                        )}
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        {contract?.historyDetails?.normal?.duration
                          ? convertSecondsToHHMMSS(
                              contract?.historyDetails?.normal?.duration
                            )
                          : '00:00:00'}
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        {contract?.historyDetails?.pause?.duration
                          ? convertSecondsToHHMMSS(
                              contract?.historyDetails?.pause?.duration
                            )
                          : '00:00:00'}
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        {contract?.historyDetails?.reservation?.duration
                          ? convertSecondsToHHMMSS(
                              contract?.historyDetails?.reservation?.duration
                            )
                          : '00:00:00'}
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        {moment.utc(contract.period * 1000).format('mm:ss')}
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        {contract.mileage}
                      </TableCell>
                      <TableCell sx={styles.tableCell}>
                        {contract.sum}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item sx={styles.pagination}>
            <Pagination
              count={pages}
              onChange={(event, nextPage) => setPage(nextPage)}
            />
          </Grid>
        </Grid>

        <Stack sx={styles.exitButtonContainer}>
          <Button onClick={() => props.onClose?.({}, 'backdropClick')}>
            Вихід
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ContractsHistoryModal;
