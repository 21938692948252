import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import axios from '../../resources/axios';
import { handleDefaultErrors } from '../../resources/functions';
import {
  Contract,
  GetActiveContractsParams,
  GetContractsHistoryParams
} from '../../resources/types/contractsTypes';

interface StatusesState {
  items: string[];
  shouldUpdate: boolean;
}

interface ActiveContractsState {
  items: Contract[];
  count: number;
}

interface ContractsHistoryState {
  items: Contract[];
  pages: number;
}

interface ContractsState {
  statuses: StatusesState;
  activeContracts: ActiveContractsState;
  history: ContractsHistoryState;
}

const initialState: ContractsState = {
  statuses: {
    items: [],
    shouldUpdate: true
  },
  activeContracts: {
    items: [],
    count: 0
  },
  history: {
    items: [],
    pages: 0
  }
};

export const contractsSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    getContractsStatuses: (state, action) => {
      state.statuses.items = action.payload;
      state.statuses.shouldUpdate = false;
    },
    getActiveContracts: (state, action) => {
      state.activeContracts.items = action.payload.contracts;
      state.activeContracts.count = action.payload.contractsCount;
    },
    setContractsHistory: (
      state,
      action: PayloadAction<ContractsHistoryState>
    ) => {
      state.history = action.payload;
    }
  }
});

export const { getContractsStatuses, getActiveContracts, setContractsHistory } =
  contractsSlice.actions;

export const getContractsStatusesAsync =
  (): AppThunk => async (dispatch, getState) => {
    if (!getState().contracts.statuses.shouldUpdate) return;
    try {
      const response = await axios.get('/contracts/statuses');
      dispatch(getContractsStatuses(response.data));
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const getActiveContractsAsync =
  (params?: GetActiveContractsParams): AppThunk =>
  async dispatch => {
    try {
      const response = await axios.get('/contracts/active', { params });
      dispatch(getActiveContracts(response.data));
      return response.data;
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const exitContractAsync =
  (contrId: string, success?: () => void): AppThunk =>
  async () => {
    try {
      await axios.put(`/contracts/${contrId}/exit`);
      if (success) success();
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const getContractsHistory =
  (params: GetContractsHistoryParams): AppThunk =>
  async dispatch => {
    try {
      const response = await axios.get(`/contracts/active`, { params });
      dispatch(
        setContractsHistory({
          items: response.data.contracts,
          pages: params.limit
            ? Math.ceil(response.data.contractsCount / params.limit)
            : 0
        })
      );
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const getContractСoordsHistoryAsync = async (contrId: string) => {
  try {
    const response = await axios.get(`/contracts/${contrId}/admin`);
    return response.data;
  } catch (error) {
    handleDefaultErrors(error);
  }
};

export const selectContracts = (state: RootState) => state.contracts;

export default contractsSlice.reducer;
