import { Box, Grid } from '@mui/material';
import moment, { Moment } from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import usePermissions from '../../hooks/usePermissions';
import {
  fetchCitiesBalance,
  fetchStatistics,
  selectCitiesBalance,
  selectStatistics
} from '../../redux/slices/balanceSlice';
import { selectCities } from '../../redux/slices/citiesSlice';
import { getActiveContractsAsync } from '../../redux/slices/contractsSlice';
import Title from '../../components/Title';
import StyledBarChart from './StyledBarChart';
import { TripState } from './types';
import { DateRange } from '@material-ui/pickers';
import { selectAuth } from '../../redux/slices/authSlice';
import { selectAdmins } from '../../redux/slices/adminsSlice';
import UsersStatistic from './components/UsersStatistic';

const StatisticPage = () => {
  const checkPermission = usePermissions();
  const dispatch = useDispatch();
  const citiesBalance = useSelector(selectCitiesBalance);
  const wasteCitiesBalance = useSelector(selectStatistics);
  const cities = useSelector(selectCities);
  const auth = useSelector(selectAuth);
  const adminInfo = useSelector(selectAdmins);

  const isSuperAdmin = useMemo(() => {
    return auth.info?.type === 'superAdmin';
  }, [auth.info?.type]);

  const currentCityId = useMemo(() => {
    if (isSuperAdmin) {
      return undefined;
    }
    const city = cities.items.find(item => item._id === adminInfo.currentCity);
    if (city) {
      return city._id;
    }
  }, [adminInfo.currentCity, cities.items, isSuperAdmin]);

  const [topUpPeriod, setTopUpPeriod] = useState<DateRange<Moment | null>>([
    moment().set({ date: 1, hours: 0, minutes: 0, seconds: 0 }),
    moment().set({ hours: 23, minutes: 59, seconds: 59 })
  ]);
  const [wastePeriod, setWastePeriod] = useState<DateRange<Moment | null>>([
    moment().set({ date: 1, hours: 0, minutes: 0, seconds: 0 }),
    moment().set({ hours: 23, minutes: 59, seconds: 59 })
  ]);
  const [tripPeriod, setTripPeriod] = useState<DateRange<Moment | null>>([
    moment().set({ date: 1, hours: 0, minutes: 0, seconds: 0 }),
    moment().set({ hours: 23, minutes: 59, seconds: 59 })
  ]);

  const [tripState, setTripState] = useState<TripState[] | []>([]);

  // Amount of trip stat
  useEffect(() => {
    if (checkPermission('contracts')) {
      const promises = cities.items.map(city => {
        if (!isSuperAdmin && currentCityId !== city._id) return null;
        return dispatch(
          getActiveContractsAsync({
            active: false,
            from: moment(tripPeriod[0]).unix() * 1000,
            to: moment(tripPeriod[1]).endOf('day').unix() * 1000,
            cityId: city?._id
          })
        );
      });

      Promise.all(promises)
        .then(res => {
          const items: any[] = res ?? [];
          return items.map((item, idx) => ({
            cityName: cities.items[idx].cityName,
            count: item?.contractsCount ?? 0
          }));
        })
        .then(data => {
          return data.filter(item => item.count);
        })
        .then(data => {
          setTripState(data);
        });
    }
  }, [cities.items, tripPeriod]);

  // Top up balance statwastePeriod
  useEffect(() => {
    if (isSuperAdmin || checkPermission('statistics')) {
      dispatch(
        fetchCitiesBalance({
          from: moment(topUpPeriod[0])?.toISOString(),
          to: moment(topUpPeriod[1])?.toISOString(),
          cityId: currentCityId
        })
      );
    }
  }, [isSuperAdmin, topUpPeriod]);

  // Waste balance stat
  useEffect(() => {
    if (isSuperAdmin || checkPermission('statistics')) {
      dispatch(
        fetchStatistics({
          dateFrom: wastePeriod[0]?.toISOString(),
          dateTo: wastePeriod[1]?.toISOString(),
          cityId: currentCityId
        })
      );
    }
  }, [wastePeriod]);

  return (
    <Box>
      <Title sx={{ mb: 5 }}>Статистика</Title>

      <Grid
        container
        justifyContent='space-between'
        direction='row'
        spacing={5}
      >
        {isSuperAdmin || checkPermission('statistics') ? (
          <UsersStatistic />
        ) : null}

        <Grid item>
          <StyledBarChart
            data={tripState}
            label='Кількість поїздок'
            barDataKey='count'
            xDataKey='cityName'
            value={tripPeriod}
            setValue={setTripPeriod}
          />
        </Grid>

        <Grid item>
          <StyledBarChart
            data={citiesBalance}
            label='Сума введених коштів на баланс'
            barDataKey='count'
            xDataKey='cityName'
            value={topUpPeriod}
            setValue={setTopUpPeriod}
          />
        </Grid>

        <Grid item>
          <StyledBarChart
            isWaste
            data={wasteCitiesBalance}
            label='Сума використаних коштів за балансом'
            barDataKey='count'
            xDataKey='cityName'
            value={wastePeriod}
            setValue={setWastePeriod}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default StatisticPage;
