import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import axios from '../../resources/axios';
import { handleDefaultErrors } from '../../resources/functions';

interface Feedback {
  _id: string;
  city: {
    _id: string;
    cityName: string;
  };
  contract: string;
  rate: number;
  description: string;
  scooter: {
    _id: string;
    city: string;
    name: string;
  };
  user: {
    _id: string;
    phone: string;
    firstName: string;
  };
  device?: string;
  createdAt?: string;
  updatedAt?: string;
}

interface FeedbacksState {
  items: Feedback[];
  shouldUpdate: boolean;
  limit: number;
  page: number;
  total: number;
  loading: boolean;
}

const initialState: FeedbacksState = {
  items: [],
  loading: false,
  shouldUpdate: true,
  limit: 20,
  page: 1,
  total: 1
};

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    getFeedbacks: (state, { payload }) => {
      state.items = payload?.items ?? [];
      state.shouldUpdate = false;
      const pageLimit = payload?.pagination?.limit ?? 20;
      state.limit = pageLimit;
      state.page = payload?.pagination?.page ?? 1;
      state.total = payload?.pagination?.total / pageLimit ?? 1;
      state.loading = false;
    },
    setFeedbacksLoading: (state, { payload }) => {
      state.loading = payload;
    }
  }
});

const { getFeedbacks, setFeedbacksLoading } = feedbackSlice.actions;

export const getFeedbacksAsync =
  (params: {
    page: number;
    limit: number;
    cityId: string;
    scooterName?: string;
    dateFrom?: string;
    dateTo?: string;
    userId?: string;
  }): AppThunk =>
  async dispatch => {
    try {
      dispatch(setFeedbacksLoading(true));
      const response = await axios.get('/contract_reviews', { params });
      console.log('-=- feedbacks res', response);
      dispatch(
        getFeedbacks({
          items: response?.data?.data ?? [],
          pagination: response?.data?.meta ?? {}
        })
      );
    } catch (error) {
      handleDefaultErrors(error);
      dispatch(setFeedbacksLoading(false));
    }
  };

export const selectFeedbacks = (state: RootState) => state.feedback;

export default feedbackSlice.reducer;
