import React from 'react';
import { FormControl, Input, InputLabel } from '@mui/material';
import ReactInputMask from 'react-input-mask';
import { Props } from './interface';

const MaskedInput = ({
  placeholder,
  shrinkLabel,
  maskProps,
  fullWidth,
  onChange,
  margin,
  value,
  label
}: Props) => {
  return (
    <FormControl fullWidth={fullWidth} margin={margin}>
      <InputLabel shrink={shrinkLabel}>{label}</InputLabel>
      <ReactInputMask {...maskProps} value={value} onChange={onChange}>
        {(inputProps: any) => (
          <Input
            {...inputProps}
            fullWidth={fullWidth}
            placeholder={placeholder}
          />
        )}
      </ReactInputMask>
    </FormControl>
  );
};

export default MaskedInput;
