import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { adminsApi } from './api/adminsApi';
import { bonusGameApi } from './api/bonusGameApi';
import { citiesApi } from './api/citiesApi';
import { noticesApi } from './api/noticesApi';
import { scootersApi } from './api/scootersApi';

import faqReducer from './slices/faqSlice';
import appReducer from './slices/appSlice';
import newsReducer from './slices/newsSlice';
import authReducer from './slices/authSlice';
import tabsReducer from './slices/tabsSlice';
import usersReducer from './slices/usersSlice';
import zonesReducer from './slices/zonesSlice';
import adminsReducer from './slices/adminsSlice';
import citiesReducer from './slices/citiesSlice';
import balanceReducer from './slices/balanceSlice';
import subscriptionReducer from './slices/subscriptionSlice';
import feedbackReducer from './slices/feedbackSlice';
import noticesReducer from './slices/noticesSlice';
import scootersReducer from './slices/scootersSlice';
import settingsReducer from './slices/settingsSlice';
import contractsReducer from './slices/contractsSlice';
import promocodesReducer from './slices/promocodesSlice';
import scooterNotesReducer from './slices/scooterNotesSlice';

export const store = configureStore({
  reducer: {
    faq: faqReducer,
    app: appReducer,
    news: newsReducer,
    auth: authReducer,
    tabs: tabsReducer,
    zones: zonesReducer,
    users: usersReducer,
    admins: adminsReducer,
    [adminsApi.reducerPath]: adminsApi.reducer,
    cities: citiesReducer,
    [citiesApi.reducerPath]: citiesApi.reducer,
    balance: balanceReducer,
    subscription: subscriptionReducer,
    feedback: feedbackReducer,
    notices: noticesReducer,
    [noticesApi.reducerPath]: noticesApi.reducer,
    settings: settingsReducer,
    scooters: scootersReducer,
    [scootersApi.reducerPath]: scootersApi.reducer,
    contracts: contractsReducer,
    promocodes: promocodesReducer,

    [bonusGameApi.reducerPath]: bonusGameApi.reducer,

    scooterNotes: scooterNotesReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      scootersApi.middleware,
      adminsApi.middleware,
      citiesApi.middleware,
      noticesApi.middleware,
      bonusGameApi.middleware
    )
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
