import { RootState } from './../store';
import { createSlice } from '@reduxjs/toolkit';
import { getLocalStorage, setLocalStorage } from '../../resources/functions';
import { STORE_SIDEBAR_VISIBLE } from '../../resources/storageVariables';
interface AppState {
  sidebarVisible: boolean;
}

const initialState: AppState = {
  sidebarVisible: getLocalStorage(STORE_SIDEBAR_VISIBLE, true)
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setSidebarVisible: (state, action) => {
      state.sidebarVisible = action.payload;
      setLocalStorage(STORE_SIDEBAR_VISIBLE, action.payload);
    }
  }
});

export const { setSidebarVisible } = appSlice.actions;

export const selectAppSettings = (state: RootState) => state.app;

export default appSlice.reducer;
