import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse as MuiCollapse, IconButton } from '@mui/material';
import clsx from 'clsx';
import { Props } from './interface';

const PREFIX = 'Collapse';

const classes = {
  root: `${PREFIX}-root`,
  expand: `${PREFIX}-expand`,
  expandOpen: `${PREFIX}-expandOpen`,
  labelRoot: `${PREFIX}-labelRoot`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column'
  },

  [`& .${classes.expand}`]: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    }),
    padding: "12px !important"
  },

  [`& .${classes.expandOpen}`]: {
    transform: 'rotate(180deg)'
  },

  [`& .${classes.labelRoot}`]: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
    fontWeight: 500,
    color: theme.palette.primary.main,
    paddingLeft: 15,
  }
}));

const Collapse = ({ label, children, style }: Props) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Root className={classes.root} style={style}>
      <div className={classes.labelRoot}>
        <div>{label}</div>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded
          })}
          onClick={() => setExpanded(!expanded)}
          size='large'
        >
          <ExpandMoreIcon color='primary' />
        </IconButton>
      </div>
      <MuiCollapse unmountOnExit in={expanded} timeout='auto'>
        {children}
      </MuiCollapse>
    </Root>
  );
};

export default Collapse;
