import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import MomentUtils from '@mui/lab/AdapterMoment';

import App from './App';
import axios from './resources/axios';
import { store } from './redux/store';
import { theme } from './resources/theme';

import 'moment/locale/uk';
import './index.scss';

const authInfo = store.getState().auth.info;

if (authInfo) {
  axios.defaults.headers.common['x-access-token'] = authInfo.token;
}

const city = store.getState().admins.currentCity;

if (city) {
  axios.defaults.headers.common['city'] = city;
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LocalizationProvider dateAdapter={MomentUtils} locale='uk'>
            <App />
          </LocalizationProvider>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
