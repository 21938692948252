import { createSlice } from '@reduxjs/toolkit';
import { AuthBody, UserType } from '../../resources/types/authTypes';
import { AppThunk, RootState } from '../store';
import axios from '../../resources/axios';
import {
  getLocalStorage,
  handleDefaultErrors,
  setLocalStorage
} from '../../resources/functions';
import {
  STORE_CURRENT_CITY,
  STORE_USER_INFO
} from '../../resources/storageVariables';

interface AuthInfo {
  token: string;
  type: UserType;
}

interface AuthState {
  info: AuthInfo | null;
}

const initialState: AuthState = {
  info: getLocalStorage<AuthInfo>(STORE_USER_INFO)
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signIn: (state, action) => {
      const info = {
        type: 'admin',
        ...action.payload
      };
      setLocalStorage(STORE_USER_INFO, info);
      axios.defaults.headers.common['x-access-token'] = action.payload.token;
      state.info = info;
    },
    signOut: state => {
      localStorage.removeItem(STORE_USER_INFO);
      localStorage.removeItem(STORE_CURRENT_CITY);
      delete axios.defaults.headers.common['x-access-token'];
      delete axios.defaults.headers.common['city'];
      state.info = null;
    }
  }
});

const { signIn } = authSlice.actions;

export const { signOut } = authSlice.actions;

export const signInAsync = (body: AuthBody): AppThunk => async dispatch => {
  try {
    const response = await axios.post('/admins/login', body);
    dispatch(signIn(response.data));
  } catch (error) {
    handleDefaultErrors(error);
  }
};

export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
