import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField
} from '@mui/material';
import { Props } from './interface';
import { useDispatch, useSelector } from 'react-redux';
import { selectCities } from '../../redux/slices/citiesSlice';
import Select from '../../components/Select';
import { SelectOption } from '../../components/Select/interface';
import {
  updateScooterInfoAsync,
  updateScooterStatusAsync
} from '../../redux/slices/scootersSlice';
import { ScooterStatus } from '../../pages/ZonesPage/interface';
import { isNaN } from 'lodash';

export const statusesOption = [
  {
    label: 'Активний для оренди',
    value: ScooterStatus.AVAILABLE
  },
  {
    label: 'На перевезенні',
    value: ScooterStatus.TRANSPORTING
  },
  {
    label: 'На зарядці',
    value: ScooterStatus.CHARGING
  },
  {
    label: 'На ремонті',
    value: ScooterStatus.MAINTENANCE
  },
  {
    label: 'Зламаний в місті',
    value: ScooterStatus.BROKEN_IN_CITY
  },
  {
    label: 'Розряджений',
    value: ScooterStatus.LOW_BATTERY
  },
  {
    label: 'Прихований',
    value: ScooterStatus.HIDDEN
  }
];

const allowConfirmChangeStatusCity = ['60d59e08bd31e836ae204610'];

const ScootersModal = (props: Props) => {
  const { isSuperAdmin, scooter, ...restProps } = props;
  const { open, onClose } = restProps;

  const dispatch = useDispatch();

  const { items: cities } = useSelector(selectCities);

  const [city, setCity] = useState('');
  const [status, setStatus] = useState(
    scooter?.status ?? ScooterStatus.AVAILABLE
  );
  const [isReplaceableBattery, setSsReplaceableBattery] = useState(false);
  const [ignoreTrackerBattery, setIgnoreTrackerBattery] = useState(false);
  const [battery, setBattery] = useState<number>(scooter?.battery ?? 100);

  const isActon = useMemo(
    () => scooter?.isReplaceableBattery ?? false,
    [scooter?.isReplaceableBattery]
  );

  const isNeedsConfirmForCharging = useMemo(
    () => allowConfirmChangeStatusCity.includes(city) ?? false,
    [city]
  );

  useEffect(() => {
    if (open && scooter) {
      setCity(
        typeof scooter?.city === 'string' ? scooter.city : scooter.city?._id
      );
      setStatus(scooter?.status ?? ScooterStatus.AVAILABLE);
      setSsReplaceableBattery(scooter?.isReplaceableBattery ?? false);
      setIgnoreTrackerBattery(scooter?.ignoreTrackerBattery ?? false);
      setBattery(scooter?.battery ?? 100);
    } else {
      setCity('');
    }
  }, [scooter, open]);

  const citiesOptions = useMemo<SelectOption[]>(
    () => [
      {
        value: '',
        label: '-'
      },
      ...cities.map(city => ({
        label: city.cityName,
        value: city._id
      }))
    ],
    [cities]
  );

  const handleSubmit = async () => {
    if (scooter) {
      if (
        isActon &&
        isNeedsConfirmForCharging &&
        status === ScooterStatus.CHARGING
      ) {
        const aNumber = Number(
          window.prompt(
            "Ви намагаєтесь перевести самокат у режим заряджання. Цей самокат має змінну батарею, і батарея буде від'єднана. Підтвердіть цю дію, ввівши номер самоката:",
            ''
          )
        );
        if (isNaN(aNumber) || aNumber !== Number(scooter?.name)) {
          return;
        }
      }

      dispatch(
        updateScooterStatusAsync(scooter._id, { status }, () => {
          if (!isSuperAdmin && onClose) {
            onClose({}, 'backdropClick');
          }
        })
      );
      if (isSuperAdmin) {
        dispatch(
          updateScooterInfoAsync(
            scooter._id,
            {
              cityId: city,
              isReplaceableBattery,
              ignoreTrackerBattery,
              battery
            },
            () => {
              if (onClose) {
                onClose({}, 'backdropClick');
              }
            }
          )
        );
      }
    }
  };

  return (
    <Dialog {...restProps} maxWidth='xs' fullWidth>
      <DialogTitle>Номер самоката: {scooter?.name}</DialogTitle>
      <DialogContent>
        {isSuperAdmin && (
          <>
            <Select
              fullWidth
              label='Місто'
              options={citiesOptions}
              onChange={value => setCity(value)}
              value={city}
            />
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isReplaceableBattery}
                    color='primary'
                    onChange={(event, checked) =>
                      setSsReplaceableBattery(checked)
                    }
                  />
                }
                label='Змінна батарея'
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={ignoreTrackerBattery}
                    color='primary'
                    onChange={(event, checked) =>
                      setIgnoreTrackerBattery(checked)
                    }
                  />
                }
                label='100% батарея'
              />
            </Grid>
            <Grid item>
              <TextField
                name='level'
                type='number'
                fullWidth
                label='Рівень заряду'
                value={battery}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  let newValue = Number(event.target.value);
                  if (newValue > 100) {
                    newValue = 100;
                  }

                  if (newValue < 0) {
                    newValue = 0;
                  }
                  setBattery(newValue);
                }}
              />
            </Grid>
          </>
        )}
        <Select
          fullWidth
          label='Статус'
          options={statusesOption}
          onChange={value => setStatus(value)}
          value={status}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit}>Зберегти</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScootersModal;
