import { useCallback, useState } from 'react';

const useAsync = <T extends Function>(fn: T) => {
  const [pending, setPending] = useState(false);

  const execute = useCallback(
    async (...args: any) => {
      setPending(true);
      const result = await fn(...args);
      setPending(false);
      return result;
    },
    [fn]
  );

  return { execute, pending };
};

export default useAsync;
