import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Pagination,
  Typography
} from '@mui/material';
import { Props, SessionsListResponse, UserDevice } from './interface';
import { useDispatch, useSelector } from 'react-redux';
import {
  expireUserSession,
  getUserSessionsAsync,
  updateUserSessionsAsync
} from '../../redux/slices/usersSlice';
import { RootState } from '../../redux/store';
import { useEffect, useState } from 'react';
import moment from 'moment';

const UserSessionsModal = (props: Props) => {
  const { onClose, user, ...restProps } = props;
  const dispatch = useDispatch();

  const isSuperAdmin = useSelector(
    (state: RootState) => state.auth.info?.type === 'superAdmin'
  );

  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [data, setData] = useState<UserDevice[]>([]);

  const getUserSessions = () => {
    if (!user?._id) return;
    getUserSessionsAsync({
      userId: user?._id,
      page,
      limit: 20
    }).then((res: SessionsListResponse) => {
      console.log('-=-=- getUserSessionsAsync res', res);
      const devices = res.data.userDevices ?? [];
      devices.sort(
        (a: UserDevice, b: UserDevice) =>
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      );
      setData(devices);
      setPages(res.pagination.totalPages ?? 1);
    });
  };

  const updateUserSessions = (id: string, banned: boolean) => {
    if (!user?._id) return;
    updateUserSessionsAsync(id, { banned }).then(
      (res: SessionsListResponse) => {
        console.log('-=-=- updateUserSessions res', res);
        getUserSessions();
      }
    );
  };

  useEffect(() => {
    if (props.open) {
      getUserSessions();
    } else {
      setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, page, user?._id]);

  const onCloseSessions = () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmation = confirm(
      'Ви впевнені що хочете завершити всі сеанси користувача?'
    );
    if (confirmation && user._id) {
      dispatch(expireUserSession(user._id));
      alert('Сесії успішно звершено');
    }
  };

  return (
    <Dialog {...restProps} maxWidth='md' fullWidth>
      <DialogTitle>
        Активні сеанси: {user?.firstName} {user?.lastName} {user?.phone}
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
          direction='column'
          minWidth={400}
          paddingX={1}
        >
          <Grid item>
            {data.length === 0 ? (
              <Grid item justifyContent={'center'} alignItems={'center'}>
                <Typography textAlign={'center'}>Немає сесій</Typography>
              </Grid>
            ) : null}
            {data.map((item, index) => (
              <Grid
                key={index}
                container
                flexDirection={'row'}
                justifyContent='space-between'
                marginTop={1}
              >
                <Grid
                  item
                  xs={isSuperAdmin ? 5 : 8}
                  container
                  flexDirection={'column'}
                >
                  <Typography fontWeight={'600'}>{item.deviceInfo}</Typography>
                  <Typography>{item.simCardNumber}</Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  container
                  flexDirection={'column'}
                  alignItems={'flex-end'}
                >
                  <Typography>
                    {moment(item.createdAt).format('DD.MM.YYYY')}
                  </Typography>
                  <Typography>
                    {moment(item.createdAt).format('HH:mm:ss')}
                  </Typography>
                </Grid>
                {isSuperAdmin ? (
                  <Grid
                    item
                    xs={2}
                    container
                    flexDirection={'column'}
                    alignItems={'flex-end'}
                  >
                    <Button
                      color={item.banned ? 'success' : 'error'}
                      variant='contained'
                      onClick={() => updateUserSessions(item._id, !item.banned)}
                    >
                      {item.banned ? 'Розблокувати' : 'Заблокувати'}
                    </Button>
                  </Grid>
                ) : null}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item>
          {data.length ? (
            <Pagination
              count={pages}
              page={page}
              onChange={(event, nextPage) => setPage(nextPage)}
            />
          ) : null}
        </Grid>
        {isSuperAdmin && data.length ? (
          <Grid
            item
            marginRight={'auto'}
            flexDirection={'row'}
            display={'flex'}
          >
            <Grid marginX={'5px'}>
              <Button
                color='warning'
                variant='contained'
                onClick={() => onCloseSessions()}
              >
                Завершити сеанси
              </Button>
            </Grid>
          </Grid>
        ) : null}
        <Grid marginX={'5px'}>
          <Button color='primary' variant='contained' onClick={() => onClose()}>
            Вийти
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default UserSessionsModal;
