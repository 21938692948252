import { NewTab } from './../../resources/types/TabsTypes';
import { AppThunk, RootState } from './../store';
import { createSlice } from '@reduxjs/toolkit';
import { Tab } from '../../resources/types/TabsTypes';
import axios from '../../resources/axios';

interface TabsState {
  items: Tab[];
}

const initialState: TabsState = {
  items: []
};

const tabsSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    getTabs: (state, action) => {
      state.items = action.payload.tabs;
    }
  }
});

const { getTabs } = tabsSlice.actions;

export const getTabsAsync = (): AppThunk => async dispatch => {
  const response = await axios.get('/tabs');
  console.log('-=-=- getTabsAsync', response)
  dispatch(getTabs(response.data));
};

export const getTabAsync =
  (id: string): AppThunk =>
  async () => {
    return await axios.get(`/tabs/${id}`);
  };

export const createTabAsync =
  (body: NewTab): AppThunk =>
  async () => {
    await axios.post('/tabs', body);
  };

export const updateTabAsync =
  (id: string, body: NewTab): AppThunk =>
  async () => {
    await axios.put(`/tabs/${id}`, body);
  };

export const deleteTabAsync =
  (id: string): AppThunk =>
  async () => {
    await axios.delete(`/tabs/${id}`);
  };

export const selectTabs = (state: RootState) => state.tabs;

export default tabsSlice.reducer;
