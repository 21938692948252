import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell
} from '@mui/material';

import { formatDate } from '../../resources/functions';
import { Tariff } from '../../resources/types/balanceTypes';

interface Props {
  items: Tariff[];
  openDialog: (tariff: Tariff | null) => () => void;
  isWeekendMode: boolean;
  onDelete: (_id: string | undefined) => void;
}

const cellStyles = {
  color: 'white'
};

const TariffsTable = ({
  items,
  openDialog,
  isWeekendMode,
  onDelete
}: Props) => {
  return (
    <TableContainer sx={{ mt: 3 }} component={Paper}>
      <Table>
        <TableHead sx={{ bgcolor: isWeekendMode ? '#9c27b0' : '#2FCB75' }}>
          <TableRow>
            <TableCell sx={cellStyles}>Назва</TableCell>
            <TableCell sx={cellStyles}>Тип</TableCell>
            <TableCell sx={cellStyles}>Ціна</TableCell>
            <TableCell sx={cellStyles}>Дата створення</TableCell>
            <TableCell sx={cellStyles}>Дата оновлення</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map(tariff => {
            if (!tariff.from && !tariff.to) {
              return (
                <TableRow hover key={tariff._id} onClick={openDialog(tariff)}>
                  <TableCell>{tariff.name}</TableCell>
                  <TableCell>{tariff.type}</TableCell>
                  <TableCell>
                    {isWeekendMode
                      ? tariff?.weekendsPrice?.toLocaleString()
                      : tariff?.weekdaysPrice?.toLocaleString()}
                  </TableCell>
                  <TableCell>{formatDate(tariff.createdAt)}</TableCell>
                  <TableCell>{formatDate(tariff.updatedAt)}</TableCell>
                </TableRow>
              );
            }
            return false;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TariffsTable;
