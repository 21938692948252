import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { noticesApi } from '../../redux/api/noticesApi';
import { getActiveContracts } from '../../redux/slices/contractsSlice';
import { setNeedPlayAlert, setNeedPlayStolenAlert } from '../../redux/slices/noticesSlice';
import { getScooters } from '../../redux/slices/scootersSlice';
import { RootState } from '../../redux/store';
import {
  WebSocketContextEvent,
  WebSocketContextValue,
  WebSocketProviderProps
} from './WebSocketProviderTypes';

export const WebSocketContext = createContext<WebSocketContextValue>({});

export const useWebSocketContext = () => {
  return useContext(WebSocketContext);
};

const WebSocketProvider = ({ children }: WebSocketProviderProps) => {
  const token = useSelector(({ auth }: RootState) => auth.info?.token);
  const cityId = useSelector(({ admins }: RootState) => admins.currentCity);
  const dispatch = useDispatch();

  const [socket, setSocket] = useState<WebSocket>();
  const [refreshSocketTrigger, refreshSocket] = useState(0);

  useEffect(() => {
    if (!token) {
      return;
    }

    const ws = new WebSocket(process.env.REACT_APP_API_SOCKET_URL || `wss://${window.location.host}/sockets`); // TODO: uncomment before build

    ws.onopen = () => {
      ws.send(JSON.stringify({ event: 'auth', jwtToken: token, cityId }));
    };

    ws.onclose = event => {
      if (event.reason === 'refresh') {
        return;
      }
      refreshSocket(prevState => prevState + 1);
    };

    ws.addEventListener('message', event => {
      try {
        const response = JSON.parse(event.data);
        switch (response.event) {
          case 'auth':
            setSocket(ws);
            break;

          case 'scooters':
            dispatch(getScooters(response));
            break;

          case 'contracts':
            dispatch(getActiveContracts(response));
            break;

          case 'createNotice':
          case 'updateNotice':
            dispatch(noticesApi.util.invalidateTags(['Notice']));
            console.log('-=-=- AudioAlert createNotice', response);
            // if (response?.data?.notice?.type === cityId) {
              if (response?.data?.notice?.type === "largeDistant" || response?.data?.notice?.type === "usual") {
                dispatch(setNeedPlayStolenAlert(true));
              } else {
                dispatch(setNeedPlayAlert(true));
              }
            // } else {
            //   console.log('-=-=- AudioAlert notice from another city', { response, cityId });
            // }

            break;
        }
      } catch (_) {}
    });

    return () => {
      ws.close(1000, 'refresh');
      setSocket(undefined);
    };
  }, [dispatch, token, cityId, refreshSocketTrigger]);

  const sendSocketMessage = useCallback(
    (event: WebSocketContextEvent, params?: Record<string, any>) => {
      if (!socket) {
        return;
      }

      socket.send(JSON.stringify({ event, version: '2', ...params }));
    },
    [socket]
  );

  return (
    <WebSocketContext.Provider value={{ socket, sendSocketMessage }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketProvider;
