import { Grid, TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { DateTimeRangePickerProps } from './interface';
import { DateRangePicker as MuiDateRangePicker, TimePicker } from '@mui/lab';
import { MuiTextFieldProps } from '@mui/lab/internal/pickers/PureDateInput';

const DateTimeRangePicker = (props: DateTimeRangePickerProps) => {
  const { endText, startText, onChange, value } = props;

  return (
    <MuiDateRangePicker
      startText={startText}
      endText={endText}
      value={value}
      onChange={onChange}
      renderInput={(startProps: MuiTextFieldProps, endProps: MuiTextFieldProps) => (
        <Grid item display={"flex"} alignItems={"center"} flexDirection={"row"} flexWrap={"wrap"}>
          <Grid item display={"flex"} flexDirection={"row"} marginBottom={2}>
            <TextField {...startProps} />
            <TimePicker
              label={`${startText} час`}
              value={value[0]}
              onChange={(newValue) => onChange([newValue, value[1]])} 
              renderInput={(props: TextFieldProps) => (
                <TextField {...props} />
              )}          
            />
          </Grid>
          &nbsp;&nbsp;&nbsp;{'-'}&nbsp;&nbsp;&nbsp;
          <Grid item display={"flex"} flexDirection={"row"} marginBottom={2}>
            <TextField {...endProps} />
            <TimePicker
              label={`${endText} час`}
              value={value[1]}
              onChange={(newValue) => onChange([value[0], newValue])} 
              renderInput={(props: TextFieldProps) => (
                <TextField {...props} />
              )}          
            />
          </Grid>
        </Grid>
      )}
    />
  );
};

export default DateTimeRangePicker;
