import free_scooter from './../img/active_scooter.png';
import free_scooter_acton from './../img/active_scooter_acton.png';

import rented_scooter from './../img/rented_scooter.png';
import rented_scooter_acton from './../img/rented_scooter_acton.png';

import disabled_scooter from './../img/disabled_scooter.png';
import disabled_scooter_acton from './../img/disabled_scooter_acton.png';

import unlocked_scooter from './../img/unlocked_scooter.png';
import unlocked_scooter_acton from './../img/unlocked_scooter_acton.png';

import maintinance_scooter from './../img/maintinance_scooter.png';
import maintinance_scooter_acton from './../img/maintinance_scooter_acton.png';

import discharged_scooter from './../img/discharged_scooter.png';
import discharged_scooter_acton from './../img/discharged_scooter_acton.png';

import hidden_scooter from './../img/hidden_scooter.png';
import hidden_scooter_acton from './../img/hidden_scooter_acton.png';

import transporting_scooter from './../img/transporting_scooter.png';
import transporting_scooter_acton from './../img/transporting_scooter_acton.png';

import charging_scooter from './../img/charging_scooter.png';
import charging_scooter_acton from './../img/charging_scooter_acton.png';

import broken_scooter from './../img/broken_scooter.png';
import broken_scooter_acton from './../img/broken_scooter_acton.png';

import no_battery_scooter from './../img/no_battery_scooter.png';
import no_battery_scooter_acton from './../img/no_battery_scooter_acton.png';

import sprinter_marker from './../img/sprinter.png';
import transit_marker from './../img/transit.png';
import { Scooter } from '../resources/types/scootersTypes';
import { ScooterColorKeys, ScooterStatus } from '../pages/ZonesPage/interface';

const scooterSettings = {
  icons: {
    free: { default: free_scooter, acton: free_scooter_acton },
    rented: { default: rented_scooter, acton: rented_scooter_acton },
    disabled: { default: disabled_scooter, acton: disabled_scooter_acton },
    unlocked: { default: unlocked_scooter, acton: unlocked_scooter_acton },
    maintenance: {
      default: maintinance_scooter,
      acton: maintinance_scooter_acton
    },
    discharged: {
      default: discharged_scooter,
      acton: discharged_scooter_acton
    },
    no_battery: {
      default: no_battery_scooter,
      acton: no_battery_scooter_acton
    },
    hidden: { default: hidden_scooter, acton: hidden_scooter_acton },
    transporting: {
      default: transporting_scooter,
      acton: transporting_scooter_acton
    },
    charging: { default: charging_scooter, acton: charging_scooter_acton },
    broken: { default: broken_scooter, acton: broken_scooter_acton },
    sprinter: sprinter_marker,
    transit: transit_marker
  },
  colors: {
    all: '#3E8A8C',
    free: '#07c50b',
    unlocked: '#FFC500',
    inRent: '#FA0006',
    CHARGING: '#742BFF',
    TRANSPORTING: '#EA7F5C',
    MAINTENANCE: '#9714E8',
    BROKEN_IN_CITY: '#2895EA',
    HIDDEN: '#757575',
    offline: '#FC16C9',
    discharged: '#151515'
  } as Record<ScooterStatus | string, string>
};

const getScooterIcon = (scooter: Scooter, scooterName: string) => {
  const isActon = scooter?.isReplaceableBattery;

  if (scooterName === 'BUS-IF-S') {
    return scooterSettings.icons.sprinter;
  }

  if (
    scooterName === 'BUS_KOL' ||
    scooterName === 'BUS-IF-T' ||
    scooterName.includes('AVTO')
  ) {
    return scooterSettings.icons.transit;
  }

  if (!scooter.connected) {
    return isActon
      ? scooterSettings.icons.disabled.acton
      : scooterSettings.icons.disabled.default;
  }

  if (scooter.status === ScooterStatus.HIDDEN) {
    return isActon
      ? scooterSettings.icons.hidden.acton
      : scooterSettings.icons.hidden.default;
  }

  if (scooter.status === ScooterStatus.BROKEN_IN_CITY) {
    return isActon
      ? scooterSettings.icons.broken.acton
      : scooterSettings.icons.broken.default;
  }

  if (scooter.status === ScooterStatus.TRANSPORTING) {
    return isActon
      ? scooterSettings.icons.transporting.acton
      : scooterSettings.icons.transporting.default;
  }

  if (scooter.status === ScooterStatus.CHARGING) {
    return isActon
      ? scooterSettings.icons.charging.acton
      : scooterSettings.icons.charging.default;
  }

  if (scooter.status === ScooterStatus.MAINTENANCE) {
    return isActon
      ? scooterSettings.icons.maintenance.acton
      : scooterSettings.icons.maintenance.default;
  }

  if (scooter.status === ScooterStatus.LOW_BATTERY) {
    return isActon
      ? scooterSettings.icons.no_battery.acton
      : scooterSettings.icons.no_battery.default;
  }

  if (scooter.free && !scooter.lock) {
    return isActon
      ? scooterSettings.icons.unlocked.acton
      : scooterSettings.icons.unlocked.default;
  }

  if (!scooter.free) {
    return isActon
      ? scooterSettings.icons.rented.acton
      : scooterSettings.icons.rented.default;
  }

  if (!scooter.viewed) {
    return isActon
      ? scooterSettings.icons.discharged.acton
      : scooterSettings.icons.discharged.default;
  }

  return isActon
    ? scooterSettings.icons.free.acton
    : scooterSettings.icons.free.default;
};

const getScooterColor = (status: ScooterColorKeys) => {
  return scooterSettings.colors[status] || scooterSettings.colors.all;
};

const getDotColor = (scooter: Scooter) => {
  const status = scooter.status;

  if (!scooter.connected) {
    return scooterSettings.colors.disabled || scooterSettings.colors.offline;
  }

  if (status === ScooterStatus.HIDDEN) {
    return scooterSettings.colors.HIDDEN;
  }

  if (status === ScooterStatus.BROKEN_IN_CITY) {
    return scooterSettings.colors.BROKEN_IN_CITY;
  }

  if (status === ScooterStatus.TRANSPORTING) {
    return scooterSettings.colors.TRANSPORTING;
  }

  if (status === ScooterStatus.CHARGING) {
    return scooterSettings.colors.CHARGING;
  }

  if (status === ScooterStatus.MAINTENANCE) {
    return scooterSettings.colors.MAINTENANCE;
  }

  if (status === ScooterStatus.LOW_BATTERY) {
    return (
      scooterSettings.colors.no_battery || scooterSettings.colors.discharged
    );
  }

  if (scooter.free && !scooter.lock) {
    return scooterSettings.colors.unlocked;
  }

  if (!scooter.free) {
    return scooterSettings.colors.inRent;
  }

  if (!scooter.viewed) {
    return scooterSettings.colors.discharged;
  }

  return scooterSettings.colors.free;
};

const getDotTooltip = (scooter: Scooter): string => {
  const status = scooter.status;

  if (!scooter.connected) {
    return 'Не в мережі';
  }

  if (status === ScooterStatus.HIDDEN) {
    return 'Прихований';
  }

  if (status === ScooterStatus.BROKEN_IN_CITY) {
    return 'Зламаний у місті';
  }

  if (status === ScooterStatus.TRANSPORTING) {
    return 'Транспортується';
  }

  if (status === ScooterStatus.CHARGING) {
    return 'Заряджається';
  }

  if (status === ScooterStatus.MAINTENANCE) {
    return 'На обслуговуванні';
  }

  if (status === ScooterStatus.LOW_BATTERY) {
    return 'Низький заряд батареї';
  }

  if (scooter.free && !scooter.lock) {
    return 'Розблокований';
  }

  if (!scooter.free) {
    return 'В оренді';
  }

  if (!scooter.viewed) {
    return 'Розряджений';
  }

  return 'Доступний';
};

export { getScooterIcon, getScooterColor, getDotColor, getDotTooltip };
