import { createSlice } from '@reduxjs/toolkit';
import axios from '../../resources/axios';
import { handleDefaultErrors } from '../../resources/functions';
import { FAQ, NewFAQ } from '../../resources/types/faqTypes';
import { AppThunk } from '../store';

interface FAQState {
  items: FAQ[];
}

const initialState: FAQState = {
  items: []
};

const FAQSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    setFAQ: (state, action) => {
      state.items = action.payload;
    }
  }
});

const { setFAQ } = FAQSlice.actions;

export const getFAQAsync = (): AppThunk => async dispatch => {
  try {
    const response = await axios.get('/faq');
    dispatch(setFAQ(response?.data?.faq ?? []));
  } catch (e) {
    handleDefaultErrors(e);
  }
};

export const createFAQAsync = (
  data: NewFAQ,
  success: () => void
): AppThunk => async dispatch => {
  try {
    await axios.post('/faq', data);
    success();
  } catch (e) {
    handleDefaultErrors(e);
  }
};

export const updateFAQAsync = (
  id: string,
  data: NewFAQ,
  success: () => void
): AppThunk => async dispatch => {
  try {
    await axios.put(`/faq/${id}`, data);
    success();
  } catch (e) {
    handleDefaultErrors(e);
  }
};

export const deleteFAQAsync = (id: string): AppThunk => async dispatch => {
  try {
    await axios.delete(`/faq/${id}`);
  } catch (e) {
    handleDefaultErrors(e);
  }
};

export default FAQSlice.reducer;
