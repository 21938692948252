import { createApi } from '@reduxjs/toolkit/query/react';
import {
  GetNoticesParams,
  GetNoticesResult
} from '../../resources/types/noticesTypes';
import baseQuery from './baseQuery';

export const noticesApi = createApi({
  reducerPath: 'noticesApi',
  baseQuery,
  tagTypes: ['Notice'],
  endpoints: builder => ({
    getNotices: builder.query<GetNoticesResult, GetNoticesParams>({
      query: params => ({
        url: 'admins/notices',
        params
      }),
      providesTags: ['Notice']
    }),
    getNoticeTypes: builder.query<string[], void>({
      query: () => 'admins/notices/types',
      transformResponse(response: { data: string[] }) {
        return response.data;
      }
    }),
    setNoticeViewed: builder.mutation<void, { id: string; viewed: boolean }>({
      query: ({ id, viewed }) => ({
        url: `/admins/notices/${id}`,
        method: 'PUT',
        body: { viewed }
      }),
      invalidatesTags: ['Notice']
    })
  })
});

export const {
  useGetNoticesQuery,
  useGetNoticeTypesQuery,
  useSetNoticeViewedMutation
} = noticesApi;
