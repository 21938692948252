import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import axios from '../../resources/axios';
import { Admin, AdminInfo, CreateAdminBody } from '../../resources/adminsTypes';
import { getLocalStorage, setLocalStorage } from '../../resources/functions';
import { STORE_CURRENT_CITY } from '../../resources/storageVariables';
import { signOut } from './authSlice';

interface AdminsState {
  items: Admin[];
  info?: AdminInfo;
  currentCity?: string;
  isGoogleApiInit: boolean;
}

const initialState: AdminsState = {
  items: [],
  isGoogleApiInit: false,
  currentCity: getLocalStorage(STORE_CURRENT_CITY) || undefined
};

const adminsSlice = createSlice({
  name: 'admins',
  initialState,
  reducers: {
    getAdmins: (state, action) => {
      state.items = action.payload.admins;
    },
    getAdminInfo: (state, action) => {
      const currentCity = action.payload.admin.cities[0]?._id;
      state.info = action.payload.admin;
      state.currentCity = currentCity;
      axios.defaults.headers.common['city'] = currentCity;
      setLocalStorage(STORE_CURRENT_CITY, currentCity);
    },
    setCurrentCity: (state, action) => {
      const currentCity = action.payload;
      state.currentCity = currentCity;
      axios.defaults.headers.common['city'] = currentCity;
      setLocalStorage(STORE_CURRENT_CITY, currentCity);
    },
    initGoogleApi: state => {
      state.isGoogleApiInit = true;
    }
  }
});

export const {
  getAdmins,
  getAdminInfo,
  setCurrentCity,
  initGoogleApi
} = adminsSlice.actions;

export const initGoogleApiAsync = (): AppThunk => async dispatch => {
  const apiUrl =
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyBu5VqKowtFVHsHfMDxdvvspcbjzztTvSA&libraries=places,visualization';

  if (!document.querySelectorAll(`[src="${apiUrl}"]`).length) {
    document.body.appendChild(
      Object.assign(document.createElement('script'), {
        type: 'text/javascript',
        src: apiUrl,
        onload: () => dispatch(initGoogleApi())
      })
    );
  } else {
    dispatch(initGoogleApi());
  }
};

export const getAdminsAsync = (cityIds = ''): AppThunk => async dispatch => {
  const response = await axios.get(`/admins${cityIds ? `?cities=${cityIds}` : ''}`);
  dispatch(getAdmins(response.data));
};

export const getAdminAsync = (adminId: string): AppThunk => async () => {
  const response = await axios.get(`/admins/${adminId}`);
  return response.data;
};

export const createAdminAsync = (
  body: CreateAdminBody
): AppThunk => async () => {
  await axios.post('/admins', body);
};

export const updateAdminAsync = (
  adminId: string,
  body: CreateAdminBody
): AppThunk => async () => {
  await axios.put(`/admins/${adminId}`, body);
};

export const deleteAdminAsync = (adminId: string): AppThunk => async () => {
  await axios.delete(`/admins/${adminId}`);
};

export const getAdminInfoAsync = (): AppThunk => async dispatch => {
  try {
    const response = await axios.get('/admins/info');
    dispatch(getAdminInfo(response.data));
  } catch (e) {
    if (e.response?.status === 401) {
      dispatch(signOut());
    }
  }
};

export const selectAdmins = (state: RootState) => state.admins;

export default adminsSlice.reducer;
