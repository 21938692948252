import React, { useEffect, useMemo, useState } from 'react';
import MapMarker from '../MapMarker';

const UserMapMarker = () => {
  const [position, setPosition] = useState<google.maps.LatLngLiteral | null>(
    null
  );

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(pos =>
      setPosition({
        lat: pos.coords.latitude,
        lng: pos.coords.longitude
      })
    );
  }, []);

  const icon = useMemo(
    () => ({
      url: '/man.svg',
      scaledSize: new window.google.maps.Size(36, 36)
    }),
    []
  );

  if (!position) return null;

  return <MapMarker icon={icon} position={position} />;
};

export default UserMapMarker;
