import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Add, Delete, Edit } from '@mui/icons-material';
import Title from '../../components/Title';
import AdminsDialog from './AdminsDialog';
import {
  deleteAdminAsync,
  getAdminsAsync,
  selectAdmins
} from '../../redux/slices/adminsSlice';
import { selectAuth } from '../../redux/slices/authSlice';
import { getTabsAsync } from '../../redux/slices/tabsSlice';

const PREFIX = 'AdminsPage';

const classes = {
  header: `${PREFIX}-header`
};

const Root = styled('div')({
  [`& .${classes.header}`]: {
    marginBottom: 20
  }
});

const AdminsPage = () => {
  const dispatch = useDispatch();

  const admins = useSelector(selectAdmins);
  const auth = useSelector(selectAuth);

  const isSuperAdmin = useMemo(() => {
    return auth.info?.type === 'superAdmin';
  }, [auth.info?.type]);

  const citiesIds = useMemo(() => {
    if (isSuperAdmin) {
      return '';
    }

    const cities = (admins.info?.cities ?? []).map((item) => item?._id);
    const citiesIds = cities.join(',').toString()

    return citiesIds;
  }, [isSuperAdmin, admins.info]);

  useEffect(() => {
    if (isSuperAdmin) {
      dispatch(getAdminsAsync());
    }

    if (!isSuperAdmin && citiesIds) {
      dispatch(getAdminsAsync(citiesIds));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citiesIds]);

  useEffect(() => {
    if (!isSuperAdmin) {
      dispatch(getTabsAsync());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuperAdmin]);

  const [dialogState, setDialogState] = useState<{
    open: boolean;
    adminId?: string;
  }>({ open: false });

  const openDialog = useCallback(
    (adminId?: string) => () => setDialogState({ open: true, adminId }),
    []
  );

  const closeDialog = useCallback(() => setDialogState({ open: false }), []);

  const deleteAdmin = useCallback(
    (adminId: string) => async () => {
      await dispatch(deleteAdminAsync(adminId));
      dispatch(getAdminsAsync());
    },
    [dispatch]
  );

  return (
    <Root>
      <Grid
        container
        alignItems='center'
        className={classes.header}
        spacing={4}
      >
        <Grid item>
          <Title>Адміністратори</Title>
        </Grid>
        <Grid item>
          <Button
            color='primary'
            startIcon={<Add />}
            variant='outlined'
            onClick={openDialog()}
          >
            Додати
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Логін</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {admins.items.map(admin => (
              <TableRow key={admin._id}>
                <TableCell>{admin.login}</TableCell>
                <TableCell align='right'>
                  <IconButton size='small' onClick={openDialog(admin._id)}>
                    <Edit fontSize='small' />
                  </IconButton>
                  <IconButton size='small' onClick={deleteAdmin(admin._id)}>
                    <Delete fontSize='small' />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AdminsDialog {...dialogState} onClose={closeDialog} />
    </Root>
  );
};

export default AdminsPage;
