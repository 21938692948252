import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  TextField
} from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ISubscriptionContractTypePrices,
  ISubscriptionType,
  Subscription
} from '../../resources/types/balanceTypes';
import { selectCities } from '../../redux/slices/citiesSlice';
import { selectAdmins } from '../../redux/slices/adminsSlice';
import { RootState } from '../../redux/store';
import { SelectOption } from '../../components/Select/interface';
import Select from '../../components/Select';
import { useFormik } from 'formik';
import SubscriptionModalPricesGroup from './SubscriptionModalPricesGroup';
import { createOrUpdateSubscriptionAsync } from '../../redux/slices/subscriptionSlice';

const defaultPricesData: ISubscriptionContractTypePrices = {
  start: null,
  normal: null,
  reservation: null,
  pause: null
};

const defaultPricesDurationData: ISubscriptionContractTypePrices = {
  normal: null,
  reservation: null,
  pause: null
};

const defaultPricesFreeActionsData: ISubscriptionContractTypePrices = {
  start: null
};

const typeSelectorOptions: SelectOption[] = [
  {
    label: 'Безкоштовні розблокування',
    value: ISubscriptionType.priceForContractActions
  },
  {
    label: 'Лімітована кількість',
    value: ISubscriptionType.freeContractActions
  },
  {
    label: 'Знижки',
    value: ISubscriptionType.discountPercentForContractActions
  }
];

interface ISubscriptionModalProps {
  selectedSubscription: Subscription | null;
  open: boolean;
  setOpen: (open: boolean) => void;
  setSelectedSubscription: (subscription: Subscription) => void;
}

const SubscriptionModal = ({
  open,
  selectedSubscription,
  setOpen,
  setSelectedSubscription
}: ISubscriptionModalProps) => {
  const dispatch = useDispatch();
  const cities = useSelector(selectCities);
  const adminInfo = useSelector(selectAdmins);

  const cityId = useMemo(() => {
    const city = cities.items.find(item => item._id === adminInfo.currentCity);
    if (city) {
      return city._id;
    }
  }, [adminInfo.currentCity, cities.items]);

  const {
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    values,
    resetForm
  } = useFormik({
    initialValues: {
      name: '',
      description: '',
      duration: 0,
      price: 0,
      city: cityId,
      type: ISubscriptionType.priceForContractActions,
      contractPrices: defaultPricesData,
      freeActions: defaultPricesFreeActionsData,
      freeDurationSeconds: defaultPricesDurationData,
      contractPricesDiscountPercents: defaultPricesData
    },
    onSubmit: values => {
      const {
        name,
        description,
        duration,
        price,
        city,
        type,
        contractPrices,
        freeActions,
        freeDurationSeconds,
        contractPricesDiscountPercents
      } = values;
      const subscriptionCity = city ?? (cityId as string);

      dispatch(
        createOrUpdateSubscriptionAsync(
          selectedSubscription?._id ?? '',
          {
            name,
            description,
            duration,
            price,
            city: subscriptionCity,
            type,
            ...(values.type === ISubscriptionType.priceForContractActions
              ? { contractPrices }
              : {}),
            ...(values.type === ISubscriptionType.freeContractActions
              ? { freeActions, freeDurationSeconds }
              : {}),
            ...(values.type ===
            ISubscriptionType.discountPercentForContractActions
              ? { contractPricesDiscountPercents }
              : {})
          },
          () => {
            resetForm();
            setOpen(false);
          }
        )
      );
    }
  });

  useEffect(() => {
    console.log('-=-=- useEffect', open, selectedSubscription);
    if (open && selectedSubscription !== null) {
      setValues({
        name: selectedSubscription?.name ?? '',
        description: selectedSubscription?.description ?? '',
        duration: selectedSubscription?.duration ?? 0,
        price: selectedSubscription?.price ?? 0,
        city: selectedSubscription?.city ?? cityId,
        type:
          selectedSubscription?.type ??
          ISubscriptionType.priceForContractActions,
        contractPrices:
          selectedSubscription?.contractPrices ?? defaultPricesData,
        freeActions:
          selectedSubscription?.freeActions ?? defaultPricesFreeActionsData,
        freeDurationSeconds:
          selectedSubscription?.freeDurationSeconds ??
          defaultPricesDurationData,
        contractPricesDiscountPercents:
          selectedSubscription?.contractPricesDiscountPercents ??
          defaultPricesData
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (values.type === ISubscriptionType.priceForContractActions) {
      setFieldValue('freeActions', defaultPricesFreeActionsData);
      setFieldValue('freeDurationSeconds', defaultPricesDurationData);
      setFieldValue('contractPricesDiscountPercents', defaultPricesData);
    }

    if (values.type === ISubscriptionType.freeContractActions) {
      setFieldValue('contractPrices', defaultPricesData);
      setFieldValue('contractPricesDiscountPercents', defaultPricesData);
    }

    if (values.type === ISubscriptionType.discountPercentForContractActions) {
      setFieldValue('contractPrices', defaultPricesData);
      setFieldValue('freeActions', defaultPricesFreeActionsData);
      setFieldValue('freeDurationSeconds', defaultPricesDurationData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.type]);

  const isSuperAdmin = useSelector(
    (state: RootState) => state.auth.info?.type === 'superAdmin'
  );

  const citiesOptions = useMemo<SelectOption[]>(
    () => [
      {
        label: 'Усі',
        value: ''
      },
      ...cities.items.map(city => ({
        label: city.cityName,
        value: city._id
      }))
    ],
    [cities.items]
  );

  return (
    <Dialog fullWidth maxWidth='sm' open={open} onClose={() => setOpen(false)}>
      <>
        <DialogTitle>
          {selectedSubscription?._id
            ? 'Редагування абонементу'
            : 'Створення абонементу'}
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label='Назва'
            name='name'
            onChange={handleChange}
            value={values.name}
          />
          <InputLabel style={{ fontSize: "13px", marginTop: 20}}>Опис</InputLabel>
          <textarea style={{ resize: "none", width: "100%" }} rows={6} onChange={event => setFieldValue('description', event.target.value)} value={values.description}></textarea>
          {isSuperAdmin ? (
            <Select
              style={{ width: '100%', marginTop: 20 }}
              label='Місто'
              options={citiesOptions}
              onChange={value => setFieldValue('city', value)}
              value={values.city}
            />
          ) : null}
          <TextField
            fullWidth
            label='Тривалість'
            margin='normal'
            type='number'
            name='duration'
            onChange={handleChange}
            value={values.duration}
          />

          <TextField
            fullWidth
            label='Ціна'
            margin='normal'
            type='number'
            name='price'
            onChange={handleChange}
            value={values.price}
          />

          <Select
            style={{ width: '100%', marginTop: 20 }}
            label='Тип підписки'
            options={typeSelectorOptions}
            onChange={value => setFieldValue('type', value)}
            value={values.type}
            disabled={Boolean(selectedSubscription?._id)}
          />

          {values.type === ISubscriptionType.priceForContractActions ? (
            <SubscriptionModalPricesGroup
              label={'Ціни договорів'}
              valueKey={'contractPrices'}
              values={values.contractPrices}
              onChange={(key, value) => {
                setFieldValue(`contractPrices.${key}`, value);
              }}
            />
          ) : null}

          {values.type === ISubscriptionType.freeContractActions ? (
            <>
              <SubscriptionModalPricesGroup
                label={'Безкоштовні дії'}
                valueKey={'freeActions'}
                values={values.freeActions}
                textFieldPlaceholder='Кількість'
                startTitle={'start (розблокувань)'}
                onChange={(key, value) => {
                  setFieldValue(`freeActions.${key}`, value);
                }}
                only={Object.keys(defaultPricesFreeActionsData) as string[]}
              />

              <SubscriptionModalPricesGroup
                label={'Безкоштовна тривалість дій'}
                textFieldPlaceholder='Тривалість'
                valueKey={'freeDurationSeconds'}
                normalTitle={'normal (секунд)'}
                reservationTitle={'reservation (секунд)'}
                pauseTitle={'pause (секунд)'}
                values={values.freeDurationSeconds}
                onChange={(key, value) => {
                  setFieldValue(`freeDurationSeconds.${key}`, value);
                }}
                only={Object.keys(defaultPricesDurationData) as string[]}
              />
            </>
          ) : null}

          {values.type ===
          ISubscriptionType.discountPercentForContractActions ? (
            <SubscriptionModalPricesGroup
              label={'Знижка в відсотках на контракти'}
              valueKey={'contractPricesDiscountPercents'}
              values={values.contractPricesDiscountPercents}
              onChange={(key, value) => {
                setFieldValue(`contractPricesDiscountPercents.${key}`, value);
              }}
            />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={() => handleSubmit()}>
            Зберегти
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
};

export default SubscriptionModal;
