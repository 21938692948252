import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createFAQAsync,
  getFAQAsync,
  updateFAQAsync
} from '../../redux/slices/faqSlice';
import { TranslationLanguages } from '../../resources/types/faqTypes';
import { Props } from './interface';
import LanguageSelect from '../../components/LanguageSelect';
import { useFormik } from 'formik';
import { selectAdmins } from '../../redux/slices/adminsSlice';

const FaqModal = ({ onClose, open, faq }: Props) => {
  const dispatch = useDispatch();

  const admins = useSelector(selectAdmins);
  const [scootersCity, setScootersCity] = useState(admins.currentCity ?? '');

  const [language, setLanguage] = useState<TranslationLanguages>(TranslationLanguages.ua);

  
  
  const success = () => {
    if (onClose) {
      onClose({}, 'backdropClick');
    }
    dispatch(getFAQAsync());
  };

  const { handleSubmit, setFieldValue, values, setValues, resetForm } = useFormik({
    initialValues: {
      answer: {
        en: '',
        ua: ''
      },
      question: {
        en: '',
        ua: ''
      },
      cities: [scootersCity]
    },
    onSubmit: values => {
      if (faq) {
        dispatch(updateFAQAsync(faq._id, values, success));
      } else {
        dispatch(createFAQAsync(values, success));
      }
    }
  });

  useEffect(() => {
    if (open && faq) {
      setValues({
        answer: faq.answer,
        question: faq.question,
        cities: faq.cities,
      })
    } else {
      resetForm();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, faq]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>FAQ</DialogTitle>
      <DialogContent>
        <Grid item>
          <LanguageSelect value={language} onChange={setLanguage} />
        </Grid>
        <TextField
          fullWidth
          label='Запитання'
          multiline
          margin='normal'
          name={`question`}
          onChange={(e) => {
            setFieldValue(`question.${language}`, e.target.value)
          }}
          value={values.question[language]}
        />
        <TextField
          fullWidth
          label='Відповідь'
          multiline
          margin='normal'
          name={`answer.${language}`}
          onChange={(e) => {
            setFieldValue(`answer.${language}`, e.target.value)
          }}
          value={values.answer[language]}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleSubmit()}>Зберегти</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FaqModal;
