import { useEffect, useState } from 'react';
import { InfoWindow, Marker } from '@react-google-maps/api';
import { MapMarkerProps } from './interface';

const MapMarker = ({
  position,
  children,
  icon,
  forceInfoWindowOpen,
  batteryLevel = '',
  zIndex
}: MapMarkerProps) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(Boolean(forceInfoWindowOpen));
  }, [forceInfoWindowOpen]);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  return (
    <Marker
      icon={icon}
      position={position}
      onClick={onOpen}
      label={{
        text: String(batteryLevel ?? ''),
        fontSize: '10px'
      }}
      {...zIndex ? { zIndex } : {}}
    >
      {open && (
        <InfoWindow position={position} onCloseClick={onClose}>
          {children}
        </InfoWindow>
      )}
    </Marker>
  );
};

export default MapMarker;
