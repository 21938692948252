import { UserType } from './usersTypes';

export enum IPromoCodeType {
  addBalance = 'addBalance', 
  referal = 'referal'
}

export interface Tariff {
  createdAt?: string;
  name: string;
  type: string;
  updatedAt?: string;
  _id?: string;
  price: number | string;
  maxTime: number | string;
  userType: UserType;
  weekendsPrice?: number | string;
  weekdaysPrice?: number | string;
  currentMode?: string;
  from: number | string;
  to: number | string;
}


export interface Promocode {
  code: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  amount?: number;
  type?: IPromoCodeType;
  expiresAt?: string;
  description?: string;
  usingLimit?: number;
  city?: string;
  active?: boolean;
  usedTimes?: number;
}

export enum ISubscriptionType {
  priceForContractActions = "priceForContractActions",
  freeContractActions = "freeContractActions",
  discountPercentForContractActions = "discountPercentForContractActions"
}

export interface ISubscriptionContractTypePrices {
  start?: number | string | null;
  normal?: number | string | null;
  reservation?: number | string | null;
  pause?: number | string | null;
}

export interface Subscription {
  _id?: string;
  name: string;
  duration: number;
  price: number;
  city: string;
  description: string;
  createdAt?: string;
  updatedAt?: string;

  // NEW VALUES
  type?: ISubscriptionType

  // priceForContractActions
  contractPrices?: ISubscriptionContractTypePrices;

  // freeContractActions
  freeActions?: ISubscriptionContractTypePrices;
  freeDurationSeconds?: ISubscriptionContractTypePrices;

  // discountPercentForContractActions
  contractPricesDiscountPercents?: ISubscriptionContractTypePrices;

  // TODO REMOVE
  icon?: string;
  oldDuration?: number;
  oldPrice?: number;
}

export interface HistoryAdmin {
  _id: string;
  login: string;
}

export interface HistoryUser {
  phone: string;
  type: UserType;
  _id: string;
}

export interface History {
  admin: HistoryAdmin;
  user: HistoryUser;
  amount: number;
  createdAt: string;
  type: string;
}

export type GetTariffsParams = Partial<{
  userType: UserType;
}>;

export type GetUserHistoryParams = Partial<{
  user_id: string;
  page: number;
  limit: number;
  type: string;
  active: boolean;
}>;

export type GetCitiesBalanceParams = Partial<{
  to: string | number;
  from: string | number;
  cityId?: string;
}>;

export interface CityBalance {
  cityName: string;
  count: number;
  _id: string;
}

export type GetStatisticsParams = Partial<{
  dateTo: string;
  dateFrom: string;
  cityId?: string;
}>;

export interface WasteCitiesBalance {
  cityName: string;
  count: number;
}
