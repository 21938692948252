import { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { DateRange } from '@material-ui/pickers';
import {
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import Title from '../../components/Title';
import { formatDate } from '../../resources/functions';
import TableCell from '../../components/TableCell';
import {
  getScooterNotesAsync,
  selectNotes
} from '../../redux/slices/scooterNotesSlice';
import { Moment } from 'moment';
import FilterHeader, {
  FilterHeaderValues
} from '../../components/Header/FilterHeader';

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

const PREFIX = 'NotesPage';

const classes = {
  header: `${PREFIX}-header`,
  addButton: `${PREFIX}-addButton`,
  tableContainer: `${PREFIX}-tableContainer`
};

const Root = styled('div')({
  [`& .${classes.header}`]: {
    display: 'flex',
    alignItems: 'center'
  },
  [`& .${classes.addButton}`]: {
    marginLeft: 20
  },
  [`& .${classes.tableContainer}`]: {
    marginTop: 40,
    marginBotom: 40
  }
});

const initialValues = {
  scooterName: '',
  adminLogin: '',
  dateRange: [null, null] as DateRange<Moment | null>
};

const NotesPage = () => {
  const dispatch = useDispatch();

  const { limit, total, items } = useSelector(selectNotes);
  const [page, setPage] = useState(1);
  const [filters, setFilters] =
    useState<Partial<FilterHeaderValues>>(initialValues);

  const onChangePage = useCallback((e: any, nextPage: number) => {
    setPage(nextPage);
  }, []);

  useEffect(() => {
    dispatch(
      getScooterNotesAsync({
        page,
        limit,
        ...filters,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, filters]);

  const handleFormSubmit = (values: Partial<FilterHeaderValues>) => {
    const isValidDateRange =
      values.dateRange?.[0]?.isValid?.() && values.dateRange?.[1]?.isValid?.();

    const dateFrom = isValidDateRange
      ? values.dateRange![0]!.format(DATE_FORMAT)
      : undefined;

    const dateTo = isValidDateRange
      ? values.dateRange![1]!.format(DATE_FORMAT)
      : undefined;

    const dateObj = isValidDateRange
      ? {
          dateTo: dateTo as string,
          dateFrom: dateFrom as string
        }
      : {};

    setFilters({
      scooterName: values?.scooterName?.length ? values.scooterName : undefined,
      adminLogin: values?.adminLogin?.length ? values.adminLogin : undefined,
      ...dateObj
    });
  };

  return (
    <Root>
      <Grid container direction='column'>
        <Grid item>
          <Title>Нотатки</Title>
        </Grid>
        <Grid item>
          <FilterHeader
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
          />
        </Grid>
      </Grid>
      {Boolean(items?.length) && (
        <div>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Скутер</TableCell>
                  <TableCell>Автор</TableCell>
                  <TableCell>Текст</TableCell>
                  <TableCell>Створено</TableCell>
                  <TableCell>Редаговано</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items?.map(note => (
                  <TableRow hover key={note._id}>
                    <TableCell>{note?.scooter?.name ?? ''}</TableCell>
                    <TableCell>{note.adminLogin}</TableCell>
                    <TableCell>{note.text}</TableCell>
                    <TableCell>
                      {formatDate(note.createdAt, { time: true })}
                    </TableCell>
                    <TableCell>
                      {formatDate(note.updatedAt, { time: true })}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination count={total} onChange={onChangePage} />
        </div>
      )}
    </Root>
  );
};

export default NotesPage;
