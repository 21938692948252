import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import useBind from '../../hooks/useBind';
import {
  createCityAsync,
  getCitiesAsync,
  getCityAsync,
  getCityLiqPayConfigAsync,
  setCityLiqPayConfigAsync,
  updateCityAsync
} from '../../redux/slices/citiesSlice';
import { CreateCityBody } from '../../resources/types/citiesTypes';
import Autocomplete from '../../containers/Autocomplete';
import { RootState } from '../../redux/store';

const initialState: CreateCityBody = {
  cityName: '',
  lat: '',
  lon: '',
  phone: '',
  telegram: '',
  viber: '',
  showParkingInstruction: false
};

const CitiesDialog = (
  props: DialogProps & { onClose: () => void; cityId?: string }
) => {
  const { open, onClose, cityId } = props;

  const dispatch = useDispatch();

  const [city, setCity] = useState(initialState);
  const [allowStatusWaitAccept, setAllowStatusWaitAccept] =
    useState<boolean>(false);

  const isSuperAdmin = useSelector(
    (state: RootState) => state.auth.info?.type === 'superAdmin'
  );

  useEffect(() => {
    if (cityId && open) {
      const getCity = async () => {
        const response: any = await dispatch(getCityAsync(cityId));
        setCity(response.city);

        const responseLiqPay: any = await dispatch(
          getCityLiqPayConfigAsync(cityId)
        );
        setAllowStatusWaitAccept(
          responseLiqPay?.allowStatusWaitAccept ?? false
        );
      };

      getCity();
    } else {
      setCity(initialState);
    }
  }, [cityId, dispatch, open]);

  const bind = useBind(city, setCity);

  const handleSave = useCallback(async () => {
    try {
      if (cityId) {
        await dispatch(updateCityAsync(cityId, city));
      } else {
        await dispatch(createCityAsync(city));
      }

      dispatch(getCitiesAsync());
      onClose();
    } catch (e) {
      if (e.response) {
        alert(e.response.data.message);
      }
    }
  }, [city, cityId, dispatch, onClose]);

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={onClose}>
      <DialogTitle>
        {cityId ? 'Редагування міста' : 'Створення міста'}
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          fullWidth
          label='Назва'
          margin='normal'
          placeholder=''
          {...bind('cityName')}
          onSelect={(value, bounds) => {
            setCity(prevState => ({
              ...prevState,
              cityName: value,
              lat: String(bounds.lat),
              lon: String(bounds.lon)
            }));
          }}
        />
        <TextField
          {...bind('lat')}
          fullWidth
          label='Широта'
          margin='normal'
          variant='outlined'
        />
        <TextField
          {...bind('lon')}
          fullWidth
          label='Довжина'
          margin='normal'
          variant='outlined'
        />
        <TextField
          {...bind('phone')}
          fullWidth
          label='Телефон'
          margin='normal'
          variant='outlined'
        />
        <TextField
          {...bind('viber')}
          fullWidth
          label='Viber'
          margin='normal'
          variant='outlined'
        />
        <TextField
          {...bind('telegram')}
          fullWidth
          label='Telegram'
          margin='normal'
          variant='outlined'
        />
        <Grid item>
          <Checkbox
            {...bind('showParkingInstruction')}
            checked={city.showParkingInstruction ?? false}
            onChange={(e, checked) => {
              setCity(prevState => ({
                ...prevState,
                showParkingInstruction: Boolean(checked)
              }));
            }}
          />
          Відображати інструкцію паркування
        </Grid>
        {isSuperAdmin ? (
          <Grid item>
            <Typography fontWeight={'500'} variant='h6'>
              Налаштування LiqPay
            </Typography>
            <Checkbox
              checked={allowStatusWaitAccept ?? false}
              onChange={(e, checked) => {
                setAllowStatusWaitAccept(Boolean(checked));
                if (cityId) {
                  dispatch(
                    setCityLiqPayConfigAsync({
                      city: cityId,
                      allowStatusWaitAccept: Boolean(checked)
                    })
                  );
                }
              }}
            />
            Приймати платежі "wait_accept"
          </Grid>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={handleSave}>
          Зберегти
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CitiesDialog;
