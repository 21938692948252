import { useCallback, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { signOut } from '../../redux/slices/authSlice';
import useRoutes, { Route } from '../../containers/Router/useRoutes';
import { selectCities } from '../../redux/slices/citiesSlice';
import { selectAdmins } from '../../redux/slices/adminsSlice';
import Logo from '../Logo';
import { RootState } from '../../redux/store';
import usePermissions from '../../hooks/usePermissions';

const PREFIX = 'Sidebar';

const classes = {
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  city: `${PREFIX}-city`,
  bottomSection: `${PREFIX}-bottomSection`,
  exitButton: `${PREFIX}-exitButton`,
  list: `${PREFIX}-list`,
  link: `${PREFIX}-link`,
  activeLink: `${PREFIX}-activeLink`,
  nested: `${PREFIX}-nested`
};

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  [`&.${classes.drawer}`]: {
    width: SIDEBAR_WIDTH,
    flexShrink: 0
  },

  [`& .${classes.drawerPaper}`]: {
    width: SIDEBAR_WIDTH
  },

  [`& .${classes.city}`]: {
    marginTop: 'auto',
    padding: 8
  },

  [`& .${classes.bottomSection}`]: {
    marginTop: 8,
    padding: 8
  },

  [`& .${classes.exitButton}`]: {
    marginTop: 5
  },

  [`& .${classes.list}`]: {
    padding: 8
  },

  [`& .${classes.link}`]: {
    padding: '2px 8px',
    borderRadius: 4,
    color: theme.palette.common.black,
    cursor: 'pointer',
    userSelect: 'none',
    '& + &': {
      marginTop: 5
    },
    '& svg': {
      color: theme.palette.grey.A400
    }
  },

  [`& .${classes.activeLink}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '& svg': {
      color: theme.palette.common.white
    }
  },

  [`& .${classes.nested}`]: {
    paddingLeft: theme.spacing(4)
  }
}));

export const SIDEBAR_WIDTH = 300;

interface SidebarItemProps {
  link: Route;
  nested?: boolean;
}

const SidebarItem = (props: SidebarItemProps) => {
  const { link } = props;
  const checkPermission = usePermissions();

  const isHavePermission = useMemo(() => {
    if (!link?.code) {
      return true;
    }
    return checkPermission(link.code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link?.code]);

  console.log('-=-=- isHavePermission', link?.code, isHavePermission);

  const [open, setOpen] = useState(false);

  const toggleCollapse = useCallback(
    () => setOpen(prevState => !prevState),
    []
  );

  const Item = useCallback(
    ({ item, nested, onClick }) => {
      const hasAccess = item?.code ? checkPermission(item.code) : true;

      if (!hasAccess) return null;

      return (
        <ListItem
          activeClassName={classes.activeLink}
          className={classNames(classes.link, nested && classes.nested)}
          component={item.nested ? 'div' : NavLink}
          key={item.path}
          to={item.path}
          onClick={onClick}
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.label} />
          {item.nested && (open ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open]
  );

  if (!isHavePermission) return null;

  return (
    <>
      <Item item={link} onClick={toggleCollapse} />
      {link.nested && (
        <Collapse unmountOnExit in={open} timeout='auto'>
          <List disablePadding className={classes.list} component='div'>
            {link.nested.map((nestedLink, index) => (
              <Item key={index} nested item={nestedLink} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

const Sidebar = () => {
  const dispatch = useDispatch();
  const links = useRoutes();

  const cities = useSelector(selectCities);
  const adminInfo = useSelector(selectAdmins);
  const sidebarVisible = useSelector(
    (state: RootState) => state.app.sidebarVisible
  );

  const cityName = useMemo(() => {
    const city = cities.items.find(item => item._id === adminInfo.currentCity);
    if (city) {
      return city.cityName;
    }
  }, [adminInfo.currentCity, cities.items]);

  if (!sidebarVisible) {
    return null;
  }

  return (
    <StyledDrawer
      anchor='left'
      classes={{
        paper: classes.drawerPaper
      }}
      className={classes.drawer}
      variant='permanent'
    >
      <Logo alignSelf='center' />
      <List className={classes.list}>
        {links.map((link, index) => (
          <SidebarItem key={index} link={link} />
        ))}
      </List>
      <div className={classes.city}>
        <Typography>Поточне місто: {cityName || '-'}</Typography>
      </div>
      <div className={classes.bottomSection}>
        <Divider />
        <ListItem
          button
          className={`${classes.link} ${classes.exitButton}`}
          onClick={() => dispatch(signOut())}
        >
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary='Вихід' />
        </ListItem>
      </div>
    </StyledDrawer>
  );
};

export default Sidebar;
