import React from 'react';
import { DotProps } from './interface';
import styled from 'styled-components';
import { Tooltip } from '@material-ui/core';

const StyledDot = styled.div<DotProps>`
  flex: none;
  display: inline-flex;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 50%;
  background-color: ${props =>
    props.type === 'empty' ? 'transparent' : props.color};
  border: ${props => (props.border ? `1px solid ${props.color}` : 'none')};
`;

const Dot: React.FC<DotProps> = props => {
  const {
    color = 'black',
    size = 10,
    type = 'filled',
    border = false,
    tooltip = '',
    ...restProps
  } = props;

  return (
    <Tooltip
      children={
        <StyledDot
          color={color}
          size={size}
          type={type}
          border={border}
          {...restProps}
        />
      }
      title={tooltip}
    ></Tooltip>
  );
};

export default Dot;
