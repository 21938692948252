import { useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Title from '../../components/Title';
import { formatDate } from '../../resources/functions';
import TableCell from '../../components/TableCell';
import { Subscription } from '../../resources/types/balanceTypes';
import {
  getSubscriptionAsync,
  selectSubscriptions,
  deleteSubscriptionAsync
} from '../../redux/slices/subscriptionSlice';
import { RootState } from '../../redux/store';
import SubscriptionModal from '../../modals/SubscriptionModal';
import { Delete, Edit } from '@mui/icons-material';
import { selectCities } from '../../redux/slices/citiesSlice';
import { selectAdmins } from '../../redux/slices/adminsSlice';

const PREFIX = 'SubscriptionPage';

const classes = {
  header: `${PREFIX}-header`,
  addButton: `${PREFIX}-addButton`,
  tableContainer: `${PREFIX}-tableContainer`
};

const Root = styled('div')({
  [`& .${classes.header}`]: {
    display: 'flex',
    alignItems: 'center'
  },
  [`& .${classes.addButton}`]: {
    marginLeft: 20
  },
  [`& .${classes.tableContainer}`]: {
    marginTop: 40
  }
});

const SubscriptionPage = () => {
  const dispatch = useDispatch();

  const cities = useSelector(selectCities);
  const adminInfo = useSelector(selectAdmins);

  const cityId = useMemo(() => {
    const city = cities.items.find(item => item._id === adminInfo.currentCity);
    if (city) {
      return city._id;
    }
  }, [adminInfo.currentCity, cities.items]);

  const { subscriptions } = useSelector(selectSubscriptions);
  console.log('-=-=-subscriptions', subscriptions);

  const [open, setOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] =
    useState<Subscription | null>(null);

  const isSuperAdmin = useSelector(
    (state: RootState) => state.auth.info?.type === 'superAdmin'
  );

  useEffect(() => {
    dispatch(getSubscriptionAsync(cityId ?? ''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteSubscription = (_id?: string) => {
    if (!_id?.length) return;
    // eslint-disable-next-line no-restricted-globals
    const confirmation = confirm(
      'Ви впевнені що хочете видалити цей абонемент?'
    );
    if (confirmation) {
      dispatch(deleteSubscriptionAsync(_id, cityId ?? ''));
    }
  };

  return (
    <Root>
      <div className={classes.header}>
        <Title>Абонементи</Title>
        <Button
          className={classes.addButton}
          color='primary'
          startIcon={<AddIcon />}
          variant='text'
          onClick={() => {
            setOpen(true);
            setSelectedSubscription(null);
          }}
        >
          Створити aбонемент
        </Button>
      </div>
      {Boolean(subscriptions?.items?.length) && (
        <div>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Назва</TableCell>
                  <TableCell>Днів</TableCell>
                  <TableCell>Ціна</TableCell>
                  <TableCell>Дата створення</TableCell>
                  <TableCell>Дата оновлення</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {subscriptions?.items?.map(subscription => (
                  <TableRow hover key={subscription._id}>
                    <TableCell>{subscription.name}</TableCell>
                    <TableCell>{subscription.duration}</TableCell>
                    <TableCell>{subscription.price}</TableCell>
                    <TableCell>{formatDate(subscription.createdAt)}</TableCell>
                    <TableCell>{formatDate(subscription.updatedAt)}</TableCell>
                    <TableCell>
                      {isSuperAdmin ? (
                        <>
                          <IconButton
                            size='small'
                            onClick={() => {
                              setOpen(true);
                              setSelectedSubscription(subscription);
                            }}
                          >
                            <Edit />
                          </IconButton>

                          <IconButton
                            size='small'
                            onClick={() =>
                              deleteSubscription(subscription?._id)
                            }
                          >
                            <Delete fontSize='small' />
                          </IconButton>
                        </>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
      <SubscriptionModal
        open={open}
        setOpen={setOpen}
        selectedSubscription={selectedSubscription}
        setSelectedSubscription={setSelectedSubscription}
      />
    </Root>
  );
};

export default SubscriptionPage;
