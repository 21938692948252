import { useCallback, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Add, Delete, Edit } from '@mui/icons-material';
import Title from '../../components/Title';
import {
  deleteCityAsync,
  getCitiesAsync,
  selectCities
} from '../../redux/slices/citiesSlice';
import CitiesDialog from './CitiesDialog';
import { Props } from './interface';
import { selectAdmins, setCurrentCity } from '../../redux/slices/adminsSlice';

const PREFIX = 'CitiesPage';

const classes = {
  header: `${PREFIX}-header`
};

const Root = styled('div')({
  [`& .${classes.header}`]: {
    marginBottom: 20
  }
});

const CitiesPage = (props: Props) => {
  const { isSuperAdmin } = props;

  const citiesStore = useSelector(selectCities);
  const adminInfo = useSelector(selectAdmins);

  const dispatch = useDispatch();

  const cities = useMemo(
    () => (isSuperAdmin ? citiesStore.items : adminInfo.info?.cities || []),
    [isSuperAdmin, citiesStore.items, adminInfo.info]
  );

  const [dialogState, setDialogState] = useState<{
    open: boolean;
    cityId?: string;
  }>({ open: false });

  const openDialog = useCallback(
    (cityId?: string) => () => setDialogState({ open: true, cityId }),
    []
  );

  const closeDialog = useCallback(() => setDialogState({ open: false }), []);

  const deleteCity = useCallback(
    (cityId: string) => async () => {
      // eslint-disable-next-line no-restricted-globals
      const confirmation = confirm("Ви впевнені що хочете видалити місто?");
      if (confirmation) {
        dispatch(deleteCityAsync(cityId));
        dispatch(getCitiesAsync());
      }
    },
    [dispatch]
  );

  const createSetCityHandler = useCallback(
    cityId => () => {
      if (isSuperAdmin && cityId === adminInfo.currentCity) {
        dispatch(setCurrentCity(undefined));
      } else {
        dispatch(setCurrentCity(cityId));
      }
    },
    [adminInfo.currentCity, dispatch, isSuperAdmin]
  );

  return (
    <Root>
      <Grid
        container
        alignItems='center'
        className={classes.header}
        spacing={4}
      >
        <Grid item>
          <Title>Міста</Title>
        </Grid>
        {isSuperAdmin && (
          <Grid item>
            <Button
              color='primary'
              startIcon={<Add />}
              variant='outlined'
              onClick={openDialog()}
            >
              Додати
            </Button>
          </Grid>
        )}
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Назва</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {cities.map(city => (
              <TableRow key={city._id}>
                <TableCell>
                  <Checkbox
                    checked={adminInfo.currentCity === city._id}
                    color='primary'
                    onChange={createSetCityHandler(city._id)}
                  />
                </TableCell>
                <TableCell>{city.cityName}</TableCell>
                <TableCell align='right'>
                  {
                    isSuperAdmin ? (
                      <>
                        <IconButton size='small' onClick={openDialog(city._id)}>
                          <Edit fontSize='small' />
                        </IconButton>
                        <IconButton size='small' onClick={deleteCity(city._id)}>
                          <Delete fontSize='small' />
                        </IconButton>
                      </>
                    ) : null
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CitiesDialog {...dialogState} onClose={closeDialog} />
    </Root>
  );
};

export default CitiesPage;
