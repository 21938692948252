import { createApi } from '@reduxjs/toolkit/query/react';
import { Admin } from '../../resources/adminsTypes';
import baseQuery from './baseQuery';

export const adminsApi = createApi({
  reducerPath: 'adminsApi',
  baseQuery,
  endpoints: builder => ({
    getAdmins: builder.query<Admin[], void>({
      query: () => 'admins',
      transformResponse(response: { admins: Admin[] }) {
        return response.admins;
      }
    })
  })
});

export const { useGetAdminsQuery } = adminsApi;
