import { createApi } from '@reduxjs/toolkit/query/react';
import { City } from '../../resources/types/citiesTypes';
import baseQuery from './baseQuery';

export const citiesApi = createApi({
  reducerPath: 'citiesApi',
  baseQuery,
  endpoints: builder => ({
    getCities: builder.query<City[], void>({
      query: () => 'cities',
      transformResponse(response: { cities: City[] }) {
        return response.cities;
      }
    })
  })
});

export const { useGetCitiesQuery } = citiesApi;
