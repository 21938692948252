import React from "react";
import styled from "styled-components";
import { Menu } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarVisible } from "../../redux/slices/appSlice";
import { RootState } from "../../redux/store";

const Button = styled(IconButton)`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1201;
`;

const SidebarToggleButton = () => {
  const dispatch = useDispatch();
  const sidebarVisible = useSelector(
    (state: RootState) => state.app.sidebarVisible
  );

  const handleClick = () => {
    dispatch(setSidebarVisible(!sidebarVisible));
  };

  return (
    <Button color="primary" onClick={handleClick}>
      <Menu />
    </Button>
  );
};

export default SidebarToggleButton;
