import { News, UpdateNewsData } from '../../resources/types/newsTypes';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import axios from '../../resources/axios';

interface NewsState {
  items: News[];
  once?: News;
}

const initialState: NewsState = {
  items: []
}

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setNews: (state, action) => {
      state.items = action.payload.data;
    },
    setNewsOnce: (state, action) => {
      state.once = action.payload.data;
    }
  }
})

const { setNews, setNewsOnce } = newsSlice.actions;

export const getNewsAsync = (): AppThunk => async dispatch => {
  const response = await axios.get('/news');
  dispatch(setNews(response.data));
}

export const getNewsOnceAsync = (): AppThunk => async dispatch => {
  const response = await axios.get('/news/once');
  dispatch(setNewsOnce(response.data));
}

export const updateNewsAsync = (data: UpdateNewsData): AppThunk => async () => {
  await axios.put('/news/once', data);
}

export const selectNews = (state: RootState) => state.news;

export default newsSlice.reducer;
