import { MenuItem, TextField } from '@mui/material';
import { userTypes } from '../../resources/constants';
import { Props } from './interface';

const UserTypeSelect = ({ value, onChange, allOption, required }: Props) => {
  return (
    <TextField
      fullWidth
      select
      label='Тип користувача'
      margin='normal'
      required={required}
      value={value}
      onChange={onChange}
    >
      {allOption && <MenuItem value=''>Усі</MenuItem>}
      {userTypes.map(type => (
        <MenuItem key={type.value} value={type.value}>
          {type.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default UserTypeSelect;
