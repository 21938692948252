import React, { useCallback, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Checkbox,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material';
import { Pagination } from '@mui/material';
import Select from '../../components/Select';
import Title from '../../components/Title';
import {
  useGetNoticesQuery,
  useGetNoticeTypesQuery,
  useSetNoticeViewedMutation
} from '../../redux/api/noticesApi';
import TableCell from '../../components/TableCell';
import { formatDate } from '../../resources/functions';
import { Notice } from '../../resources/types/noticesTypes';
import { InputChangeEvent } from '../../resources/utils';
import { DateRange } from '@material-ui/pickers';
import { Moment } from 'moment';
import DateTimeRangePicker from '../../components/DateTimeRangePicker';

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

const PREFIX = 'NoticesPage';

const classes = {
  filters: `${PREFIX}-filters`,
  filter: `${PREFIX}-filter`,
  tableContainer: `${PREFIX}-tableContainer`
};

const Root = styled('div')({
  [`& .${classes.filters}`]: {
    marginTop: 20
  },
  [`& .${classes.filter}`]: {
    width: 140
  },
  [`& .${classes.tableContainer}`]: {
    marginTop: 40,
    marginBottom: 40
  }
});

const limit = 20;

const NoticesPage = () => {
  const [page, setPage] = useState(1);

  const [viewed, setViewed] = useState('');

  const [type, setType] = useState('');

  const [dateRange, setDateRange] = useState<DateRange<Moment | null>>([
    null,
    null
  ]);

  const dateFilter = useMemo(() => {
    const isValid = dateRange?.[0]?.isValid() && dateRange?.[1]?.isValid();
    const startDate = dateRange?.[0]?.format?.(DATE_FORMAT) ?? null;
    const endDate = dateRange?.[1]?.format?.(DATE_FORMAT) ?? null;
    return isValid ? { startDate, endDate } : {};
  }, [dateRange]);

  const { data } = useGetNoticesQuery({
    limit,
    page,
    viewed,
    type,
    ...dateFilter
  });
  const notices = data?.adminNotices || [];

  const { data: noticeTypesData } = useGetNoticeTypesQuery();
  const noticeTypes = noticeTypesData || [];

  const [setNoticeViewed, { isLoading: updateNoticePending }] =
    useSetNoticeViewedMutation();

  const changeViewedFilter = useCallback((e: InputChangeEvent) => {
    setViewed(e.target.value);
  }, []);

  const changePage = useCallback((e: any, nextPage: number) => {
    setPage(nextPage);
  }, []);

  const updateNoticeViewed = useCallback(
    (notice: Notice) => () => {
      setNoticeViewed({ id: notice._id, viewed: !notice.viewed });
    },
    [setNoticeViewed]
  );

  return (
    <Root>
      <Title>Повідомлення</Title>
      <Grid container spacing={2} className={classes.filters}>
        <Grid item>
          <TextField
            select
            className={classes.filter}
            label='Статус'
            value={viewed}
            onChange={changeViewedFilter}
          >
            <MenuItem value=''>Усі</MenuItem>
            <MenuItem value='true'>Переглянуті</MenuItem>
            <MenuItem value='false'>Нові</MenuItem>
          </TextField>
        </Grid>
        <Grid item>
          <Select
            className={classes.filter}
            options={[
              {
                label: 'Усі',
                value: ''
              },
              ...noticeTypes.map(type => ({
                label: type,
                value: type
              }))
            ]}
            onChange={setType}
            value={type}
            label='Тип'
          />
        </Grid>
        <Grid item>
          <DateTimeRangePicker
            startText='Від'
            endText='До'
            value={dateRange}
            onChange={(date: DateRange<Moment>) => {
              setDateRange(date);
            }}
          />
        </Grid>
        {/* <Grid item>
          <TextField
            label='Зникли з онлайну'
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            value={data?.scooterDisconect}
          />
        </Grid>
        <Grid item>
          <TextField
            label='100+ м'
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            value={data?.largeDistant}
          />
        </Grid>
        <Grid item>
          <TextField
            label='Звичайні'
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            value={data?.usual}
          />
        </Grid>
        <Grid item>
          <TextField
            label='Поза зоною'
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            value={data?.outsideGreenZone}
          />
        </Grid> */}
      </Grid>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Текст</TableCell>
              <TableCell>Дата</TableCell>
              <TableCell align='center'>Переглянуто</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notices.map(notice => (
              <TableRow key={notice._id}>
                <TableCell>{notice.text}</TableCell>
                <TableCell>
                  {formatDate(notice.createdAt, { time: true })}
                </TableCell>
                <TableCell align='center'>
                  <Checkbox
                    checked={notice.viewed}
                    color='primary'
                    disabled={updateNoticePending}
                    onChange={updateNoticeViewed(notice)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination count={data?.pagination.totalPages} onChange={changePage} />
    </Root>
  );
};

export default NoticesPage;
