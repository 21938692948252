import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableCell from '../../components/TableCell';
import FaqModal from '../../modals/FAQModal';
import { deleteFAQAsync, getFAQAsync } from '../../redux/slices/faqSlice';
import { RootState } from '../../redux/store';
import { FAQ } from '../../resources/types/faqTypes';

const FaqPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFAQAsync());
  }, [dispatch]);

  const faq = useSelector((state: RootState) => state.faq.items);

  const deleteFAQ = async (faqId: string) => {
    if (window.confirm('Видалити?')) {
      await dispatch(deleteFAQAsync(faqId));
      dispatch(getFAQAsync());
    }
  };

  const [faqDialog, setFaqDialog] = useState<{ open: boolean; faq?: FAQ }>({
    open: false
  });

  return (
    <div>
      <Grid container justifyContent='flex-end'>
        <Button
          variant='contained'
          color='primary'
          style={{ marginBottom: 24 }}
          onClick={() => setFaqDialog({ open: true })}
        >
          Створити
        </Button>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Запитання</TableCell>
              <TableCell>Відповідь</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {faq.map(item => (
              <TableRow key={item._id}>
                <TableCell>{item?.question?.ua ?? ''}</TableCell>
                <TableCell>{item?.answer?.ua ?? ''}</TableCell>
                <TableCell>
                  <IconButton size='small'>
                    <Edit
                      onClick={() => setFaqDialog({ open: true, faq: item })}
                    />
                  </IconButton>
                  <IconButton size='small'>
                    <Delete onClick={() => deleteFAQ(item._id)} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <FaqModal {...faqDialog} onClose={() => setFaqDialog({ open: false })} />
    </div>
  );
};

export default FaqPage;
