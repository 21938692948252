import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import axios from '../../resources/axios';
import { handleDefaultErrors } from '../../resources/functions';
import { DayIndex, WorkTime } from '../../resources/types/settingsTypes';

interface SettingsState {
  phones: string[];
  workTime: WorkTime | null;
}

const initialState: SettingsState = {
  phones: [],
  workTime: null
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    getSettings: (state, action) => {
      state.phones = action.payload.adminSettings.phones;
      state.workTime = action.payload.adminSettings.workTime;
    },
    changeWorkTimeSettings: (state, action) => {
      if (!state.workTime) return;
      const { dayKey, prop, value } = action.payload;
      state.workTime[dayKey as DayIndex][prop as 'from' | 'to'] = value;
    },
    changePhoneSettings: (state, action) => {
      state.phones = action.payload;
    }
  }
});

const { getSettings } = settingsSlice.actions;

export const {
  changePhoneSettings,
  changeWorkTimeSettings
} = settingsSlice.actions;

export const getSettingsAsync = (): AppThunk => async dispatch => {
  try {
    const response = await axios.get('/admins/settings');
    dispatch(getSettings(response.data));
  } catch (error) {
    handleDefaultErrors(error);
  }
};

export const updateSettingsAsync = (): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    await axios.put('/admins/settings', getState().settings);
  } catch (error) {
    handleDefaultErrors(error);
  }
};

export const selectSettings = (state: RootState) => state.settings;

export default settingsSlice.reducer;
