import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetNoticesParams, Notice } from '../../resources/types/noticesTypes';
import { AppThunk, RootState } from '../store';
import axios from '../../resources/axios';
import { handleDefaultErrors } from '../../resources/functions';

interface NoticesState {
  items: Notice[];
  total: number;
  largeDistant: number;
  outsideGreenZone: number;
  scooterDisconect: number;
  usual: number;
  types: string[];
  notViewedCount: number | null;
  needPlayAlert: boolean;
  needPlayStolenAlert: boolean;
}

const initialState: NoticesState = {
  items: [],
  total: 0,
  largeDistant: 0,
  outsideGreenZone: 0,
  scooterDisconect: 0,
  usual: 0,
  types: [],
  notViewedCount: null,
  needPlayAlert: false,
  needPlayStolenAlert: false,
};

const noticesSlice = createSlice({
  name: 'notices',
  initialState,
  reducers: {
    setNeedPlayAlert: (state, action: PayloadAction<boolean>) => {
      state.needPlayAlert = action.payload;
    },
    setNeedPlayStolenAlert: (state, action: PayloadAction<boolean>) => {
      state.needPlayStolenAlert = action.payload;
    },
    checkNotViewedNotices: (
      state,
      action: PayloadAction<{ notViewedCount: number }>
    ) => {
      if (
        state.notViewedCount &&
        state.notViewedCount < action.payload.notViewedCount
      ) {
        state.needPlayAlert = true;
      }

      state.notViewedCount = action.payload.notViewedCount;
    },
    getNotices: (state, action) => {
      state.items = action.payload.adminNotices;
      state.total = action.payload.pagination.count;
      state.largeDistant = action.payload.largeDistant;
      state.outsideGreenZone = action.payload.outsideGreenZone;
      state.scooterDisconect = action.payload.scooterDisconect;
      state.usual = action.payload.usual;
    },
    getTypes: (state, action) => {
      state.types = action.payload.data;
    }
  }
});

export const { setNeedPlayAlert, checkNotViewedNotices, getNotices, getTypes, setNeedPlayStolenAlert } =
  noticesSlice.actions;

export const getNoticesAsync =
  (params?: GetNoticesParams): AppThunk =>
  async dispatch => {
    try {
      const response = await axios.get('/admins/notices', { params });
      dispatch(getNotices(response.data));
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const updateNoticeViewedAsync =
  (noticeId: string, viewed: boolean, success?: () => void): AppThunk =>
  async () => {
    try {
      await axios.put(`/admins/notices/${noticeId}`, { viewed });

      if (success) {
        success();
      }
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const getNoticeTypesAsync = (): AppThunk => async dispatch => {
  try {
    const response = await axios.get('/admins/notices/types');
    dispatch(getTypes(response.data));
  } catch (e) {
    handleDefaultErrors(e);
  }
};

export const selectNotices = (state: RootState) => state.notices;

export default noticesSlice.reducer;
