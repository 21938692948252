import { Button, ButtonGroup } from '@mui/material';
import React from 'react';
import { Languages } from '../../resources/types';
import { LanguageSelectProps } from './LanguageSelectTypes';
import { TranslationLanguages } from '../../resources/types/faqTypes';

const LanguageSelect = ({ onChange, value }: LanguageSelectProps) => {
  return (
    <ButtonGroup>
      {Object.keys(TranslationLanguages).map(lang => (
        <Button
          key={lang}
          onClick={() => onChange(lang as TranslationLanguages)}
          variant={value === lang ? 'contained' : 'outlined'}
        >
          {lang}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default LanguageSelect;
