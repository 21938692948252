import React from 'react';
import { TextField, FormLabel, Grid } from '@mui/material';
import { ISubscriptionContractTypePrices } from '../../resources/types/balanceTypes';

interface ISubscriptionModalPricesGroupProps {
  label: string;
  textFieldPlaceholder?: string;
  valueKey: string;
  values: ISubscriptionContractTypePrices;
  only?: string[];
  startTitle?: string;
  normalTitle?: string;
  reservationTitle?: string;
  pauseTitle?: string;
  onChange: (key: string, value: number | string | null) => void;
}

const SubscriptionModalPricesGroup = ({
  label,
  values,
  valueKey,
  onChange,
  textFieldPlaceholder = 'Ціна',
  startTitle = 'start',
  normalTitle = 'normal',
  reservationTitle = 'reservation',
  pauseTitle = 'pause',
  only = []
}: ISubscriptionModalPricesGroupProps) => {
  console.log('-=-=- SubscriptionModalPricesGroup', only);
  return (
    <Grid item container direction='column' mt={3} mb={1}>
      <FormLabel sx={{ m: 0 }} style={{ fontWeight: 'bold' }}>
        {label}
      </FormLabel>
      {only.length === 0 || only.includes('start') ? (
        <TextField
          fullWidth
          label={`${textFieldPlaceholder} ${startTitle}`}
          margin='normal'
          type='number'
          name={`${valueKey}.start`}
          value={values?.start ?? null}
          onChange={e => {
            onChange('start', Number(e.target.value ?? 0));
          }}
        />
      ) : null}

      {only.length === 0 || only.includes('normal') ? (
        <TextField
          fullWidth
          label={`${textFieldPlaceholder} ${normalTitle}`}
          margin='normal'
          type='number'
          name={`${valueKey}.normal`}
          value={values?.normal ?? null}
          onChange={e => {
            onChange('normal', Number(e.target.value ?? 0));
          }}
        />
      ) : null}

      {only.length === 0 || only.includes('reservation') ? (
        <TextField
          fullWidth
          label={`${textFieldPlaceholder} ${reservationTitle}`}
          margin='normal'
          type='number'
          name={`${valueKey}.reservation`}
          value={values?.reservation ?? null}
          onChange={e => {
            onChange('reservation', Number(e.target.value ?? 0));
          }}
        />
      ) : null}

      {only.length === 0 || only.includes('pause') ? (
        <TextField
          fullWidth
          label={`${textFieldPlaceholder} ${pauseTitle}`}
          margin='normal'
          type='number'
          name={`${valueKey}.pause`}
          value={values?.pause ?? null}
          onChange={e => {
            onChange('pause', Number(e.target.value ?? 0));
          }}
        />
      ) : null}
    </Grid>
  );
};

export default SubscriptionModalPricesGroup;
