import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import BonusGamePage from '../../pages/BonusGamePage';
import FaqPage from '../../pages/FAQPage';
import CitiesBalancePage from '../../pages/CitiesBalancePage';
import ScootersLockHistoryPage from '../../pages/ScootersLockHistoryPage';
import ZonesPage from '../../pages/ZonesPage';
import { selectAuth } from '../../redux/slices/authSlice';
import PromocodesPage from '../../pages/PromocodesPage';
import UsersPage from '../../pages/UsersPage';
import PushesPage from '../../pages/PushesPage';
import NoticesPage from '../../pages/NoticesPage';
import NotesPage from '../../pages/NotesPage';
import SettingsPage from '../../pages/SettingsPage';
import TariffsPage from '../../pages/TariffsPage';
import HistoryPage from '../../pages/HistoryPage';
import CitiesPage from '../../pages/CitiesPage';
import AdminsPage from '../../pages/AdminsPage';
import TabsPage from '../../pages/TabsPage/TabsPage';
import StatisticPage from '../../pages/StatisticPage/StatisticPage';
import { selectAdmins } from '../../redux/slices/adminsSlice';
import PaymentIcon from '@mui/icons-material/Payment';
import MapIcon from '@mui/icons-material/Map';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import GroupIcon from '@mui/icons-material/Group';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import {
  AddAlert,
  Announcement,
  History,
  LiveHelp,
  Payment,
  Room,
  Settings,
  SportsEsports,
  BarChart,
  LocationCity,
  Tab,
  StickyNote2,
  Reviews
} from '@mui/icons-material';
import { checkPermission } from '../../resources/functions';
import NewsPage from '../../pages/NewsPage';
import TrackitPage from '../../pages/TrackitPage';
import SubscriptionPage from '../../pages/SubscriptionPage';
import FeedbacksPage from '../../pages/FeedbacksPage';

export interface Route {
  path: string;
  label?: string;
  code?: string;
  component?: (props: any) => JSX.Element | null;
  nested?: Route[];
  icon?: ReactElement;
}

const useRoutes = (): Route[] => {
  const auth = useSelector(selectAuth);
  const admin = useSelector(selectAdmins);
  console.log('-=-= admin', admin);

  if (!auth.info) return [];

  const links: Route[] = [
    {
      label: 'Управління',
      icon: <MapIcon color='inherit' />,
      path: '/control',
      code: 'control',
      component: ZonesPage
    },
    {
      label: 'Історія блок/розблок',
      icon: <History color='inherit' />,
      path: '/scooters-lock-history',
      code: 'scooters-lock-history',
      component: ScootersLockHistoryPage
    },
    {
      label: 'Промокоди',
      icon: <CardGiftcardIcon color='inherit' />,
      path: '/promocodes',
      code: 'bonuscodes',
      component: PromocodesPage
    },
    {
      label: 'Абонементи',
      icon: <CardGiftcardIcon color='inherit' />,
      path: '/subscriptions',
      code: 'tariffs',
      component: SubscriptionPage
    },
    {
      label: 'Бонусна гра',
      icon: <SportsEsports color='inherit' />,
      path: '/bonus-game',
      code: 'bonusgame',
      component: BonusGamePage
    },
    {
      label: 'Користувачі',
      icon: <GroupIcon color='inherit' />,
      path: '/users',
      code: 'users',
      component: UsersPage
    },
    {
      label: 'Пуш-повідомлення',
      icon: <AddAlert color='inherit' />,
      path: '/pushes',
      code: 'pushes',
      component: PushesPage
    },
    {
      label: 'Повідомлення',
      icon: <NotificationsActiveIcon color='inherit' />,
      path: '/notices',
      code: 'notices',
      component: NoticesPage
    },
    {
      label: 'Налаштування',
      icon: <Settings color='inherit' />,
      path: '/settings',
      code: 'settings',
      component: SettingsPage
    },
    {
      label: 'Транзакції',
      icon: <History color='inherit' />,
      path: '/history',
      code: 'history',
      component: HistoryPage
    },
    {
      label: 'Новини',
      icon: <Announcement color='inherit' />,
      path: '/news',
      code: 'news',
      component: NewsPage
    },
    {
      label: 'Відгуки',
      icon: <Reviews color='inherit' />,
      path: '/feedbacks',
      code: 'contractReviews',
      component: FeedbacksPage
    },
    {
      label: 'Нотатки',
      icon: <StickyNote2 color='inherit' />,
      path: '/notes',
      code: 'scooter_notes',
      component: NotesPage
    },
    {
      label: 'Trackit',
      icon: <Room color='inherit' />,
      path: '/trackit',
      code: 'trackit',
      component: TrackitPage
    },
    {
      label: 'FAQ',
      icon: <LiveHelp color='inherit' />,
      path: '/faq',
      code: 'faq',
      component: FaqPage
    }
  ];

  const isSuperAdmin = auth.info.type === 'superAdmin';
  const isAminsCrud = checkPermission(admin.info?.tabs ?? [], 'admins crud');

  if (isSuperAdmin || isAminsCrud) {
    links.push({
      label: 'Панель адміністратора',
      path: '/admin/cities',
      icon: <Settings color='inherit' />,
      nested: [
        {
          label: 'Міста',
          path: '/admin/cities',
          component: props => (
            <CitiesPage {...props} isSuperAdmin={isSuperAdmin} />
          ),
          icon: <LocationCity color='inherit' />
        },
        {
          label: 'Адміністратори',
          path: '/admin/admins',
          component: AdminsPage,
          icon: <GroupIcon color='inherit' />
        },
        ...(isSuperAdmin
          ? [
              {
                label: 'Таби',
                path: '/admin/tabs',
                component: TabsPage,
                icon: <Tab color='inherit' />
              },
              {
                label: 'Баланс міст',
                path: '/admin/citiesBalance',
                component: CitiesBalancePage,
                icon: <Payment color='inherit' />
              }
            ]
          : []),

        {
          label: 'Статистика',
          path: '/admin/stats',
          component: StatisticPage,
          code: 'statistics',
          icon: <BarChart color='inherit' />
        }
      ]
    });
  } else {
    links.push({
      label: 'Вибір міста',
      path: '/cities',
      component: CitiesPage,
      icon: <Settings color='inherit' />
    });
  }

  links.push({
    label: 'Тарифи',
    icon: <PaymentIcon color='inherit' />,
    code: 'tariffs',
    path: '*',
    component: TariffsPage
  });

  if (auth.info.type !== 'superAdmin') {
    return links.filter(
      link =>
        !link.code || checkPermission(admin.info?.tabs || [], link.code || '')
    );
  }

  return links;
};

export default useRoutes;
