export enum TranslationLanguages {
  en = "en",
  ua = "ua"
}

export interface Translation {
  en: string;
  ua: string;
}

export interface FAQ {
  question: Translation;
  answer: Translation;
  cities: string[];
  _id: string;
}

export type NewFAQ = Omit<FAQ, '_id'>;
