import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { GoogleMap, Polygon, InfoWindow } from '@react-google-maps/api';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  useTheme,
  Typography,
  Stack,
  Box,
  SvgIcon
} from '@mui/material';
import { Pagination } from '@mui/material';
import {
  Block,
  Delete,
  Edit,
  History,
  LockOpen,
  Timeline,
  VolumeUp,
  RouteOutlined,
  NoteAdd,
  RestartAlt
} from '@mui/icons-material';
import moment, { Moment } from 'moment';
import clsx from 'clsx';

import Title from '../../components/Title';
import { useWebSocketContext } from '../../containers/WebSocketProvider';
import ContractsHistoryModal from '../../modals/ContractsHistoryModal';
import { TripTimeModal } from '../../modals/TripTimeModal';
import ZoneModal from '../../modals/ZoneModal';
import {
  deleteZoneAsync,
  getZonesAsync,
  getZoneTypesAsync,
  selectZones,
  setZoneState
} from '../../redux/slices/zonesSlice';
import { Zone } from '../../resources/types/zonesTypes';
import { formatDate } from '../../resources/functions';
import {
  deleteScooterAsync,
  formatCDScooterAsync,
  getScootersAsync,
  lockScooterAsync,
  selectScooters,
  signalScooterAsync,
  updateScooterInfoAsync
} from '../../redux/slices/scootersSlice';
import MapMarker from '../../containers/MapMarker';
import Collapse from '../../components/Collapse';
import TableCell from '../../components/TableCell';
import { Scooter } from '../../resources/types/scootersTypes';
import {
  TripHistoryDetails,
  TripTimeDialogState
} from '../../resources/types/contractsTypes';
import {
  exitContractAsync,
  getActiveContractsAsync,
  selectContracts
} from '../../redux/slices/contractsSlice';
import ScooterCounters from '../../components/ScooterCounters';
import UserMapMarker from '../../containers/UserMapMarker';
import usePermissions from '../../hooks/usePermissions';
import useFormInput from '../../hooks/useFormInput';
import { selectAdmins } from '../../redux/slices/adminsSlice';
import { selectAuth } from '../../redux/slices/authSlice';
import { selectCities } from '../../redux/slices/citiesSlice';
import Select from '../../components/Select';
import { SelectOption } from '../../components/Select/interface';
import { MapFilters, ScootersModalState, ScooterStatus } from './interface';
import ScootersModal from '../../modals/ScootersModal';
import Dot from '../../components/Dot';
// import MapLegend from './MapLegend';
import DateRangePicker from '../../components/DateRangePicker';
import { DateRange } from '@material-ui/pickers';
import { TimePicker } from '@mui/lab';
import { ReactComponent as CrownIcon } from '../../img/crown.svg';
import MapMarkersSection from '../../components/sections/MapMarkersSection';
import { classes, defaultCenter, LIMIT, zonesColors } from './constants';
import RentHeatMapSection from '../../components/sections/RentHeatMapSection';
import ZonesConfigSection from '../../components/sections/ZonesConfigSection';
import { TripCoordinatesModal } from '../../modals/TripCoordinatesModal/TripCoordinatesModal';
import ScooterNotesModal from '../../modals/ScooterNotesModal';
import {
  getDotColor,
  getDotTooltip,
  getScooterIcon
} from '../../helpers/scooter-markers-helper';

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.header}`]: {
    display: 'flex',
    alignItems: 'center'
  },

  [`& .${classes.uploadButton}`]: {
    marginLeft: 20
  },

  [`& .${classes.blockContainer}`]: {
    marginTop: 20
  },

  [`& .${classes.saveButton}`]: {
    margin: 20
  },

  [`& .${classes.infoWindow}`]: {
    padding: 6,
    paddingRight: 25,
    fontSize: 12,
    color: 'gray',
    whiteSpace: 'nowrap'
  },

  [`& .${classes.collapseLabel}`]: {
    padding: '10px 20px'
  },

  [`& .${classes.tableBody}`]: {
    '& td': {
      verticalAlign: 'top'
    }
  },

  [`& .${classes.filters}`]: {
    padding: '8px 20px !important'
  },

  [`& .${classes.counterWrap}`]: {
    padding: '0 20px'
  },

  [`& .${classes.lock}`]: {
    color: theme.palette.error.main
  },

  [`& .${classes.scooterIcons}`]: {
    display: 'inline-flex'
  },

  [`& .${classes.routeLink}`]: {
    color: theme.palette.primary.main,
    textDecoration: 'underline'
  },

  [`& .${classes.contractImages}`]: {
    display: 'flex'
  },

  [`& .${classes.contractImage}`]: {
    width: 64,
    height: 64,
    borderRadius: 8,
    '& + &': {
      marginLeft: 12
    },
    '& img': {
      width: '100%',
      height: '100%'
    }
  },

  [`& .${classes.tabButton}`]: {
    margin: 0,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: 'transparent',
    borderRadius: 0,
    minWidth: '130px',
    '&.active': {
      borderBottomColor: theme.palette.primary.main
    }
  },

  [`& .${classes.typeSelector}`]: {
    width: 180
  }
}));

const ZonesPage = () => {
  // const theme = useTheme();
  const dispatch = useDispatch();

  const auth = useSelector(selectAuth);
  const admins = useSelector(selectAdmins);
  const cities = useSelector(selectCities);

  const checkPermission = usePermissions();

  const { sendSocketMessage } = useWebSocketContext();

  const [scootersCity, setScootersCity] = useState(admins.currentCity ?? '');
  const [scootersModalState, setScootersModalState] =
    useState<ScootersModalState>({ open: false });
  const [scooterNotesModal, setScooterNotesModal] = useState<{
    open: boolean;
    scooter?: Scooter;
  }>({ open: false });
  const [zoneModalOpen, setZoneModalOpen] = useState(false);
  // const [batteryLevel, setBatteryLevel] = useState('');
  const [filteredByScooterName, setFilteredByScooterName] = useState('');
  const [scooterNumber, setScooterNumber] = useState('');

  const [lowBatteryLevel, setLowBatteryLevel] = useState('');
  const [isLowBatteryChecked, setIsLowBatteryChecked] = useState(false);
  const [mapFilters, setMapFilters] = useState<MapFilters>({});
  const searchScooter = useFormInput('');

  useEffect(() => {
    if (!checkPermission('zones')) {
      return;
    }

    dispatch(getZonesAsync(true));
    dispatch(getZoneTypesAsync());
  }, [checkPermission, dispatch]);

  const getScootersBySockets = useCallback(() => {
    if (!checkPermission('scooters') || !sendSocketMessage) {
      return;
    }
    console.log('-=-=- getScootersBySockets', {
      cityId: scootersCity,
      ...mapFilters,
      // free: false,
      // status: ScooterStatus.CHARGING,
      name: filteredByScooterName,
      // battery_value: +batteryLevel,
      ...(isLowBatteryChecked && { lt_battery: lowBatteryLevel })
    });
    sendSocketMessage('scooters', {
      cityId: scootersCity,
      ...mapFilters,
      // free: false,
      // status: ScooterStatus.CHARGING,
      name: filteredByScooterName,
      // battery_value: +batteryLevel,
      ...(isLowBatteryChecked && { lt_battery: lowBatteryLevel })
    });
  }, [
    checkPermission,
    filteredByScooterName,
    isLowBatteryChecked,
    lowBatteryLevel,
    mapFilters,
    scootersCity,
    sendSocketMessage
  ]);

  useEffect(() => {
    if (!checkPermission('scooters') || !sendSocketMessage) {
      return;
    }

    getScootersBySockets();

    const interval = setInterval(() => {
      getScootersBySockets();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [
    checkPermission,
    dispatch,
    scootersCity,
    sendSocketMessage,
    isLowBatteryChecked,
    lowBatteryLevel,
    filteredByScooterName,
    getScootersBySockets
  ]);

  const [isActiveContracts, setIsActiveContracts] = useState(false);
  const [page, setPage] = useState(1);

  const onChangeIsActiveContracts = () => {
    setIsActiveContracts(!isActiveContracts);
    setPage(1);
  };

  const onChangePage = (e: any, nextPage: number) => {
    setPage(nextPage);
  };

  // CONTRACTS TAB
  const adminInfo = useSelector(selectAdmins);
  const [userPhone, setUserPhone] = useState('');
  const [scooterName, setScooterName] = useState('');
  const [tripDate, setTripDate] = useState<DateRange<Moment | null>>([
    moment().set({ date: 1, hours: 0, minutes: 0, seconds: 0 }),
    moment().set({ hours: 23, minutes: 59, seconds: 59 })
  ]);
  const currentCityId = useMemo(() => {
    const city = cities.items.find(item => item._id === adminInfo.currentCity);
    if (city) {
      return city?._id;
    }
    return adminInfo?.currentCity;
  }, [adminInfo.currentCity, cities.items]);

  const [tripCity, setTripCity] = useState(currentCityId);
  const [tripTimeDialogState, setTripTimeDialogState] =
    useState<TripTimeDialogState>({
      open: false
    });

  const [tripCoordinatesDialogState, setTripCoordinatesDialogState] = useState<{
    open: boolean;
    tripId?: string;
  }>({
    open: false
  });

  const [filterByHours, setFilterByHours] = useState<{
    from: Date | null;
    to: Date | null;
  }>({
    from: moment()
      .set({
        hours: 0,
        minutes: 0
      })
      .toDate(),
    to: moment()
      .set({
        hours: 23,
        minutes: 59
      })
      .toDate()
  });
  const [isTimeFiltersTouched, setIsTimeFiltersTouched] = useState({
    from: false,
    to: false
  });

  const handleFilterByHoursFrom = (val: Date | null) => {
    setIsTimeFiltersTouched(prev => ({ ...prev, from: true }));
    setFilterByHours(prevVal => {
      return {
        ...prevVal,
        from: moment(val)
          .set({
            month: moment(tripDate[0]).get('month'),
            day: moment(tripDate[0]).get('day')
          })
          .toDate()
      };
    });
  };

  const handleFilterByHoursTo = (val: Date | null) => {
    setIsTimeFiltersTouched(prev => ({ ...prev, to: true }));
    setFilterByHours(prevVal => {
      return {
        ...prevVal,
        to: moment(val)
          .set({
            month: moment(tripDate[1]).get('month'),
            day: moment(tripDate[1]).get('day')
          })
          .toDate()
      };
    });
  };

  const getActiveContracts = useCallback(() => {
    if (!checkPermission('contracts')) {
      return;
    }

    dispatch(
      getActiveContractsAsync({
        active: isActiveContracts,
        page,
        limit: LIMIT,
        userPhone,
        scooterName,
        cityId: tripCity || 'all',
        from:
          isTimeFiltersTouched.from === true &&
          !isNaN(moment(filterByHours.from).unix() * 1000)
            ? moment(filterByHours.from).unix() * 1000
            : tripDate[0]?.toDate().setHours(0, 0, 0),
        to:
          isTimeFiltersTouched.to === true &&
          !isNaN(moment(filterByHours.to).unix() * 1000)
            ? moment(filterByHours.to).unix() * 1000
            : tripDate[1]?.toDate().setHours(23, 59, 59)
      })
    );
  }, [
    checkPermission,
    dispatch,
    isActiveContracts,
    page,
    userPhone,
    scooterName,
    tripCity,
    tripDate,
    filterByHours,
    isTimeFiltersTouched
  ]);

  useEffect(() => {
    if (!checkPermission('contracts')) {
      return;
    }

    getActiveContracts();
  }, [checkPermission, getActiveContracts]);

  const zonesState = useSelector(selectZones);
  const scooters = useSelector(selectScooters);
  const { activeContracts } = useSelector(selectContracts);

  const sortedScooters = useMemo(() => {
    let scootersList = [...(scooters?.items ?? [])];
    return (
      scootersList.sort((a, b) => parseInt(a.name) - parseInt(b.name)) ?? []
    );
  }, [scooters?.items]);

  const [mapCenter, setMapCenter] = useState(defaultCenter);

  useEffect(() => {
    let nextMapCenter;

    if (auth.info?.type === 'superAdmin') {
      const city = cities.items.find(item => item._id === admins.currentCity);
      if (city?.lat && city.lon) {
        nextMapCenter = {
          lat: city.lat,
          lng: city.lon
        };
      }
    } else if (admins.info && admins.currentCity) {
      const city = admins.info.cities.find(
        item => item._id === admins.currentCity
      );
      if (city?.lat && city.lon) {
        nextMapCenter = {
          lat: city.lat,
          lng: city.lon
        };
      }
    }

    if (!nextMapCenter) {
      setMapCenter(defaultCenter);
    } else {
      setMapCenter(nextMapCenter);
    }
  }, [admins.currentCity, admins.info, auth.info, cities.items]);

  const [selectedZone, setSelectedZone] = useState<{
    position: google.maps.LatLng | null;
    zone: Zone | null;
  }>({ position: null, zone: null });
  const toggleInfoWindow = (
    zone: Zone | null,
    e: google.maps.MouseEvent | null
  ) => {
    setSelectedZone({ position: e ? e.latLng : null, zone });
  };

  const [contractsHistoryDialogState, setContractsHistoryDialogState] =
    useState<{ open: boolean; scooterId?: string; userId?: string }>({
      open: false
    });

  const toggleScooterViewed = useCallback(
    (scooter: Scooter) => () => {
      dispatch(
        updateScooterInfoAsync(scooter._id, { viewed: !scooter.viewed })
      );
    },
    [dispatch]
  );

  const exitContract = useCallback(
    (contrId: string) => () => {
      if (window.confirm('Завершити поїздку?')) {
        dispatch(exitContractAsync(contrId, getActiveContracts));
      }
    },
    [dispatch, getActiveContracts]
  );

  const paginationCount = useMemo(
    () => Math.ceil(activeContracts.count / LIMIT),
    [activeContracts.count]
  );

  const signalScooter = useCallback(
    (scooter: Scooter) => async () => {
      await dispatch(signalScooterAsync(scooter._id, { value: true }));
    },
    [dispatch]
  );

  const citiesOptions = useMemo<SelectOption[]>(
    () => [
      {
        label: 'Усі',
        value: ''
      },
      ...cities.items.map(city => ({
        label: city.cityName,
        value: city._id
      }))
    ],
    [cities.items]
  );

  const onSubmitContractsFilters = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const { userPhone, scooterName } = Object.fromEntries(
        new FormData(event.currentTarget).entries()
      );

      setUserPhone(userPhone as string);
      setScooterName(scooterName as string);
    },
    []
  );

  // const getDotColor = useCallback(
  //   (scooter: Scooter) => {
  //     if (scooter.status === ScooterStatus.HIDDEN) {
  //       return theme.palette.grey[700];
  //     }

  //     if (!scooter.connected) {
  //       return theme.palette.violet.main;
  //     }

  //     if (!scooter.free) {
  //       return theme.palette.red.main;
  //     }

  //     if (!scooter.viewed) {
  //       return theme.palette.black.main;
  //     }

  //     if (scooter.free && !scooter.lock) {
  //       return theme.palette.yellow.main;
  //     }

  //     if (scooter.free && scooter.connected && scooter.lock) {
  //       return theme.palette.green.main;
  //     }

  //     return theme.palette.violet.main;
  //   },
  //   [theme]
  // );

  const isSuperAdmin = useMemo(() => {
    return auth.info?.type === 'superAdmin';
  }, [auth.info?.type]);

  // const handleBatteryLevel = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setBatteryLevel(event.target.value);
  // };

  const handleScooterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScooterNumber(event.target.value);
  };

  const handleLowBatteryLevel = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLowBatteryLevel(event.target.value);
  };

  const handleTripDate = useCallback(
    (nextValue: DateRange<Moment>) => {
      setTripDate(nextValue);
    },
    [setTripDate]
  );

  const handleTripDetailsClick = (
    event: any,
    createdAt?: string,
    closedAt?: string,
    cost?: string | number,
    status?: string,
    cableImgCoords?: {
      lat?: string;
      lon?: string;
    },
    tripHistoryDetails?: TripHistoryDetails
  ) => {
    event.stopPropagation();
    setTripTimeDialogState({
      open: true,
      createdAt,
      closedAt,
      cost,
      status,
      cableImgCoords,
      tripHistoryDetails
    });
  };

  const handleTripCoordinatesClick = (event: any, tripId?: string) => {
    event.stopPropagation();
    setTripCoordinatesDialogState({
      open: true,
      tripId
    });
  };

  console.log('-=- scooters', scooters);

  return (
    <Root>
      <div className={classes.header}>
        <Title>Управління</Title>
      </div>
      {checkPermission('map') && (
        <Paper className={classes.blockContainer}>
          <Collapse
            classes={{
              labelRoot: classes.collapseLabel
            }}
            label='Мапа'
          >
            <div className={classes.counterWrap}>
              <ScooterCounters
                mapFilters={mapFilters}
                onFilterSet={setMapFilters}
              />
              <TextField
                {...searchScooter}
                label='Назва скутера'
                variant='standard'
              />
              {/* <Select
                style={{ width: 200, marginLeft: 20 }}
                label='Місто'
                options={citiesOptions}
                onChange={value => setScootersCity(value)}
                value={scootersCity}
              /> */}
              {/* <MapLegend /> */}
            </div>
            <GoogleMap
              center={mapCenter}
              mapContainerStyle={{
                width: '100%',
                height: 500,
                marginTop: 20
              }}
              options={{
                disableDefaultUI: true,
                zoomControl: true,
                fullscreenControl: true
              }}
              zoom={11}
            >
              <UserMapMarker />
              {zonesState.zones.map(zone => (
                <Polygon
                  key={zone._id}
                  options={zonesColors[zone.type] || zonesColors.green}
                  paths={zone.coordinates.map(coordinate => ({
                    lat: coordinate[0],
                    lng: coordinate[1]
                  }))}
                  onClick={e => toggleInfoWindow(zone, e)}
                />
              ))}
              {selectedZone.zone && selectedZone.position && (
                <InfoWindow
                  position={selectedZone.position}
                  onCloseClick={() => toggleInfoWindow(null, null)}
                >
                  <>
                    Назва: {selectedZone.zone.name}
                    <br />
                    Тип: {selectedZone.zone.type}
                  </>
                </InfoWindow>
              )}
              {scooters.items.map((scooter, index) => {
                const isBusMarker =
                  scooter.name.includes('AVTO') ||
                  scooter.name === 'BUS-IF-S' ||
                  scooter.name === 'BUS_KOL' ||
                  scooter.name === 'BUS-IF-T';

                if (scooter.status === ScooterStatus.HIDDEN) {
                  return null;
                }

                return (
                  scooter.coords && (
                    <MapMarker
                      forceInfoWindowOpen={scooter.name === searchScooter.value}
                      icon={{
                        url: getScooterIcon(scooter, scooter.name)!,
                        labelOrigin: new window.google.maps.Point(16, 15)
                      }}
                      key={scooter._id}
                      position={{
                        lat: scooter.coords.lat,
                        lng: scooter.coords.lon
                      }}
                      batteryLevel={
                        isBusMarker ? ' ' : scooter?.battery ?? 'null'
                      }
                      {...(!scooter.connected
                        ? { zIndex: 5000 + index }
                        : { zIndex: isBusMarker ? 9000 + index : index })}
                    >
                      <div>
                        Cкутер: {scooter.name}
                        <br />
                        {scooter?.battery ? (
                          <>Батарея: {scooter?.battery}</>
                        ) : null}
                        <br />
                        {scooter?.status ? (
                          <>Статус: {scooter?.status}</>
                        ) : null}
                        <div
                          className={classes.routeLink}
                          onClick={() => {
                            navigator.geolocation.getCurrentPosition(pos =>
                              window.open(
                                `https://www.google.com/maps/dir/${pos.coords.latitude},${pos.coords.longitude}/${scooter.coords.lat},${scooter.coords.lon}`,
                                '_blank'
                              )
                            );
                          }}
                        >
                          Маршрут
                        </div>
                      </div>
                    </MapMarker>
                  )
                );
              })}
            </GoogleMap>
          </Collapse>
        </Paper>
      )}
      {checkPermission('zones') && currentCityId ? (
        <ZonesConfigSection
          isSuperAdmin={isSuperAdmin}
          currentCityId={currentCityId}
        />
      ) : null}
      {checkPermission('zones') && (
        <Paper className={classes.blockContainer}>
          <Collapse
            classes={{
              labelRoot: classes.collapseLabel
            }}
            label='Зони'
          >
            {/* {isSuperAdmin ? ( */}
            <Button
              className={classes.saveButton}
              color='primary'
              variant='contained'
              onClick={() => setZoneModalOpen(true)}
            >
              Створити
            </Button>
            {/* ) : null} */}
            <TableContainer style={{ maxHeight: 440 }}>
              <Table stickyHeader size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>Назва</TableCell>
                    <TableCell>Тип</TableCell>
                    <TableCell>Оновлений</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {zonesState.zones.map(zone => (
                    <TableRow key={zone._id}>
                      <TableCell>{zone.name}</TableCell>
                      <TableCell>{zone.type}</TableCell>
                      <TableCell>
                        {formatDate(zone.updatedAt, { time: true })}
                      </TableCell>
                      <TableCell>
                        {/* {isSuperAdmin ? ( */}
                        <>
                          <IconButton
                            onClick={() => {
                              dispatch(
                                setZoneState({
                                  coordinates: zone.coordinates,
                                  name: zone.name,
                                  type: zone.type,
                                  cityId: zone.city._id,
                                  _id: zone._id,
                                  prohibitStop: zone.prohibitStop ?? false
                                })
                              );
                              setZoneModalOpen(true);
                            }}
                            size='large'
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            onClick={async () => {
                              if (window.confirm('Ви впевнені?')) {
                                await dispatch(deleteZoneAsync(zone._id));
                                await dispatch(getZonesAsync(true));
                              }
                            }}
                            size='large'
                          >
                            <Delete />
                          </IconButton>
                        </>
                        {/* ) : null} */}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </Paper>
      )}
      {checkPermission('scooters') && (
        <Paper className={classes.blockContainer}>
          <Collapse
            classes={{
              labelRoot: classes.collapseLabel
            }}
            label='Cкутери'
          >
            <div className={classes.counterWrap}>
              <Grid container alignItems='end' pb={2}>
                {/* <Grid item sx={{ ml: 2 }}>
                  <TextField
                    label='Фільтр по заряду'
                    value={batteryLevel}
                    onChange={handleBatteryLevel}
                  />
                </Grid> */}
                {isSuperAdmin ? (
                  <Grid item>
                    <Select
                      style={{ width: 200 }}
                      label='Фільтр по місту'
                      options={citiesOptions}
                      onChange={value => setScootersCity(value)}
                      value={scootersCity}
                    />
                  </Grid>
                ) : null}
                <Grid
                  item
                  sx={{ ml: isSuperAdmin ? 2 : 0 }}
                  position={'relative'}
                  display={'flex'}
                  flexDirection={'column'}
                >
                  <TextField
                    label='Фільтр по номеру'
                    value={scooterNumber}
                    onChange={handleScooterName}
                    onKeyPress={e => {
                      console.log(e.code);
                      if (e.code === 'Enter') {
                        setFilteredByScooterName(scooterNumber);
                      }
                    }}
                  />
                  <span
                    style={{ position: 'absolute', fontSize: 11, top: '100%' }}
                  >
                    Для пошуку нажміть Enter
                  </span>
                </Grid>
                <Grid item sx={{ ml: 'auto' }}>
                  <TextField
                    label='Значення низької🔋'
                    value={lowBatteryLevel}
                    onChange={handleLowBatteryLevel}
                  />
                </Grid>
                <Grid item sx={{ ml: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isLowBatteryChecked}
                        // disabled={Boolean(batteryLevel)}
                        color='primary'
                        onChange={(event, checked) =>
                          setIsLowBatteryChecked(checked)
                        }
                      />
                    }
                    label='Низький заряд батарей'
                  />
                </Grid>
              </Grid>
            </div>

            <TableContainer style={{ maxHeight: 440, marginTop: 20 }}>
              <Table stickyHeader size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>Назва</TableCell>
                    <TableCell>Нотатки</TableCell>
                    <TableCell align='center'>Показувати на мапі</TableCell>
                    <TableCell>Заряд</TableCell>
                    <TableCell>Рейтинг</TableCell>
                    <TableCell>Змінна батарея</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedScooters.map(scooter => (
                    <TableRow key={scooter._id}>
                      <TableCell style={{ width: '100%' }}>
                        <Dot
                          color={getDotColor(scooter)}
                          size={8}
                          type={
                            scooter.status === ScooterStatus.HIDDEN
                              ? 'empty'
                              : 'filled'
                          }
                          border={scooter.status === ScooterStatus.HIDDEN}
                          style={{ marginRight: 12 }}
                          tooltip={getDotTooltip(scooter)}
                        />
                        {scooter.name}
                      </TableCell>
                      <TableCell align='center'>
                        <div className={classes.scooterIcons}>
                          <IconButton
                            onClick={() =>
                              setScooterNotesModal({ open: true, scooter })
                            }
                            size='large'
                          >
                            <NoteAdd />
                          </IconButton>
                        </div>
                      </TableCell>
                      <TableCell align='center'>
                        <Checkbox
                          checked={scooter.viewed}
                          color='primary'
                          onChange={toggleScooterViewed(scooter)}
                        />
                      </TableCell>
                      <TableCell align='center'>{scooter.battery}</TableCell>
                      <TableCell align='center'>{scooter?.avgRate}</TableCell>
                      <TableCell align='center'>
                        {scooter?.isReplaceableBattery ? 'Так' : ' Ні'}
                      </TableCell>
                      <TableCell align='center'>
                        <div className={classes.scooterIcons}>
                          <IconButton
                            onClick={signalScooter(scooter)}
                            size='large'
                          >
                            <VolumeUp />
                          </IconButton>

                          <IconButton
                            onClick={() =>
                              dispatch(
                                lockScooterAsync(scooter._id, { lock: true })
                              )
                            }
                            size='large'
                          >
                            <Block
                              className={clsx(scooter.lock && classes.lock)}
                              color='inherit'
                            />
                          </IconButton>

                          <IconButton
                            onClick={() =>
                              dispatch(
                                lockScooterAsync(scooter._id, { lock: false })
                              )
                            }
                            size='large'
                          >
                            <LockOpen
                              className={clsx(!scooter.lock && classes.lock)}
                              color='inherit'
                            />
                          </IconButton>

                          <IconButton
                            onClick={() =>
                              setContractsHistoryDialogState({
                                open: true,
                                scooterId: scooter._id
                              })
                            }
                            size='large'
                          >
                            <History />
                          </IconButton>

                          <IconButton
                            onClick={() =>
                              setScootersModalState({
                                open: true,
                                scooter
                              })
                            }
                            size='large'
                          >
                            <Edit color='inherit' />
                          </IconButton>

                          {isSuperAdmin && (
                            <>
                              <IconButton
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      'Ви впевнені що хочете фідформатувати CD скутера?'
                                    )
                                  ) {
                                    dispatch(formatCDScooterAsync(scooter._id));
                                  }
                                }}
                                size='large'
                              >
                                <RestartAlt />
                              </IconButton>
                              <IconButton
                                onClick={async () => {
                                  if (window.confirm('Ви впевнені?')) {
                                    await dispatch(
                                      deleteScooterAsync(scooter._id)
                                    );
                                    dispatch(
                                      getScootersAsync({ cityId: scootersCity })
                                    );
                                  }
                                }}
                                size='large'
                              >
                                <Delete />
                              </IconButton>
                            </>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </Paper>
      )}
      {checkPermission('contracts') && (
        <Paper className={classes.blockContainer}>
          <Collapse
            classes={{
              labelRoot: classes.collapseLabel
            }}
            label='Поїздки'
          >
            <Grid container spacing={2} direction='column'>
              <Grid item className={classes.filters}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isActiveContracts}
                      color='primary'
                      onChange={onChangeIsActiveContracts}
                    />
                  }
                  label='Показати лише активні поїздки'
                />
              </Grid>
              <Grid
                item
                container
                spacing={2}
                className={classes.filters}
                alignItems='flex-end'
                component='form'
                onSubmit={onSubmitContractsFilters}
              >
                <Grid item>
                  <TextField label='Телефон' name='userPhone' />
                </Grid>
                <Grid item>
                  <TextField label='Самокат' name='scooterName' />
                </Grid>
                <Grid item>
                  <Button color='primary' variant='contained' type='submit'>
                    Застосувати
                  </Button>
                </Grid>
                <Grid item sx={{ ml: 'auto' }}>
                  {tripDate[0]?.toISOString() ===
                    tripDate[1]?.toISOString() && (
                    <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                      <Box sx={{ maxWidth: '182px' }}>
                        <TimePicker
                          renderInput={props => <TextField {...props} />}
                          ampm={false}
                          label='Час з'
                          value={filterByHours?.from}
                          onChange={handleFilterByHoursFrom}
                        />
                      </Box>
                      &nbsp;&nbsp;&nbsp;{'-'}&nbsp;&nbsp;&nbsp;
                      <Box sx={{ maxWidth: '182px' }}>
                        <TimePicker
                          renderInput={props => <TextField {...props} />}
                          ampm={false}
                          label='Час до'
                          value={filterByHours?.to}
                          onChange={handleFilterByHoursTo}
                        />
                      </Box>
                    </Box>
                  )}
                  <DateRangePicker
                    startText='Від'
                    endText='До'
                    value={tripDate}
                    onChange={handleTripDate}
                  />
                </Grid>
                {isSuperAdmin ? (
                  <Grid item>
                    <Select
                      style={{ width: 200 }}
                      label='Місто'
                      options={citiesOptions}
                      onChange={value => setTripCity(value)}
                      value={tripCity}
                    />
                  </Grid>
                ) : null}
                {/* <Grid item>
                  <Stack direction='row' alignItems='center' spacing={1}>
                    <Typography>Кількість поїздок за день:</Typography>
                    <Typography
                      color={theme.palette.info.main}
                      fontWeight={700}
                    >
                      {activeContracts.count}
                    </Typography>
                  </Stack>
                </Grid> */}
              </Grid>

              {Boolean(activeContracts.items.length) && (
                <Grid item>
                  <TableContainer style={{ maxHeight: 440, marginBottom: 20 }}>
                    <Table stickyHeader size='small'>
                      <TableHead>
                        <TableRow>
                          <TableCell>Договір</TableCell>
                          <TableCell>Самокат</TableCell>
                          <TableCell>Оцінка</TableCell>
                          <TableCell>Користувач</TableCell>
                          <TableCell>Зображення</TableCell>
                          <TableCell>Пробіг</TableCell>
                          {checkPermission('history') && (
                            <TableCell>Деталі поїздки</TableCell>
                          )}
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.tableBody}>
                        {activeContracts.items.map(contract => {
                          console.log(
                            '-=-= activeContract',
                            contract?.cableImgCoords?.lat
                          );
                          return (
                            <TableRow key={contract._id}>
                              <TableCell>
                                <Grid container spacing={3}>
                                  <Grid item>
                                    <div>
                                      Дата створення:{' '}
                                      {formatDate(contract.createdAt, {
                                        time: true
                                      })}
                                    </div>
                                    <div>Статус: {contract.status?.value}</div>
                                  </Grid>
                                  <Grid item>
                                    Активний:
                                    <Checkbox
                                      disableRipple
                                      readOnly
                                      checked={contract.active}
                                      color='primary'
                                    />
                                  </Grid>
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <div>Номер: {contract.scooter?.name}</div>
                                <div>Батарея: {contract.scooter?.battery}</div>
                              </TableCell>
                              <TableCell align='center'>
                                <div>
                                  {contract.contractReview?.rate ?? 'Немає'}
                                </div>
                              </TableCell>
                              <TableCell>
                                {contract.user?.firstName && (
                                  <div>
                                    Ім'я:{' '}
                                    {contract.user?.type === 'vip' ? (
                                      <>
                                        <SvgIcon
                                          component={CrownIcon}
                                          viewBox='0 0 1280 815'
                                          sx={{
                                            width: 18,
                                            height: 18,
                                            mr: 0.5,
                                            mb: -0.35
                                          }}
                                        />
                                        <span>{contract.user.firstName}</span>
                                      </>
                                    ) : (
                                      contract.user.firstName
                                    )}
                                  </div>
                                )}
                                <div>Баланс: {contract.user?.balance}</div>
                                <div>Телефон: {contract.user?.phone}</div>
                              </TableCell>
                              <TableCell>
                                <div className={classes.contractImages}>
                                  {contract.cableImg ? (
                                    <a
                                      className={classes.contractImage}
                                      href={contract.cableImg}
                                      rel='noopener noreferrer'
                                      target='_blank'
                                    >
                                      <img
                                        alt='cableImg'
                                        src={contract.cableImg}
                                      />
                                    </a>
                                  ) : (
                                    'Самокат ще в поїздці'
                                  )}
                                </div>
                              </TableCell>
                              <TableCell>{contract.mileage}</TableCell>
                              {checkPermission('history') && (
                                <TableCell>
                                  <IconButton
                                    size='small'
                                    onClick={event => {
                                      handleTripCoordinatesClick(
                                        event,
                                        contract._id
                                      );
                                    }}
                                  >
                                    <RouteOutlined />
                                  </IconButton>
                                  <IconButton
                                    size='small'
                                    onClick={event => {
                                      handleTripDetailsClick(
                                        event,
                                        contract.createdAt,
                                        contract.closedDate,
                                        contract.sum,
                                        contract?.status?.value,
                                        contract?.cableImgCoords,
                                        contract?.historyDetails
                                      );
                                    }}
                                  >
                                    <History />
                                  </IconButton>
                                  <IconButton
                                    size='small'
                                    onClick={(e: any) => {
                                      e.stopPropagation();
                                      setContractsHistoryDialogState({
                                        open: true,
                                        userId: contract.user._id
                                      });
                                    }}
                                  >
                                    <Timeline />
                                  </IconButton>
                                </TableCell>
                              )}
                              <TableCell>
                                {contract.active && (
                                  <Button
                                    color='primary'
                                    variant='contained'
                                    onClick={exitContract(contract._id)}
                                  >
                                    Завершити
                                  </Button>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}
              <Grid item>
                <Pagination count={paginationCount} onChange={onChangePage} />
              </Grid>
            </Grid>
          </Collapse>
        </Paper>
      )}
      <MapMarkersSection mapCenter={mapCenter} currentCityId={currentCityId} />
      <RentHeatMapSection mapCenter={mapCenter} currentCityId={currentCityId} />
      <ScootersModal
        {...scootersModalState}
        isSuperAdmin={isSuperAdmin}
        onClose={() =>
          setScootersModalState({
            open: false,
            scooter: undefined
          })
        }
      />
      <ZoneModal
        open={zoneModalOpen}
        onClose={() => setZoneModalOpen(false)}
        mapCenter={mapCenter}
        zones={zonesState.zones}
      />
      <ContractsHistoryModal
        open={contractsHistoryDialogState.open}
        onClose={() => setContractsHistoryDialogState({ open: false })}
        userId={contractsHistoryDialogState.userId}
      />
      <TripTimeModal
        open={tripTimeDialogState.open}
        onClose={() => setTripTimeDialogState({ open: false })}
        createdAt={tripTimeDialogState.createdAt}
        closedAt={tripTimeDialogState.closedAt}
        cost={tripTimeDialogState.cost}
        status={tripTimeDialogState?.status}
        cableImgCoords={tripTimeDialogState?.cableImgCoords}
        tripHistoryDetails={tripTimeDialogState?.tripHistoryDetails}
      />
      <TripCoordinatesModal
        open={tripCoordinatesDialogState.open}
        onClose={() => setTripCoordinatesDialogState({ open: false })}
        tripCity={tripCity}
        tripId={tripCoordinatesDialogState.tripId}
      />
      <ScooterNotesModal
        open={scooterNotesModal.open}
        onClose={() => setScooterNotesModal({ open: false })}
        isSuperAdmin={isSuperAdmin}
        scooter={scooterNotesModal?.scooter}
      />
    </Root>
  );
};

export default ZonesPage;
