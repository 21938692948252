import { createTheme, Theme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    red: Palette['primary'];
    yellow: Palette['primary'];
    green: Palette['primary'];
    black: Palette['primary'];
    violet: Palette['primary'];
  }
  interface PaletteOptions {
    red: PaletteOptions['primary'];
    yellow: PaletteOptions['primary'];
    green: PaletteOptions['primary'];
    black: PaletteOptions['primary'];
    violet: PaletteOptions['primary'];
  }
}

export const theme: Theme = createTheme({
  palette: {
    primary: {
      main: '#2FCB75',
      contrastText: '#ffffff'
    },
    red: {
      main: '#E20000'
    },
    yellow: {
      main: '#FFC500'
    },
    violet: {
      main: '#F600FF'
    },
    black: {
      main: '#000000'
    },
    green: {
      main: '#26C400'
    }
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard'
      }
    }
  }
});
