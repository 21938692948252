export const convertSecondsToHHMMSS = (seconds: number): string => {
  let hours: number = Math.floor(seconds / 3600);
  let minutes: number = Math.floor((seconds % 3600) / 60);
  let remainingSeconds: number = seconds % 60;
  const formatWithLeadingZero = (num: number): string =>
    num.toString().padStart(2, '0');
  return `${formatWithLeadingZero(hours)}:${formatWithLeadingZero(
    minutes
  )}:${formatWithLeadingZero(remainingSeconds)}`;
};
