import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button
} from '@mui/material';

import { Edit } from '@mui/icons-material';

import Collapse from './../../Collapse';
import TableCell from '../../TableCell';
import { classes } from '../../../pages/ZonesPage/constants';
import {
  getCityZonesConfigAsync,
  selectZones
} from '../../../redux/slices/zonesSlice';
import ZoneConfigModal from '../../../modals/ZoneConfigModal';
import {
  ZoneConfigResponceRow,
  ZoneType
} from '../../../resources/types/zonesTypes';
import { RootState } from '../../../redux/store';

interface IZonesConfigSectionProps {
  currentCityId: string;
  isSuperAdmin: boolean;
}

const ZonesConfigSection = ({
  currentCityId,
  isSuperAdmin
}: IZonesConfigSectionProps) => {
  const dispatch = useDispatch();
  const { zonesConfig } = useSelector(selectZones);

  const [zoneConfigEdit, setZoneConfigEdit] =
    useState<ZoneConfigResponceRow | null>(null);

  useEffect(() => {
    dispatch(getCityZonesConfigAsync(currentCityId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCityId]);

  const addedForZones = useMemo<ZoneType[]>(() => {
    const added: ZoneType[] = [];
    for (const conf of zonesConfig) {
      if (conf?.type) {
        added.push(conf?.type);
      }
    }

    return added;
  }, [zonesConfig]);

  const types = useSelector(
    (state: RootState) => state.zones.types
  ) as ZoneType[];

  const typesOptions: number = types
    .map((type: ZoneType) => ({
      value: type,
      label: type,
      hidden: addedForZones.includes(type)
    }))
    .filter(t => !t.hidden).length;

  return (
    <>
      <Paper className={classes.blockContainer}>
        <Collapse
          classes={{
            labelRoot: classes.collapseLabel
          }}
          label='Конфігурація Зон'
        >
          {typesOptions > 0 ? (
            <Button
              className={classes.saveButton}
              color='primary'
              variant='contained'
              onClick={() => setZoneConfigEdit({})}
            >
              Додати
            </Button>
          ) : null}
          <TableContainer style={{ maxHeight: 440 }}>
            <Table stickyHeader size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Тип Зони</TableCell>
                  <TableCell>Опис</TableCell>
                  <TableCell>Вартість зупинки</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {zonesConfig.map(config => (
                  <TableRow key={config._id}>
                    <TableCell>{config.type}</TableCell>
                    <TableCell>{config?.description?.ua ?? ''}</TableCell>
                    <TableCell>{config.amountForStop}</TableCell>
                    <TableCell>
                      {isSuperAdmin ? (
                        <>
                          <IconButton
                            onClick={() => {
                              console.log(config);
                              setZoneConfigEdit(config);
                            }}
                            size='large'
                          >
                            <Edit />
                          </IconButton>
                        </>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Collapse>
      </Paper>
      <ZoneConfigModal
        open={Boolean(zoneConfigEdit)}
        currentCityId={currentCityId}
        onClose={() => setZoneConfigEdit(null)}
        config={zoneConfigEdit}
        addedForZones={addedForZones}
      />
    </>
  );
};

export default ZonesConfigSection;
