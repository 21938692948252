import { Languages } from './index';

export type BonusGameLocalization = {
  [key in Languages]?: { description: string, title: string };
};

export enum BonusGameBonusTypes {
  money = 'money',
  promo = 'promo'
}

export interface NthUserBonus {
  nth: number;
  money: number;
}

export interface BonusGame extends BonusGameLocalization {
  totalSeconds: number;
  type: BonusGameBonusTypes;
  _id: string;
  moneyBonus: number;
  bonusCode: string;
  icon: string;
  level: number;
  city: string;
  nthUserBonuses?: NthUserBonus[];
}
