import { useCallback, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Add, Delete, Edit } from '@mui/icons-material';
import Title from '../../components/Title';
import TabsDialog from './TabsDialog';
import {
  deleteTabAsync,
  getTabsAsync,
  selectTabs
} from '../../redux/slices/tabsSlice';

const PREFIX = 'TabsPage';

const classes = {
  header: `${PREFIX}-header`
};

const Root = styled('div')({
  [`& .${classes.header}`]: {
    marginBottom: 20
  }
});

const TabsPage = () => {
  const tabs = useSelector(selectTabs);

  const dispatch = useDispatch();

  const [dialogState, setDialogState] = useState<{
    open: boolean;
    tabId?: string;
  }>({ open: false });

  const openDialog = useCallback(
    (tabId?: string) => () => setDialogState({ open: true, tabId }),
    []
  );

  const closeDialog = useCallback(() => setDialogState({ open: false }), []);

  const deleteTab = useCallback(
    (tabId: string) => async () => {
      await dispatch(deleteTabAsync(tabId));
      dispatch(getTabsAsync());
    },
    [dispatch]
  );

  return (
    <Root>
      <Grid
        container
        alignItems='center'
        className={classes.header}
        spacing={4}
      >
        <Grid item>
          <Title>Таби</Title>
        </Grid>
        <Grid item>
          <Button
            color='primary'
            startIcon={<Add />}
            variant='outlined'
            onClick={openDialog()}
          >
            Додати
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Назва</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {tabs.items.map(tab => (
              <TableRow key={tab._id}>
                <TableCell>{tab.name}</TableCell>
                <TableCell align='right'>
                  <IconButton size='small' onClick={openDialog(tab._id)}>
                    <Edit fontSize='small' />
                  </IconButton>
                  <IconButton size='small' onClick={deleteTab(tab._id)}>
                    <Delete fontSize='small' />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TabsDialog {...dialogState} onClose={closeDialog} />
    </Root>
  );
};

export default TabsPage;
