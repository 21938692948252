import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from '@mui/material';
import { Props } from './interface';
import { useDispatch, useSelector } from 'react-redux';
import { banUser } from '../../redux/slices/usersSlice';
import { useState } from 'react';
import { RootState } from '../../redux/store';

const UserBanModal = (props: Props) => {
  const { onClose, user, ...restProps } = props;
  const dispatch = useDispatch();

  const isSuperAdmin = useSelector(
    (state: RootState) => state.auth.info?.type === 'superAdmin'
  );

  const [banMessage, setBanMessage] = useState('');
  const [banMinmutes, setBanMinmutes] = useState(0);

  // useEffect(() => {
  //   if (props.open) {
  //     getUserSessions();
  //   } else {
  //     setPage(1);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.open, page, user?._id]);

  const onBanUser = () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmation = confirm(
      'Ви впевнені що хочете заблокувати користувача?'
    );
    if (confirmation && user._id) {
      dispatch(banUser(user._id, { text: banMessage, minutes: banMinmutes }));
      alert('Користувача успішно заблоковано');
    }
  };

  return (
    <Dialog {...restProps} maxWidth='xs' fullWidth>
      <DialogTitle>
        Заблокувати користувача: {user?.firstName} {user?.lastName}{' '}
        {user?.phone}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} direction='column'>
          <Grid item xs={10}>
            <TextField
              name='ban-message'
              label='Причина блокування'
              fullWidth
              value={banMessage}
              onChange={e => {
                setBanMessage(e.target.value ?? '');
              }}
            />
          </Grid>
          <Grid item xs={10}>
            <TextField
              name='ban-minutes'
              label='Хвилин блокування'
              type='number'
              fullWidth
              value={banMinmutes}
              onChange={e => {
                const minutes = Number(e.target.value ?? 0);
                setBanMinmutes(minutes < 0 ? 0 : minutes);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {/* <Grid item>
          {data.length ? (
            <Pagination
              count={pages}
              page={page}
              onChange={(event, nextPage) => setPage(nextPage)}
            />
          ) : null}
        </Grid> */}
        {isSuperAdmin ? (
          <Grid
            item
            marginRight={'auto'}
            flexDirection={'row'}
            display={'flex'}
          >
            <Grid marginX={'5px'}>
              <Button
                color='warning'
                variant='contained'
                disabled={banMessage.length < 3 || banMinmutes < 1}
                onClick={() => onBanUser()}
              >
                Заблокувати користувача
              </Button>
            </Grid>
          </Grid>
        ) : null}
        <Grid marginX={'5px'}>
          <Button color='primary' variant='contained' onClick={() => onClose()}>
            Вийти
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default UserBanModal;
