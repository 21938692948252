import React, { useCallback, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Button,
  Input,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import {
  getSettingsAsync,
  selectSettings,
  changeWorkTimeSettings,
  updateSettingsAsync
} from '../../redux/slices/settingsSlice';
import Title from '../../components/Title';
import TableCell from '../../components/TableCell';
import { DayIndex } from '../../resources/types/settingsTypes';
import { InputChangeEvent } from '../../resources/utils';

const PREFIX = 'SettingsPage';

const classes = {
  title: `${PREFIX}-title`,
  subTitle: `${PREFIX}-subTitle`,
  block: `${PREFIX}-block`
};

const Root = styled('div')({
  [`& .${classes.title}`]: {
    marginBottom: 20
  },
  [`& .${classes.subTitle}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10
  },
  [`& .${classes.block}`]: {
    '& + &': {
      marginTop: 20
    }
  }
});

const days = ['НД', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];

const SettingsPage = () => {
  const dispatch = useDispatch();

  const { workTime } = useSelector(selectSettings);

  const getSettings = useCallback(() => {
    dispatch(getSettingsAsync());
  }, [dispatch]);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  const changeTime = useCallback(
    (index: number, prop: 'from' | 'to') => (e: InputChangeEvent) => {
      dispatch(
        changeWorkTimeSettings({
          dayKey: String(index),
          prop,
          value: e.target.value
        })
      );
    },
    [dispatch]
  );

  const updateTimeSettings = useCallback(() => {
    if (!workTime) {
      return dispatch(updateSettingsAsync());
    }

    let isValid = true;
  
    for (let index = 0; index < days.length; index++) {
      const from = workTime[String(index) as DayIndex].from;
      const to = workTime[String(index) as DayIndex].to;
  
      if (to < from) {
        isValid = false;
        break;
      }
    }
  
    if (isValid) {
      dispatch(updateSettingsAsync());
    } else {
      alert('Введені робочі години не правильні!');
    }
  }, [dispatch, workTime]);
  
  return (
    <Root>
      <Title className={classes.title}>Налаштування</Title>
      {workTime && (
        <>
          <Alert severity="warning">Зверніть увагу що не може бути кінець дня о 00:00 а лише о 23:59 якщо початок дня не має значення 00:00. Значення 00:00 для початку та кінця використовуються лише для відключення перевірки робочого часу протягом дня!</Alert>
          <div className={classes.block}>
            <div className={classes.subTitle}>
              <Title variant='h5'>Робочі години</Title>
              <Button color='primary' onClick={updateTimeSettings}>
                Зберегти
              </Button>
            </div>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>День</TableCell>
                    <TableCell>Початок</TableCell>
                    <TableCell>Кінець</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {days.map((day, index) => (
                    <TableRow key={day}>
                      <TableCell>{day}</TableCell>
                      <TableCell>
                        <Input
                          type='time'
                          value={workTime[String(index) as DayIndex].from}
                          onChange={changeTime(index, 'from')}
                        />
                      </TableCell>
                      <TableCell>
                        <Input
                          type='time'
                          value={workTime[String(index) as DayIndex].to}
                          onChange={changeTime(index, 'to')}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      )}
    </Root>
  );
};

export default SettingsPage;
