import React from 'react';
import { Props } from './interface';
import { TextField } from '@mui/material';

const Input = (props: Props) => {
  const { ...restProps } = props;

  return (
    <TextField {...restProps} />
  );
};

export default Input;
