import { Stack, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

const TableLagend = () => {
  return (
    <Stack direction='row' alignItems='center' spacing={3} mt={2}>
      <Stack direction='row' alignItems='center' spacing={1}>
        <CircleIcon color='primary' fontSize='small' />
        <Typography>Будні дні</Typography>
      </Stack>
      <Stack direction='row' alignItems='center' spacing={1}>
        <CircleIcon color='secondary' fontSize='small' />
        <Typography>Вихідні дні</Typography>
      </Stack>
    </Stack>
  );
};

export default TableLagend;
