/* eslint-disable react-hooks/rules-of-hooks */
import { Edit, Delete } from '@mui/icons-material';
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import React, { useState } from 'react';
import TableCell from '../../components/TableCell';
import Title from '../../components/Title';
import BonusGameModal from '../../modals/BonusGameModal';
import {
  useDeleteBonusGameMutation,
  useGetBonusGamesQuery
} from '../../redux/api/bonusGameApi';
import bonusGameTypeName from '../../resources/bonusGameTypeName';
import { BonusGame } from '../../resources/types/bonusGameTypes';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const BonusGamePage = () => {
  const { data, refetch } = useGetBonusGamesQuery();
  const [deleteGameLevel] = useDeleteBonusGameMutation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editBonusGame, setEditBonusGame] = useState<BonusGame>();

  const isSuperAdmin = useSelector(
    (state: RootState) => state.auth.info?.type === 'superAdmin'
  );

  const deleteLevel = (_id: string) => {
    if (!_id) return;
    // eslint-disable-next-line no-restricted-globals
    const confirmation = confirm('Ви впевнені що хочете видалити цей рівень?');
    if (confirmation) {
      deleteGameLevel({ _id })
        .unwrap()
        .catch(error => alert(error.data.message))
        .finally(() => refetch());
    }
  };

  const edit = (bonusGame: BonusGame) => {
    setIsModalOpen(true);
    setEditBonusGame(bonusGame);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditBonusGame(undefined);
  };

  return (
    <Grid container direction='column' spacing={4}>
      <Grid
        container
        item
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
      >
        <Grid item>
          <Title>Бонусна гра</Title>
        </Grid>
        {isSuperAdmin ? (
          <Grid item>
            <Button variant='contained' onClick={() => setIsModalOpen(true)}>
              Створити
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <Grid item>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>№</TableCell>
                <TableCell>Рівень</TableCell>
                <TableCell>Тип бонусу</TableCell>
                <TableCell>Кількість витрачених секунд</TableCell>
                <TableCell>NTH Бонуси</TableCell>
                {/*<TableCell>Текст поздравления</TableCell>*/}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.length &&
                data?.map((bonusGame, index) => (
                  <TableRow key={bonusGame._id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{bonusGame.level}</TableCell>
                    <TableCell>{bonusGameTypeName[bonusGame.type]}</TableCell>
                    <TableCell>{bonusGame.totalSeconds}</TableCell>
                    <TableCell>
                      {bonusGame.nthUserBonuses?.map(
                        (bonus, index) =>
                          `${index + 1}. nth: ${bonus.nth} money: ${
                            bonus.money
                          } \n`
                      )}
                    </TableCell>
                    {/*<TableCell>*/}
                    {/*  {Object.keys(Languages).map(*/}
                    {/*    language =>*/}
                    {/*      bonusGame[language as Languages]?.description && (*/}
                    {/*        <>*/}
                    {/*          {language}:{' '}*/}
                    {/*          {bonusGame[language as Languages]!.description}*/}
                    {/*          <br />*/}
                    {/*        </>*/}
                    {/*      )*/}
                    {/*  )}*/}
                    {/*</TableCell>*/}
                    <TableCell>
                      {isSuperAdmin ? (
                        <>
                          <IconButton
                            size='small'
                            onClick={() => edit(bonusGame)}
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            size='small'
                            onClick={() => deleteLevel(bonusGame?._id)}
                          >
                            <Delete fontSize='small' />
                          </IconButton>
                        </>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <BonusGameModal
        editBonusGame={editBonusGame}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </Grid>
  );
};

export default BonusGamePage;
