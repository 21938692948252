import { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid
} from '@mui/material';
import UploadButton from '../../components/UploadButton';
import { uploadZoneTypeFile } from '../../resources/uploadFiles';
import { InputChangeEvent } from '../../resources/utils';
import { Props } from './interface';
import Title from '../../components/Title';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import Select from '../../components/Select';
import { SelectOption } from '../../components/Select/interface';
import {
  clearZoneState,
  createZoneAsync,
  getZonesAsync,
  setZoneState,
  updateZoneAsync
} from '../../redux/slices/zonesSlice';
// import { ZoneType } from '../../resources/types/zonesTypes';
import { selectAdmins } from '../../redux/slices/adminsSlice';

const ZoneModal = (props: Props) => {
  const { open, onClose } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!open) {
      dispatch(clearZoneState());
    }
  }, [dispatch, open]);

  const isSuperAdmin = useSelector(
    (state: RootState) => state.auth.info?.type === 'superAdmin'
  );
  // const types = useSelector((state: RootState) => state.zones.types);
  const cities = useSelector((state: RootState) => state.cities.items);
  const zoneState = useSelector((state: RootState) => state.zones.zone);
  const adminInfo = useSelector(selectAdmins);

  const currentCityId = useMemo(() => {
    const city = cities.find(item => item._id === adminInfo.currentCity);
    if (city) {
      return city._id;
    }
  }, [adminInfo.currentCity, cities]);

  // const typesOptions = useMemo<SelectOption[]>(
  //   () => types.map(type => ({ value: type, label: type })),
  //   [types]
  // );

  const citiesOptions = useMemo<SelectOption[]>(
    () =>
      cities.map(city => ({
        value: city._id,
        label: city.cityName
      })),
    [cities]
  );

  const [isUploadingFile, setIsUploadingFile] = useState(false);

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle>
        <Title>{zoneState._id ? 'Редагування зони' : 'Створення зони'}</Title>
      </DialogTitle>
      <DialogContent>
        <Grid container direction='column' spacing={2}>
          {/* <Grid item>
            <Select
              fullWidth
              options={typesOptions}
              onChange={value =>
                dispatch(setZoneState({ type: value as ZoneType }))
              }
              value={zoneState.type}
              label='Тип'
            />
          </Grid> */}
          {isSuperAdmin && (
            <Grid item>
              <Select
                fullWidth
                options={citiesOptions}
                onChange={value => dispatch(setZoneState({ cityId: value }))}
                value={zoneState.cityId}
                label='Місто'
              />
            </Grid>
          )}
          {zoneState._id && zoneState.type === 'blue' ? (
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={zoneState.prohibitStop ?? false}
                    color='primary'
                    onChange={(event, checked) =>
                      dispatch(setZoneState({ prohibitStop: checked }))
                    }
                  />
                }
                label='Заборонити паркування'
              />
            </Grid>
          ) : null}
          <Grid item>
            <UploadButton
              loading={isUploadingFile}
              buttonProps={{
                color: 'primary',
                variant: 'text'
              }}
              onChange={async (e: InputChangeEvent) => {
                const { files } = e.target;

                if (files) {
                  const file = files[0];

                  setIsUploadingFile(true);

                  uploadZoneTypeFile(
                    zoneState.type,
                    zoneState.cityId || currentCityId,
                    file,
                    async (changed: any) => {
                      console.log('-=-=- changed', changed);
                      setIsUploadingFile(false);
                    },
                    () => {
                      setIsUploadingFile(false);
                    }
                  );
                }
              }}
            >
              Завантажити нові координати
            </UploadButton>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color="info"
          onClick={async () => {
            dispatch(getZonesAsync(true));
            if (onClose) {
              onClose({}, 'escapeKeyDown');
            }
          }}
        >
          Закрити
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={async () => {
            if (zoneState._id) {
              await dispatch(
                updateZoneAsync(zoneState._id, zoneState)
              );
            } else {
              await dispatch(createZoneAsync(zoneState));
            }
            dispatch(getZonesAsync(true));
            if (onClose) {
              onClose({}, 'escapeKeyDown');
            }
          }}
        >
          Зберегти
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ZoneModal;
