import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import { useMemo } from 'react';
import ScooterCounItem from './ScooterCounItem';
import { useSelector } from 'react-redux';
import { selectScooters } from '../../redux/slices/scootersSlice';
import { MapFilters, ScooterStatus } from '../../pages/ZonesPage/interface';
import { getScooterColor } from '../../helpers/scooter-markers-helper';

const PREFIX = 'ScooterCounters';

const classes = {
  container: `${PREFIX}-container`,
  containerCount: `${PREFIX}-container-count`,
  free: `${PREFIX}-free`,
  unFree: `${PREFIX}-unFree`,
  checked: `${PREFIX}-checked`,
  err: `${PREFIX}-err`
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.container}`]: {
    marginBottom: 10,
    display: 'flex',
    fontSize: 18,
    flexWrap: 'wrap',
    '& span:nth-child(2)': {
      marginLeft: 10
    }
  },
  [`& .${classes.containerCount}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 18,
    padding: 5,
    minWidth: 95,
    height: 95,
    borderStyle: 'solid',
    borderRadius: 12,
    marginRight: 12,
    marginBottom: 12,
    cursor: 'pointer',
    textAlign: 'center',
    '&:hover': {
      boxShadow: '0px 0px 10px 0 rgba(0, 0, 0, 0.2)'
    },
    '& span:nth-child(2)': {
      marginLeft: 0,
      fontSize: 25,
      fontWeight: 'bold'
    },
    '& span:nth-child(3)': {
      marginLeft: 0,
      fontSize: 13
    }
  },

  [`& .${classes.free}`]: {
    '& span:nth-child(2)': {
      marginRight: 20,
      fontWeight: 700,
      color: theme.palette.primary.main
    }
  },

  [`& .${classes.unFree}`]: {
    '& span:nth-child(2)': {
      marginRight: 20,
      fontWeight: 700,
      color: theme.palette.error.main
    }
  },

  [`& .${classes.checked}`]: {
    '& span:nth-child(2)': {
      marginRight: 20,
      fontWeight: 700,
      color: theme.palette.info.main
    }
  },
  [`& .${classes.err}`]: {
    '& span:nth-child(2)': {
      marginRight: 20,
      fontWeight: 700,
      color: theme.palette.error.main
    }
  }
}));

interface Props {
  mapFilters?: MapFilters;
  onFilterSet?: (val: MapFilters) => void;
}

const DISABLED_COLOR = '#9B9B9B';

const ScooterCounters = ({ mapFilters, onFilterSet }: Props) => {
  const {
    items,
    total,
    free,
    onContract,
    transporting,
    charging,
    maintenance,
    offline,
    unlocked,
    lowBattery
  } = useSelector(selectScooters);

  const broken = useMemo(
    () =>
      items.filter(scooter => scooter.status === ScooterStatus.BROKEN_IN_CITY)
        .length ?? 0,
    [items]
  );

  const onUpdateFilters = (value: MapFilters) => {
    const filters = { ...mapFilters };

    if (
      Object.keys(value).includes('free') ||
      Object.keys(value).includes('offline')
    ) {
      delete filters?.status;
      delete value?.status;
    }

    if (Object.keys(value).includes('status')) {
      delete filters?.free;
      delete value?.free;
      delete filters?.offline;
      delete value?.offline;
    }

    if (value?.free === filters?.free) {
      delete filters?.free;
      delete value?.free;
    }

    if (value?.offline === filters?.offline) {
      delete filters?.offline;
      delete value?.offline;
    }

    const newFilters = {
      ...filters,
      ...value
    };
    onFilterSet?.(newFilters);
  };

  const onSetStatus = (status: ScooterStatus) => {
    let arr = mapFilters?.status?.split(',') ?? [];

    let index = arr.indexOf(status);
    if (index > -1) {
      arr.splice(index, 1);
    } else {
      arr.push(status);
    }

    let newStatus = arr.join(',');

    onUpdateFilters({ status: newStatus });
  };

  const isAll = useMemo(
    () => mapFilters && Object.keys(mapFilters).length === 0,
    [mapFilters]
  );
  const isFree = useMemo(
    () =>
      mapFilters &&
      (Object.keys(mapFilters).length === 0 ||
        mapFilters.free ||
        mapFilters.free === 'all'),
    [mapFilters]
  );
  const isUnlocked = useMemo(
    () =>
      mapFilters &&
      (Object.keys(mapFilters).length === 0 || mapFilters.unlocked),
    [mapFilters]
  );
  const isInRent = useMemo(
    () =>
      mapFilters &&
      (Object.keys(mapFilters).length === 0 ||
        (Object.keys(mapFilters).includes('free') &&
          Boolean(mapFilters.free) === false) ||
        mapFilters.free === 'all'),
    [mapFilters]
  );
  const isInTransporting = useMemo(
    () =>
      mapFilters &&
      (Object.keys(mapFilters).length === 0 ||
        mapFilters?.status?.includes(ScooterStatus.TRANSPORTING)),
    [mapFilters]
  );
  const isInCharging = useMemo(
    () =>
      mapFilters &&
      (Object.keys(mapFilters).length === 0 ||
        mapFilters?.status?.includes(ScooterStatus.CHARGING)),
    [mapFilters]
  );
  const isInMaintainance = useMemo(
    () =>
      mapFilters &&
      (Object.keys(mapFilters).length === 0 ||
        mapFilters?.status?.includes(ScooterStatus.MAINTENANCE)),
    [mapFilters]
  );
  const isOffline = useMemo(
    () =>
      mapFilters &&
      (Object.keys(mapFilters).length === 0 || mapFilters.offline),
    [mapFilters]
  );
  const isBrokenInCity = useMemo(
    () =>
      mapFilters &&
      (Object.keys(mapFilters).length === 0 ||
        mapFilters?.status?.includes(ScooterStatus.BROKEN_IN_CITY)),
    [mapFilters]
  );

  const isDischarged = useMemo(
    () =>
      mapFilters &&
      (Object.keys(mapFilters).length === 0 ||
        mapFilters?.status?.includes(ScooterStatus.LOW_BATTERY)),
    [mapFilters]
  );

  return (
    <Root className={classes.container}>
      <ScooterCounItem
        className={classNames(classes.containerCount)}
        onPress={() => onFilterSet?.({})}
        title='Всі'
        count={String(total)}
        color={isAll ? getScooterColor('all') : DISABLED_COLOR}
      />
      <ScooterCounItem
        className={classNames(classes.containerCount)}
        onPress={() =>
          onUpdateFilters(
            isInRent && mapFilters?.free !== 'all'
              ? { free: 'all' }
              : { free: true }
          )
        }
        title='Вільні'
        count={String(free)}
        color={isFree ? getScooterColor('free') : DISABLED_COLOR}
      />
      <ScooterCounItem
        className={classNames(classes.containerCount)}
        onPress={() =>
          onUpdateFilters(
            isFree && mapFilters?.free !== 'all'
              ? { free: 'all' }
              : { free: false }
          )
        }
        title='У поїздці'
        count={String(onContract)}
        color={isInRent ? getScooterColor('inRent') : DISABLED_COLOR}
      />
      <ScooterCounItem
        className={classNames(classes.containerCount)}
        onPress={() => onUpdateFilters({ unlocked: true })}
        title='Розблокований'
        count={String(unlocked)}
        color={isUnlocked ? getScooterColor('unlocked') : DISABLED_COLOR}
      />
      <ScooterCounItem
        className={classNames(classes.containerCount)}
        onPress={() => onSetStatus?.(ScooterStatus.TRANSPORTING)}
        title='Перевезення'
        count={String(transporting)}
        color={
          isInTransporting
            ? getScooterColor(ScooterStatus.TRANSPORTING)
            : DISABLED_COLOR
        }
      />
      <ScooterCounItem
        className={classNames(classes.containerCount)}
        onPress={() => onSetStatus?.(ScooterStatus.CHARGING)}
        title='На зарядці'
        count={String(charging)}
        color={
          isInCharging
            ? getScooterColor(ScooterStatus.CHARGING)
            : DISABLED_COLOR
        }
      />
      <ScooterCounItem
        className={classNames(classes.containerCount)}
        onPress={() => onSetStatus?.(ScooterStatus.MAINTENANCE)}
        title='В ремонті'
        count={String(maintenance)}
        color={
          isInMaintainance
            ? getScooterColor(ScooterStatus.MAINTENANCE)
            : DISABLED_COLOR
        }
      />
      <ScooterCounItem
        className={classNames(classes.containerCount)}
        onPress={() => onUpdateFilters({ offline: true })}
        title='Оффлайн'
        count={String(offline)}
        color={isOffline ? getScooterColor('offline') : DISABLED_COLOR}
      />
      <ScooterCounItem
        className={classNames(classes.containerCount)}
        onPress={() => onSetStatus?.(ScooterStatus.BROKEN_IN_CITY)}
        title='Зламаний в місті'
        count={String(broken)}
        color={
          isBrokenInCity
            ? getScooterColor(ScooterStatus.BROKEN_IN_CITY)
            : DISABLED_COLOR
        }
      />
      <ScooterCounItem
        className={classNames(classes.containerCount)}
        onPress={() => onSetStatus?.(ScooterStatus.LOW_BATTERY)}
        title='Розряджений'
        count={String(lowBattery)}
        color={isDischarged ? getScooterColor('discharged') : DISABLED_COLOR}
      />
    </Root>
  );
};

export default ScooterCounters;
