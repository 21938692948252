import React, { useMemo } from 'react';
import { Marker } from '@react-google-maps/api';
import {
  ZoneMarker,
  ZoneMarkerType
} from '../../../resources/types/zonesTypes';
import noParking from '../../../img/no-parking.png';
import stop from '../../../img/stop.png';
import parking from '../../../img/parking.png';
import cashback from '../../../img/cashback.png';
import payzone from '../../../img/payzone.png';
import slow from '../../../img/slow.png';
import { MapLatLng } from '../../../pages/ZonesPage/interface';

interface Props {
  marker: ZoneMarker;
  onSelect?: () => void;
  isEdit?: boolean;
  updatedCoords?: MapLatLng | null;
  updateType?: ZoneMarkerType;
}

const ZoneMapMarker = ({
  marker,
  onSelect,
  isEdit,
  updatedCoords,
  updateType
}: Props) => {
  const currentMarkerType = useMemo(() => {
    if (isEdit) {
      return updateType ?? marker.type;
    }
    return marker.type;
  }, [isEdit, marker.type, updateType]);

  const position = useMemo(() => {
    if (isEdit && updatedCoords) {
      return updatedCoords;
    }
    return {
      lat: marker.coordinates[0],
      lng: marker.coordinates[1]
    };
  }, [isEdit, marker.coordinates, updatedCoords]);

  const marlerUri = useMemo(() => {
    if (currentMarkerType === 'parking') {
      return parking;
    }

    if (currentMarkerType === 'no-parking') {
      return noParking;
    }

    if (currentMarkerType === 'cashback') {
      return cashback;
    }

    if (currentMarkerType === 'payzone') {
      return payzone;
    }

    if (currentMarkerType === 'slow') {
      return slow;
    }

    if (currentMarkerType === 'stop') {
      return stop;
    }

    return null;
  }, [currentMarkerType]);

  if (!marlerUri) return null;

  return (
    <Marker
      icon={{
        url: marlerUri,
        labelOrigin: new window.google.maps.Point(16, 15)
      }}
      position={position}
      zIndex={9000}
      onDblClick={() => onSelect?.()}
    />
  );
};

export default ZoneMapMarker;
