import { Grid, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchUsersStatisticsByDates,
  fetchUsersStatisticsByDays,
  selectUsersStatisticsByDates,
  selectUsersStatisticsByDays
} from '../../../redux/slices/balanceSlice';
import { DateRange } from '@material-ui/pickers';
import { Moment } from 'moment';
import { selectAdmins } from '../../../redux/slices/adminsSlice';
import { selectCities } from '../../../redux/slices/citiesSlice';
import DateRangePicker from '../../../components/DateRangePicker';

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

const UsersStatistic = () => {
  const dispatch = useDispatch();

  const usersStatisticsByDays = useSelector(selectUsersStatisticsByDays);
  const usersStatisticsByDates = useSelector(selectUsersStatisticsByDates);
  const cities = useSelector(selectCities);

  const adminInfo = useSelector(selectAdmins);
  const [dateRange, setDateRange] = useState<DateRange<Moment | null>>([
    null,
    null
  ]);

  const currentCityId = useMemo(() => {
    const city = cities.items.find(item => item._id === adminInfo.currentCity);
    if (city) {
      return city?._id;
    }
    return adminInfo?.currentCity;
  }, [adminInfo.currentCity, cities.items]);

  const isValidDate = useMemo(() => {
    return dateRange?.[0]?.isValid() && dateRange?.[1]?.isValid();
  }, [dateRange]);

  useEffect(() => {
    dispatch(fetchUsersStatisticsByDays());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentCityId) {
      if (!isValidDate) {
        return;
      }

      const startDate = dateRange?.[0]?.format?.(DATE_FORMAT) ?? null;
      const endDate = dateRange?.[1]?.format?.(DATE_FORMAT) ?? null;
      dispatch(
        fetchUsersStatisticsByDates(
          currentCityId,
          isValidDate ? startDate : null,
          isValidDate ? endDate : null
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCityId, dateRange, isValidDate]);

  return (
    <Grid item>
      <Grid item display={'flex'} justifyContent={'center'}>
        <Typography
          sx={{
            fontWeigth: '500',
            fontSize: 20,
            textDecoration: 'underline'
          }}
        >
          Кількість нових користувачів
        </Typography>
      </Grid>

      <Grid
        item
        display={'flex'}
        flexDirection={'row'}
        gap={2}
        justifyContent={'space-between'}
      >
        <Typography>За день: {usersStatisticsByDays?.lastDay ?? 0}</Typography>
        <Typography>
          За тиждень: {usersStatisticsByDays?.last7Days ?? 0}
        </Typography>
        <Typography>
          За місяць: {usersStatisticsByDays?.last30Days ?? 0}
        </Typography>
      </Grid>
      <Grid item>
        <DateRangePicker
          startText='Від'
          endText='До'
          value={dateRange}
          onChange={(date: DateRange<Moment>) => {
            setDateRange(date);
          }}
        />
        {isValidDate ? (
          <Typography>{usersStatisticsByDates} нових користувачів</Typography>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default UsersStatistic;
