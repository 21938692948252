import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import moment from 'moment';

import { Props } from './interface';
import { theme } from '../../resources/theme';
import { convertSecondsToHHMMSS } from '../../helpers/time';

const styles = {
  text: {
    color: theme.palette.info.main,
    fontWeight: 700
  }
};

export const TripTimeModal = (props: Props) => {
  const {
    createdAt,
    closedAt,
    cost,
    status,
    cableImgCoords,
    tripHistoryDetails,
    ...restProps
  } = props;

  const [tripTime, setTripTime] = useState<string | null>(null);

  const [normalTime, setNormalTime] = useState<string | null>(null);
  const [pauseTime, setPauseTime] = useState<string | null>(null);
  const [rentTime, setRentTime] = useState<string | null>(null);

  useEffect(() => {
    if (closedAt) {
      const diffSeconds = moment(closedAt).diff(moment(createdAt), 'seconds');
      const duration = moment.duration(diffSeconds, 'seconds');
      const formattedDuration = moment
        .utc(duration.as('milliseconds'))
        .format('HH:mm:ss');
      setTripTime(formattedDuration);
    } else {
      setTripTime(null);
    }
  }, [closedAt, createdAt]);

  useEffect(() => {
    if (tripHistoryDetails?.normal && tripHistoryDetails?.normal.duration > 0) {
      const formattedTime = convertSecondsToHHMMSS(
        tripHistoryDetails?.normal.duration
      );
      setNormalTime(formattedTime);
    } else {
      setNormalTime(null);
    }
  }, [tripHistoryDetails?.normal]);

  useEffect(() => {
    if (tripHistoryDetails?.pause && tripHistoryDetails?.pause.duration > 0) {
      const formattedTime = convertSecondsToHHMMSS(
        tripHistoryDetails?.pause.duration
      );
      setPauseTime(formattedTime);
    } else {
      setPauseTime(null);
    }
  }, [tripHistoryDetails?.pause]);

  useEffect(() => {
    if (
      tripHistoryDetails?.reservation &&
      tripHistoryDetails?.reservation.duration > 0
    ) {
      const formattedTime = convertSecondsToHHMMSS(
        tripHistoryDetails?.reservation.duration
      );
      setRentTime(formattedTime);
    } else {
      setRentTime(null);
    }
  }, [tripHistoryDetails?.reservation]);

  return (
    <Dialog {...restProps}>
      <DialogTitle>Деталі поїздки</DialogTitle>

      <DialogContent>
        <Stack spacing={3}>
          <Stack direction='row' alignItems='center' spacing={2}>
            <Typography>
              {status === 'reservation'
                ? 'Час бронювання поїздки:'
                : 'Час початку поїздки:'}
            </Typography>
            <Typography sx={styles.text}>
              {moment(createdAt).format('DD-MMMM-YYYY, HH:mm:ss')}
            </Typography>
          </Stack>
          <Stack direction='row' alignItems='center' spacing={2}>
            <Typography>Час закінчення поїздки:</Typography>
            <Typography sx={styles.text}>
              {closedAt
                ? moment(closedAt).format('DD-MMMM-YYYY, HH:mm:ss')
                : 'Поїздка триває'}
            </Typography>
          </Stack>
          {normalTime ? (
            <Stack direction='row' alignItems='center' spacing={2}>
              <Typography>Час у дорозі:</Typography>
              <Typography sx={styles.text}>{normalTime}</Typography>
            </Stack>
          ) : null}

          {pauseTime ? (
            <Stack direction='row' alignItems='center' spacing={2}>
              <Typography>Час у паузі:</Typography>
              <Typography sx={styles.text}>{pauseTime}</Typography>
            </Stack>
          ) : null}

          {rentTime ? (
            <Stack direction='row' alignItems='center' spacing={2}>
              <Typography>Час у бронюванні:</Typography>
              <Typography sx={styles.text}>{rentTime}</Typography>
            </Stack>
          ) : null}

          {tripTime && (
            <Stack direction='row' alignItems='center' spacing={2}>
              <Typography>Тривалість поїздки:</Typography>
              <Typography sx={styles.text}>{tripTime}</Typography>
            </Stack>
          )}
          <Stack direction='row' alignItems='center' spacing={2}>
            <Typography>Вартість поїздки:</Typography>
            <Typography sx={styles.text}>
              {cost ? `${cost} грн` : 'Не вказано'}
            </Typography>
          </Stack>
          {cableImgCoords?.lat || cableImgCoords?.lon ? (
            <Stack direction='row' alignItems='center' spacing={2}>
              <Typography>Координати:</Typography>
              <Typography sx={styles.text}>
                {cableImgCoords?.lat ?? null}
                <br />
                {cableImgCoords?.lon ?? null}
              </Typography>
            </Stack>
          ) : null}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => props.onClose?.({}, 'backdropClick')}>
          Вихід
        </Button>
      </DialogActions>
    </Dialog>
  );
};
