import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import axios from '../../resources/axios';
import {
  Subscription
} from '../../resources/types/balanceTypes';
import { handleDefaultErrors } from '../../resources/functions';

interface SubscriptionState {
  subscriptions: {
    items: Subscription[],
    shouldUpdate: boolean
  },
}

const initialState: SubscriptionState = {
  subscriptions: {
    items: [],
    shouldUpdate: true
  },
};

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    getSubscriptions: (state, action) => {
      state.subscriptions.items = action.payload;
      state.subscriptions.shouldUpdate = false;
    },
    createOrUpdateSubscription: state => {
      state.subscriptions.shouldUpdate = true;
    },
  }
});

const {
  getSubscriptions,
  createOrUpdateSubscription,
} = subscriptionSlice.actions;


export const getSubscriptionAsync =
  (cityId: string): AppThunk => async (dispatch, getState) => {
    if (!getState().subscription.subscriptions.shouldUpdate) return;
    try {
      const response = await axios.get(`/subscription?cityId=${cityId}`);
      dispatch(getSubscriptions(response?.data?.data?.plans ?? []));
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const deleteSubscriptionAsync =
  (id: string, cityId: string): AppThunk => async (dispatch) => {
    try {
      await axios.delete(`/subscription/${id}`)
      dispatch(createOrUpdateSubscription());
      await dispatch(getSubscriptionAsync(cityId));
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const createOrUpdateSubscriptionAsync =
  (id: string, body: Subscription, success: () => void): AppThunk =>
  async dispatch => {
    try {
      if (id) {
        await axios.put(`/subscription/${id}`, body);
      } else {
        await axios.post(`/subscription`, body);
      }
      dispatch(createOrUpdateSubscription());
      await dispatch(getSubscriptionAsync(body.city));
      success();
    } catch (error) {
      handleDefaultErrors(error);
    }
  };


export const selectSubscriptions = (state: RootState) => state.subscription;

export default subscriptionSlice.reducer;
