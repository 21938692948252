import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import axios from '../../resources/axios';
import {
  GetTariffsParams,
  Promocode,
  Tariff,
  GetUserHistoryParams,
  History,
  GetCitiesBalanceParams,
  CityBalance,
  GetStatisticsParams,
  WasteCitiesBalance
} from '../../resources/types/balanceTypes';
import { handleDefaultErrors } from '../../resources/functions';

interface TariffsState {
  items: Tariff[];
  shouldUpdate: boolean;
}

interface PromocodesState {
  items: Promocode[];
  shouldUpdate: boolean;
}

interface HistoryState {
  items: History[];
  count: number;
}

interface CitiesState {
  items: CityBalance[];
}

interface CitiesState {
  items: CityBalance[];
}

interface UsersDaysStatisticState {
  lastDay: number;
  last7Days: number;
  last30Days: number;
}

interface BalanceState {
  tariffs: TariffsState;
  history: HistoryState;
  promocodes: PromocodesState;
  cities: CitiesState;
  wasteCitiesBalance: WasteCitiesBalance[];
  usersDaysStatistic: UsersDaysStatisticState;
  usersDatesStatistic: number;
}

const initialState: BalanceState = {
  tariffs: {
    items: [],
    shouldUpdate: true
  },
  promocodes: {
    items: [],
    shouldUpdate: true
  },
  history: {
    items: [],
    count: 0
  },
  cities: {
    items: []
  },
  usersDaysStatistic: {
    lastDay: 0,
    last7Days: 0,
    last30Days: 0
  },
  usersDatesStatistic: 0,
  wasteCitiesBalance: []
};

type PartialStatisticItem = {
  city: string;
  sum: number;
};

export const balanceSlice = createSlice({
  name: 'balance',
  initialState,
  reducers: {
    getTariffs: (state, action) => {
      state.tariffs.items = action.payload.tariffs;
      state.tariffs.shouldUpdate = false;
    },
    updateTariff: state => {
      state.tariffs.shouldUpdate = true;
    },
    getPromocodes: (state, action) => {
      state.promocodes.items = action.payload.promocodes;
      state.promocodes.shouldUpdate = false;
    },
    createOrUpdatePromocode: state => {
      state.promocodes.shouldUpdate = true;
    },
    getUserHistory: (state, action) => {
      state.history.items = action.payload.userBalanceHistories;
      state.history.count = action.payload.count;
    },
    clearUserHistory: state => {
      state.history.items = [];
      state.history.count = 0;
    },
    setCitiesBalance: (state, action: PayloadAction<CityBalance[]>) => {
      state.cities.items = action.payload;
    },
    setNewUsersDaysStatistic: (
      state,
      action: PayloadAction<UsersDaysStatisticState>
    ) => {
      state.usersDaysStatistic.last30Days = action.payload.last30Days ?? 0;
      state.usersDaysStatistic.last7Days = action.payload.last7Days ?? 0;
      state.usersDaysStatistic.lastDay = action.payload.lastDay ?? 0;
    },
    setNewUsersDatesStatistic: (state, action: PayloadAction<number>) => {
      state.usersDatesStatistic = action.payload ?? 0;
    },
    getStatistics: (state, action) => {
      const cities = Array.from(
        new Set(
          action.payload.data.map((item: PartialStatisticItem) =>
            String(item.city)
          )
        ),
        city => {
          const cityCount = action.payload.data.reduce(
            (acc: number, item: PartialStatisticItem) => {
              if (item.city === city) {
                acc += item.sum;
              }
              return acc;
            },
            0
          );
          return { cityName: city, count: cityCount } as WasteCitiesBalance;
        }
      );

      state.wasteCitiesBalance = cities;
    }
  }
});

const {
  getTariffs,
  updateTariff,
  getPromocodes,
  getUserHistory,
  createOrUpdatePromocode,
  setCitiesBalance,
  getStatistics,
  setNewUsersDaysStatistic,
  setNewUsersDatesStatistic
} = balanceSlice.actions;

export const { clearUserHistory } = balanceSlice.actions;

export const getTariffsAsync = (params?: GetTariffsParams): AppThunk => {
  return async (dispatch, getState) => {
    if (!getState().balance.tariffs.shouldUpdate) {
      return;
    }
    try {
      const response = await axios.get('/balance/tariffs', { params });
      dispatch(getTariffs(response.data));
    } catch (error) {
      handleDefaultErrors(error);
    }
  };
};

export const updateTariffAsync =
  (type: string, body: Tariff, success: () => void): AppThunk =>
  async dispatch => {
    try {
      await axios.put(`/balance/tariffs/${type}`, body);
      dispatch(updateTariff());
      await dispatch(getTariffsAsync());
      success();
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const deleteTariffAsync =
  (_id: string): AppThunk =>
  async dispatch => {
    try {
      await axios.delete(`/balance/tariffs/${_id}`);
      dispatch(updateTariff());
      await dispatch(getTariffsAsync());
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const createTimingTariffAsync =
  (type: string, body: Tariff, success: () => void): AppThunk =>
  async dispatch => {
    try {
      await axios.post(`/balance/tariffs/${type}`, body);
      dispatch(updateTariff());
      await dispatch(getTariffsAsync());
      success();
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const getPromocodesAsync =
  (): AppThunk => async (dispatch, getState) => {
    if (!getState().balance.promocodes.shouldUpdate) return;
    try {
      const response = await axios.get('/bonuscodes');
      dispatch(
        getPromocodes({ promocodes: response?.data?.data?.bonusCodes ?? [] })
      );
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const createOrUpdatePromocodeAsync =
  (body: Promocode, success: () => void): AppThunk =>
  async dispatch => {
    try {
      await axios.post('/bonuscodes', body);
      dispatch(createOrUpdatePromocode());
      await dispatch(getPromocodesAsync());
      success();
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const getUserHistoryAsync =
  (params: GetUserHistoryParams): AppThunk =>
  async dispatch => {
    try {
      const response = await axios.get('/balance/histories', { params });
      dispatch(getUserHistory(response.data));
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const fetchCitiesBalance =
  (params?: GetCitiesBalanceParams): AppThunk =>
  async dispatch => {
    try {
      const response = await axios.get('/balance/cities', { params });
      dispatch(setCitiesBalance(response.data));
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const fetchStatistics =
  (params?: GetStatisticsParams): AppThunk =>
  async dispatch => {
    try {
      const response = await axios.get('/statistics', { params });
      dispatch(getStatistics(response.data));
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const fetchUsersStatisticsByDays = (): AppThunk => async dispatch => {
  try {
    dispatch(
      setNewUsersDaysStatistic({
        lastDay: 0,
        last7Days: 0,
        last30Days: 0
      })
    );
    const response = await axios.get('/users/new_users_count_default');
    dispatch(
      setNewUsersDaysStatistic(response.data?.data?.newUsersCount ?? {})
    );
  } catch (error) {
    handleDefaultErrors(error);
  }
};

export const fetchUsersStatisticsByDates =
  (cityId: string, from: string | null, to: string | null): AppThunk =>
  async dispatch => {
    try {
      dispatch(setNewUsersDatesStatistic(0));
      const dateDuration = from && to ? `&dateFrom=${from}&dateTo=${to}` : '';
      const response = await axios.get(
        `/users/new_users_count?cityId=${cityId}${dateDuration}`
      );
      dispatch(
        setNewUsersDatesStatistic(response.data?.data?.newUsersCount ?? 0)
      );
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const selectBalance = (state: RootState) => state.balance;
export const selectCitiesBalance = (state: RootState) =>
  state.balance.cities.items;

export const selectStatistics = (state: RootState) =>
  state.balance.wasteCitiesBalance;

export const selectUsersStatisticsByDays = (state: RootState) =>
  state.balance.usersDaysStatistic;

export const selectUsersStatisticsByDates = (state: RootState) =>
  state.balance.usersDatesStatistic;

export default balanceSlice.reducer;
