import { TextField } from '@mui/material';
import { DatePickerProps } from './interface';
import { DatePicker as MuiDatePicker } from '@mui/lab';

const DatePicker = (props: DatePickerProps) => {
  const { onChange, value, label } = props;
  return (
    <MuiDatePicker
      label={label}
      renderInput={(params: any) => <TextField {...params} />}
      value={value}
      onChange={onChange}
    />
  );
};

export default DatePicker;
