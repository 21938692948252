import React from 'react';
import { Grid, TextField, Button } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DateRangePicker from '../../components/DateRangePicker';

import { Moment } from 'moment';
import { DateRange } from '@material-ui/pickers';

export interface FilterHeaderValues {
  scooterId?: string;
  scooterName?: string;
  adminId?: string;
  adminLogin?: string;
  userId?: string;
  cityId?: string;
  dateRange?: DateRange<Moment | null>;
}

interface FilterHeaderProps {
  onSubmit: (values: Partial<FilterHeaderValues>) => void;
  initialValues: FilterHeaderValues;
}

const validationSchema = Yup.object().shape({
  scooterId: Yup.string().optional(),
  scooterName: Yup.string().optional(),
  adminId: Yup.string().optional(),
  adminLogin: Yup.string().optional(),
  userId: Yup.string().optional(),
  cityId: Yup.string().optional(),
  dateRange: Yup.array().of(Yup.string().nullable()).optional()
});

const FilterHeader = ({ onSubmit, initialValues }: FilterHeaderProps) => {
  const formik = useFormik<FilterHeaderValues>({
    initialValues: {
      ...initialValues,
      dateRange: initialValues.dateRange || [null, null]
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: values => {
      onSubmit(values as Partial<FilterHeaderValues>);
    },
    onReset: () => {
      onSubmit(initialValues as Partial<FilterHeaderValues>);
    }
  });

  return (
    <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
      <Grid container display={'flex'} direction='row' spacing={2}>
        <Grid
          item
          flex={1}
          display={'flex'}
          direction='row'
          flexWrap={'wrap'}
          gap={2}
        >
          {initialValues.scooterId !== undefined && (
            <Grid item>
              <TextField
                name='scooterId'
                label='ID самоката'
                value={formik.values.scooterId}
                onChange={formik.handleChange}
                error={
                  formik.touched.scooterId && Boolean(formik.errors.scooterId)
                }
                helperText={formik.touched.scooterId && formik.errors.scooterId}
              />
            </Grid>
          )}

          {initialValues.scooterName !== undefined && (
            <Grid item>
              <TextField
                name='scooterName'
                label='Назва самоката'
                value={formik.values.scooterName}
                onChange={formik.handleChange}
                error={
                  formik.touched.scooterName &&
                  Boolean(formik.errors.scooterName)
                }
                helperText={
                  formik.touched.scooterName && formik.errors.scooterName
                }
              />
            </Grid>
          )}

          {initialValues.adminId !== undefined && (
            <Grid item>
              <TextField
                name='adminId'
                label='ID адміністратора'
                value={formik.values.adminId}
                onChange={formik.handleChange}
                error={formik.touched.adminId && Boolean(formik.errors.adminId)}
                helperText={formik.touched.adminId && formik.errors.adminId}
              />
            </Grid>
          )}

          {initialValues.adminLogin !== undefined && (
            <Grid item>
              <TextField
                name='adminLogin'
                label='Логін адміністратора'
                value={formik.values.adminLogin}
                onChange={formik.handleChange}
                error={
                  formik.touched.adminLogin && Boolean(formik.errors.adminLogin)
                }
                helperText={
                  formik.touched.adminLogin && formik.errors.adminLogin
                }
              />
            </Grid>
          )}

          {initialValues.userId !== undefined && (
            <Grid item>
              <TextField
                name='userId'
                label='ID користувача'
                value={formik.values.userId}
                onChange={formik.handleChange}
                error={formik.touched.userId && Boolean(formik.errors.userId)}
                helperText={formik.touched.userId && formik.errors.userId}
              />
            </Grid>
          )}

          {initialValues.cityId !== undefined && (
            <Grid item>
              <TextField
                name='cityId'
                label='ID міста'
                value={formik.values.cityId}
                onChange={formik.handleChange}
                error={formik.touched.cityId && Boolean(formik.errors.cityId)}
                helperText={formik.touched.cityId && formik.errors.cityId}
              />
            </Grid>
          )}

          {initialValues.dateRange !== undefined && (
            <Grid item>
              <DateRangePicker
                startText='Від'
                endText='До'
                value={formik.values.dateRange as DateRange<Moment | null>}
                onChange={(date: DateRange<Moment>) => {
                  formik.setFieldValue('dateRange', date);
                }}
              />
            </Grid>
          )}
        </Grid>

        <Grid
          item
          display={'flex'}
          flexDirection={'row'}
          gap={1}
          alignItems={'flex-start'}
        >
          <Button variant='contained' color='primary' type='submit'>
            Застосувати
          </Button>
          <Button variant='contained' color='error' type='reset'>
            Очистити
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default FilterHeader;
