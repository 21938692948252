import { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { Pagination } from '@mui/material';
import {
  getUserHistoryAsync,
  selectBalance,
  clearUserHistory
} from '../../redux/slices/balanceSlice';
import Title from '../../components/Title';
import { formatDate } from '../../resources/functions';

const PREFIX = 'HistoryPage';

const classes = {
  tableContainer: `${PREFIX}-tableContainer`
};

const Root = styled('div')({
  [`& .${classes.tableContainer}`]: {
    margin: '20px 0'
  }
});

const limit = 20;

const HistoryPage = () => {
  const balance = useSelector(selectBalance);

  const dispatch = useDispatch();

  const [page, setPage] = useState(1);

  const handleChangePage = useCallback(
    (e: any, nextPage: number) => setPage(nextPage),
    []
  );

  useEffect(() => {
    dispatch(getUserHistoryAsync({ type: 'admin', limit, page }));
  }, [dispatch, page]);

  useEffect(() => {
    return () => {
      dispatch(clearUserHistory());
    };
  }, [dispatch]);

  return (
    <Root>
      <Title>Транзакції</Title>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Адміністратор</TableCell>
              <TableCell>Телефон клієнта</TableCell>
              <TableCell>Сума</TableCell>
              <TableCell>Дата</TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            {balance.history.items.map(item => (
              <TableRow key={item.createdAt}>
                <TableCell>{item.admin?.login}</TableCell>
                <TableCell>{item.user?.phone}</TableCell>
                <TableCell>{item.amount}</TableCell>
                <TableCell>
                  {formatDate(item.createdAt, { time: true })}
                </TableCell>
              </TableRow>
            ))}
          </TableHead>
        </Table>
      </TableContainer>
      <Pagination
        count={balance.history.count}
        page={page}
        onChange={handleChangePage}
      />
    </Root>
  );
};

export default HistoryPage;
