import { TextField } from '@mui/material';
import React from 'react';
import { DateRangePickerProps } from './interface';
import { DateRangePicker as MuiDateRangePicker } from '@mui/lab';

const DateRangePicker = (props: DateRangePickerProps) => {
  const { endText, startText, onChange, value } = props;

  return (
    <MuiDateRangePicker
      startText={startText}
      endText={endText}
      value={value}
      onChange={onChange}
      renderInput={(startProps: any, endProps: any) => (
        <React.Fragment>
          <TextField {...startProps} />
          &nbsp;&nbsp;&nbsp;{'-'}&nbsp;&nbsp;&nbsp;
          <TextField {...endProps} />
        </React.Fragment>
      )}
    />
  );
};

export default DateRangePicker;
