import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Container } from '@mui/material';
import AudioAlert from './containers/AudioAlert';
import WebSocketProvider from './containers/WebSocketProvider';
import SignInPage from './pages/SignInPage';
import { selectAuth } from './redux/slices/authSlice';
import Sidebar from './components/Sidebar';
import Router from './containers/Router';
import { getCitiesAsync } from './redux/slices/citiesSlice';
import { getTabsAsync } from './redux/slices/tabsSlice';
import {
  getAdminInfoAsync,
  initGoogleApiAsync
} from './redux/slices/adminsSlice';
import SidebarToggleButton from './containers/SidebarToggleButton';
import DevLabel from './components/DevLabel';

const PREFIX = 'App';

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  loader: `${PREFIX}-loader`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: 'flex'
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },

  [`& .${classes.loader}`]: {
    position: 'fixed',
    left: '50%',
    top: '50%',
    marginLeft: -40,
    marginTop: -40
  }
}));

function App() {
  const auth = useSelector(selectAuth);

  const dispatch = useDispatch();

  const [isInfoFetching, setIsInfoFetching] = useState(true);

  useEffect(() => {
    if (auth.info) {
      dispatch(initGoogleApiAsync());
      if (auth.info.type === 'superAdmin') {
        (async () => {
          setIsInfoFetching(true);
          await dispatch(getCitiesAsync());
          await dispatch(getTabsAsync());
          setIsInfoFetching(false);
        })();
      } else {
        (async () => {
          setIsInfoFetching(true);
          await dispatch(getAdminInfoAsync());
          await dispatch(getCitiesAsync());
          setIsInfoFetching(false);
        })();
      }
    }
  }, [auth.info, dispatch]);

  if (!auth.info) return <SignInPage />;

  if (isInfoFetching) {
    return (
      <Root>
        <CircularProgress
          className={classes.loader}
          color='primary'
          size={80}
        />
      </Root>
    );
  }

  return (
    <Root>
      <WebSocketProvider>
        <AudioAlert />
        <div className={classes.root}>
          <SidebarToggleButton />
          <Sidebar />
          <Container className={classes.content} maxWidth='xl'>
            <Router />
          </Container>
          <DevLabel />
        </div>
      </WebSocketProvider>
    </Root>
  );
}

export default App;
