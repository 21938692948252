import { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Pagination,
  TextField,
  Typography
} from '@mui/material';
import { Note, NotesListResponse, Props } from './interface';
import {
  addScooterNoteAsync,
  getScooterNotesAsync
} from '../../redux/slices/scootersSlice';
import moment from 'moment';

const ScooterNotesModal = (props: Props) => {
  const { scooter, isSuperAdmin, ...restProps } = props;

  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [data, setData] = useState<Note[]>([]);
  const [newNote, setNewNote] = useState<string>('');

  const getNotes = () => {
    getScooterNotesAsync({
      scooterId: scooter?._id,
      page,
      limit: 20
    }).then((res: NotesListResponse) => {
      const notes = res.data.notes ?? [];
      notes.sort(
        (a: Note, b: Note) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      setData(notes);
      setPages(res.pagination.totalPages ?? 1);
    });
  };

  useEffect(() => {
    if (props.open) {
      getNotes();
    } else {
      setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, page, scooter?._id]);

  const assNewNote = () => {
    addScooterNoteAsync({ scooterId: scooter?._id, text: newNote }).then(() => {
      getNotes();
      setNewNote('');
    });
  };

  return (
    <Dialog {...restProps}>
      <DialogTitle>Нотатки самоката № {scooter?.name}</DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
          direction='column'
          minWidth={400}
          paddingX={1}
        >
          <Grid
            container
            justifyContent={'space-between'}
            alignItems={'center'}
            spacing={2}
            flexDirection={'row'}
          >
            <Grid item xs={10}>
              <TextField
                name='note'
                label='Нова нотатка'
                fullWidth
                value={newNote}
                onChange={e => {
                  setNewNote(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={2} mt={2}>
              <Button color='primary' onClick={assNewNote}>
                Додати
              </Button>
            </Grid>
          </Grid>

          <Grid item>
            {data.length === 0 ? (
              <Grid item justifyContent={'center'} alignItems={'center'}>
                <Typography textAlign={'center'}>Немає нотаток</Typography>
              </Grid>
            ) : null}
            {data.map((item, index) => (
              <Grid
                key={index}
                container
                flexDirection={'row'}
                justifyContent='space-between'
                marginTop={1}
              >
                <Grid item xs={8} container flexDirection={'column'}>
                  <Typography fontWeight={'600'}>{item.adminLogin}</Typography>
                  <Typography>{item.text}</Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  container
                  flexDirection={'column'}
                  alignItems={'flex-end'}
                >
                  <Typography>
                    {moment(item.createdAt).format('DD.MM.YYYY')}
                  </Typography>
                  <Typography>
                    {moment(item.createdAt).format('HH:mm:ss')}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid item>
            {data.length ? (
              <Pagination
                count={pages}
                page={page}
                onChange={(event, nextPage) => setPage(nextPage)}
              />
            ) : null}
          </Grid>
          <Grid item>
            <Button onClick={() => props.onClose?.({}, 'backdropClick')}>
              Вихід
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ScooterNotesModal;
