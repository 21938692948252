import { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  IconButton,
  MenuItem,
  Paper,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material';
import { Pagination } from '@mui/material';
import { History, Timeline, Send, Devices, Block } from '@mui/icons-material';
import usePermissions from '../../hooks/usePermissions';
import ContractsHistoryModal from '../../modals/ContractsHistoryModal';
import { getUsersAsync, selectUsers } from '../../redux/slices/usersSlice';
import Title from '../../components/Title';
import TableCell from '../../components/TableCell';
import { formatDate } from '../../resources/functions';
import {
  GetUsersParams,
  SortFields,
  SortOrder,
  User,
  UserType
} from '../../resources/types/usersTypes';
import UsersDialog from './UsersDialog';
import UserHistory from '../../containers/UserHistory';

import { ReactComponent as CrownIcon } from '../../img/crown.svg';
import UserPushModal from '../../modals/UserPushModal';
import UserSessionsModal from '../../modals/UserSessionsModal';
import { selectAuth } from '../../redux/slices/authSlice';
import UserBanModal from '../../modals/UserBanModal';

const PREFIX = 'UsersPage';

const classes = {
  header: `${PREFIX}-header`,
  filters: `${PREFIX}-filters`,
  balance: `${PREFIX}-balance`,
  userTypeSelect: `${PREFIX}-userTypeSelect`,
  tableContainer: `${PREFIX}-tableContainer`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.header}`]: {
    display: 'flex',
    alignItems: 'center'
  },

  [`& .${classes.filters}`]: {
    display: 'flex',
    marginTop: 20,
    '& > * + *': {
      marginLeft: 20
    }
  },

  [`& .${classes.balance}`]: {
    marginTop: 20,
    fontSize: 16,
    '& span:first-child': {
      fontWeight: 700,
      color: theme.palette.grey.A200
    },
    '& span:last-child': {
      color: theme.palette.primary.main
    }
  },

  [`& .${classes.userTypeSelect}`]: {
    width: 140
  },

  [`& .${classes.tableContainer}`]: {
    marginTop: 20,
    marginBottom: 40
  }
}));

const LIMIT = 10;

const userTypes = [
  {
    name: 'Усі',
    value: ''
  },
  {
    name: 'Звичайний',
    value: 'normal'
  },
  {
    name: 'VIP',
    value: 'vip'
  }
];

const getUserName = ({ firstName, lastName, middleName }: User) => {
  return [lastName, firstName, middleName].filter(a => a).join(' ');
};

const sortOptions: Record<SortFields, { asc: string; desc: string }> = {
  firstName: {
    asc: 'firstName_asc',
    desc: 'firstName_desc'
  },
  mileage: {
    asc: 'mileage_asc',
    desc: 'mileage_desc'
  },
  balance: {
    asc: 'balance_asc',
    desc: 'balance_desc'
  },
  devices: {
    asc: 'devices_asc',
    desc: 'devices_desc'
  }
};

const UsersPage = () => {
  const users = useSelector(selectUsers);
  const dispatch = useDispatch();
  const checkPermission = usePermissions();
  const auth = useSelector(selectAuth);

  const isSuperAdmin = useMemo(() => {
    return auth.info?.type === 'superAdmin';
  }, [auth.info?.type]);

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [type, setType] = useState<UserType>('');
  // const [paymentId, setPaymentId] = useState<string>('');
  const [page, setPage] = useState(1);

  const [sentPushUser, setSentPushUser] = useState<User | null>(null);
  const [sessionsUser, setSessionsUser] = useState<User | null>(null);
  const [banUser, setBanUser] = useState<User | null>(null);

  const [currentField, setCurrentField] = useState<SortFields | null>(null);
  const [currentOrder, setCurrentOrder] = useState<SortOrder | null>(null);

  const [contractsHistoryDialogState, setContractsHistoryDialogState] =
    useState<{ open: boolean; userId?: string }>({ open: false });

  const getUsers = useCallback(() => {
    const params: GetUsersParams = {
      limit: LIMIT,
      page
    };

    if (name) {
      params.name = name;
    }
    if (phone) {
      params.phone = phone;
    }

    if (type) {
      params.type = type;
    }

    if (
      currentField &&
      currentOrder &&
      sortOptions[currentField][currentOrder]
    ) {
      params.sortType = sortOptions[currentField][currentOrder];
    }

    dispatch(getUsersAsync(params));
  }, [currentField, currentOrder, dispatch, name, page, phone, type]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const onChangePage = useCallback((e: any, nextPage: number) => {
    setPage(nextPage);
  }, []);

  const paginationCount = useMemo(
    () => Math.ceil(users.pagination.count / LIMIT),
    [users.pagination.count]
  );

  const [open, setOpen] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

  const toggleDialog = useCallback(
    (userId: string | null = null) =>
      () => {
        setOpen(!open);
        setSelectedUserId(userId);
      },
    [open]
  );

  const toggleHistoryDialog = useCallback(
    (userId: string | null = null) =>
      (e: any) => {
        e.stopPropagation();

        setOpenHistory(!openHistory);
        setSelectedUserId(userId);
      },
    [openHistory]
  );

  const toggleSortOrder = (field: SortFields) => {
    const newOrder = currentOrder === 'asc' ? 'desc' : 'asc';
    setCurrentField(field);
    setCurrentOrder(newOrder);
    return sortOptions[field][newOrder];
  };

  return (
    <Root>
      <div className={classes.header}>
        <Title>Користувачі</Title>
      </div>
      <div className={classes.filters}>
        <TextField
          label='ПІБ'
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <TextField
          label='Телефон'
          value={phone}
          onChange={e => setPhone(e.target.value)}
        />
        <TextField
          select
          className={classes.userTypeSelect}
          label='Тип'
          value={type}
          onChange={e => setType(e.target.value as UserType)}
        >
          {userTypes.map(userType => (
            <MenuItem key={userType.value} value={userType.value}>
              {userType.name}
            </MenuItem>
          ))}
        </TextField>
        {/* <TextField
          label='Номер транзакції'
          value={paymentId}
          onChange={e => setPaymentId(e.target.value)}
        /> */}
      </div>
      {users?.totalBalance ? (
        <div className={classes.balance}>
          <span>Загальний баланс:</span>{' '}
          <span>{users?.totalBalance?.toLocaleString()}</span>
        </div>
      ) : null}
      {Boolean(users.items.length) && (
        <div>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    onClick={() => toggleSortOrder('firstName')}
                    sx={{
                      cursor: 'pointer'
                    }}
                  >
                    ПІБ
                  </TableCell>
                  <TableCell>Пошта</TableCell>
                  <TableCell>Телефон</TableCell>
                  <TableCell>Дата нарождення</TableCell>
                  <TableCell
                    onClick={() => toggleSortOrder('mileage')}
                    sx={{
                      cursor: 'pointer'
                    }}
                  >
                    Баланс
                  </TableCell>
                  <TableCell
                    onClick={() => toggleSortOrder('balance')}
                    sx={{
                      cursor: 'pointer'
                    }}
                  >
                    Пробіг (км)
                  </TableCell>
                  <TableCell
                    onClick={() => toggleSortOrder('devices')}
                    sx={{
                      cursor: 'pointer'
                    }}
                  >
                    Активні сеанси
                  </TableCell>
                  {checkPermission('history') || isSuperAdmin ? (
                    <TableCell />
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {users.items.map(user => (
                  <TableRow
                    hover
                    key={user._id}
                    onClick={toggleDialog(user._id)}
                  >
                    <TableCell>
                      <Stack direction='row' alignItems='center'>
                        {user.type === 'vip' && (
                          <SvgIcon
                            component={CrownIcon}
                            viewBox='0 0 1280 815'
                            sx={{ width: 18, height: 18, mr: 0.5 }}
                          />
                        )}
                        {getUserName(user)}
                      </Stack>
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.phone}</TableCell>
                    <TableCell>{formatDate(user.birthday)}</TableCell>
                    <TableCell>{user.balance}</TableCell>
                    <TableCell>{user.mileage}</TableCell>
                    <TableCell>{user.devicesCount ?? 0}</TableCell>
                    {checkPermission('history') || isSuperAdmin ? (
                      <TableCell>
                        <IconButton
                          size='small'
                          onClick={toggleHistoryDialog(user._id)}
                        >
                          <History />
                        </IconButton>
                        <IconButton
                          size='small'
                          onClick={(e: any) => {
                            e.stopPropagation();
                            setContractsHistoryDialogState({
                              open: true,
                              userId: user._id
                            });
                          }}
                        >
                          <Timeline />
                        </IconButton>
                        <IconButton
                          size='small'
                          onClick={(e: any) => {
                            e.stopPropagation();
                            setSentPushUser(user);
                          }}
                        >
                          <Send />
                        </IconButton>
                        {isSuperAdmin ? (
                          <>
                            <IconButton
                              size='small'
                              onClick={(e: any) => {
                                e.stopPropagation();
                                setSessionsUser(user);
                              }}
                            >
                              <Devices />
                            </IconButton>
                            <IconButton
                              size='small'
                              onClick={(e: any) => {
                                e.stopPropagation();
                                setBanUser(user);
                              }}
                            >
                              <Block />
                            </IconButton>
                          </>
                        ) : null}
                      </TableCell>
                    ) : null}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination count={paginationCount} onChange={onChangePage} />
        </div>
      )}
      <UsersDialog
        open={open}
        userId={selectedUserId}
        onClose={toggleDialog(null)}
        onSave={getUsers}
      />
      <UserHistory
        open={openHistory}
        userId={selectedUserId}
        onClose={toggleHistoryDialog(null)}
      />
      <ContractsHistoryModal
        {...contractsHistoryDialogState}
        onClose={() => setContractsHistoryDialogState({ open: false })}
      />
      {sessionsUser !== null ? (
        <UserSessionsModal
          open={sessionsUser !== null}
          onClose={() => setSessionsUser(null)}
          user={sessionsUser}
        />
      ) : null}

      {banUser !== null ? (
        <UserBanModal
          open={banUser !== null}
          onClose={() => setBanUser(null)}
          user={banUser}
        />
      ) : null}

      {sentPushUser !== null ? (
        <UserPushModal
          open={sentPushUser !== null}
          onClose={() => setSentPushUser(null)}
          user={sentPushUser}
        />
      ) : null}
    </Root>
  );
};

export default UsersPage;
