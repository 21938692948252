import { LoadingButton } from '@mui/lab';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import UploadButton from '../../components/UploadButton';
import {
  useCreateBonusGameMutation,
  useUpdateBonusGameMutation
} from '../../redux/api/bonusGameApi';
import bonusGameTypeName from '../../resources/bonusGameTypeName';
import getFileUrl from '../../resources/getFileUrl';
import { Languages } from '../../resources/types';
import { BonusGameBonusTypes } from '../../resources/types/bonusGameTypes';
import { BonusGameModalProps } from './BonusGameModalTypes';
import { useSelector } from 'react-redux';
import { selectAdmins } from '../../redux/slices/adminsSlice';
import { selectCities } from '../../redux/slices/citiesSlice';

const defaultValues = {
  totalSeconds: '',
  type: BonusGameBonusTypes.money,
  moneyBonus: '',
  bonusCode: '',
  icon: '',
  level: '',
  city: '',
  nthUserBonuses: [],
  ru: { description: '', title: '' },
  en: { description: '', title: '' },
  ua: { description: '', title: '' }
};

const BonusGameModal = ({
  editBonusGame,
  isOpen,
  onClose
}: BonusGameModalProps) => {
  const cities = useSelector(selectCities);
  const adminInfo = useSelector(selectAdmins);

  const [createBonusGame, { isLoading: isBonusGameCreating }] =
    useCreateBonusGameMutation();
  const [updateBonusGame, { isLoading: isBonusGameUpdating }] =
    useUpdateBonusGameMutation();

  const isLoading = isBonusGameCreating || isBonusGameUpdating;

  const isEditing = Boolean(editBonusGame);

  const [language, setLanguage] = useState(Languages.ua);

  const cityId = useMemo(() => {
    const city = cities.items.find(item => item._id === adminInfo.currentCity);
    return city ? city._id : '';
  }, [adminInfo.currentCity, cities.items]);

  const cityName = useMemo(() => {
    const city = cities.items.find(item => item._id === adminInfo.currentCity);
    if (city) {
      return city.cityName;
    }
  }, [adminInfo.currentCity, cities.items]);

  const formik = useFormik({
    initialValues: editBonusGame || {
      ...defaultValues,
      city: cityId
    },
    onSubmit: async values => {
      try {
        if (editBonusGame) {
          await updateBonusGame(values).unwrap();
        } else {
          await createBonusGame({
            ...values,
            city: adminInfo?.currentCity ?? '',
            level: Number(values?.level)
          }).unwrap();
        }
        onClose();
      } catch (error) {
        alert(error?.data.message);
      }
    }
  });

  const {
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    values,
    resetForm
  } = formik;

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen, resetForm]);

  useEffect(() => {
    if (editBonusGame) {
      setValues(editBonusGame);
    }
  }, [editBonusGame, setValues]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth='sm'
      fullWidth
      disableEnforceFocus
      disableAutoFocus
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>Бонусна гра</DialogTitle>
        <DialogContent>
          <FormikProvider value={formik}>
            <Grid container direction='column' spacing={4}>
              <Grid item>
                <Typography>Місто: {cityName || '-'}</Typography>
              </Grid>
              <Grid item>
                <TextField
                  name='level'
                  disabled={isEditing}
                  type='number'
                  fullWidth
                  label='Рівень'
                  value={values.level}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item>
                <TextField
                  name={`${language}.title`}
                  fullWidth
                  label='Назва'
                  value={values[language]?.title}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item>
                <TextField
                  name={`${language}.description`}
                  label='Введіть опис'
                  fullWidth
                  multiline
                  value={values[language]?.description}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item>
                <TextField
                  name='totalSeconds'
                  type='number'
                  fullWidth
                  label='Кількість витрачених секунд'
                  value={values.totalSeconds}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item>
                <ButtonGroup>
                  {Object.keys(BonusGameBonusTypes).map(type => (
                    <Button
                      key={type}
                      onClick={() => setFieldValue('type', type)}
                      variant={values.type === type ? 'contained' : 'outlined'}
                    >
                      {bonusGameTypeName[type as BonusGameBonusTypes]}
                    </Button>
                  ))}
                </ButtonGroup>
              </Grid>
              {values.type === BonusGameBonusTypes.money && (
                <Grid item>
                  <TextField
                    name='moneyBonus'
                    type='number'
                    fullWidth
                    label='Введіть суму поповнення балансу'
                    value={values.moneyBonus}
                    onChange={handleChange}
                  />
                </Grid>
              )}
              {values.type === BonusGameBonusTypes.promo && (
                <>
                  <Grid item>
                    <UploadButton
                      onFileUpload={(file: string) =>
                        setFieldValue('icon', file)
                      }
                      buttonProps={{ variant: 'contained' }}
                    >
                      Завантажити файл логотипу
                    </UploadButton>
                  </Grid>
                  {values.icon && (
                    <Grid item>
                      <Link href={getFileUrl(values.icon)} target='_blank'>
                        {values.icon}
                      </Link>
                    </Grid>
                  )}
                  <Grid item>
                    <TextField
                      name='bonusCode'
                      fullWidth
                      label='Введіть помокод'
                      value={values.bonusCode}
                      onChange={handleChange}
                    />
                  </Grid>
                </>
              )}
              <FieldArray name='nthUserBonuses'>
                {({ push, remove }) => (
                  <Grid item>
                    <Typography>NTH Бонуси</Typography>
                    {values?.nthUserBonuses?.map((bonus, index) => (
                      <Grid container key={index} spacing={4} alignItems={"center"} marginBottom={2}>
                        <Grid item xs={4}>
                          <TextField
                            name={`nthUserBonuses[${index}].nth`}
                            label='nth'
                            type='number'
                            value={bonus.nth}
                            onChange={handleChange}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            name={`nthUserBonuses[${index}].money`}
                            label='сумма'
                            type='number'
                            value={bonus.money}
                            onChange={handleChange}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            variant='outlined'
                            color='secondary'
                            onClick={() => remove(index)}
                          >
                            Видалити
                          </Button>
                        </Grid>
                      </Grid>
                    ))}
                    <Button
                      variant='outlined'
                      color='primary'
                      onClick={() => push({ nth: '', money: '' })}
                    >
                      Додати бонус
                    </Button>
                  </Grid>
                )}
              </FieldArray>
            </Grid>
          </FormikProvider>
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={isLoading} type='submit'>
            Зберегти
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default BonusGameModal;
