import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField
} from '@mui/material';
import { useDispatch } from 'react-redux';
import useBind from '../../hooks/useBind';
import { NewTab } from '../../resources/types/TabsTypes';
import {
  createTabAsync,
  getTabAsync,
  getTabsAsync,
  updateTabAsync
} from '../../redux/slices/tabsSlice';

const initialState: NewTab = {
  name: '',
  code: ''
};

const TabsDialog = (
  props: DialogProps & { onClose: () => void; tabId?: string }
) => {
  const { open, onClose, tabId } = props;

  const dispatch = useDispatch();

  const [tab, setTab] = useState(initialState);

  useEffect(() => {
    if (tabId && open) {
      const getTab = async () => {
        const response: any = await dispatch(getTabAsync(tabId));
        setTab({
          name: response.data.tabs.name,
          code: response.data.tabs.code
        });
      };

      getTab();
    } else {
      setTab(initialState);
    }
  }, [tabId, dispatch, open]);

  const bind = useBind(tab, setTab);

  const handleSave = useCallback(async () => {
    try {
      if (tabId) {
        await dispatch(updateTabAsync(tabId, tab));
      } else {
        await dispatch(createTabAsync(tab));
      }

      dispatch(getTabsAsync());
      onClose();
    } catch (e) {
      if (e.response) {
        alert(e.response.data.message);
      }
    }
  }, [tab, tabId, dispatch, onClose]);

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={onClose}>
      <DialogTitle>{tabId ? 'Редагування' : 'Створення'}</DialogTitle>
      <DialogContent>
        {!tabId && (
          <TextField
            {...bind('name')}
            fullWidth
            label='Назва'
            margin='normal'
            variant='outlined'
          />
        )}
        <TextField
          {...bind('code')}
          fullWidth
          label='Код'
          margin='normal'
          variant='outlined'
        />
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={handleSave}>
          Зберегти
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TabsDialog;
