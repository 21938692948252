import { createApi } from '@reduxjs/toolkit/query/react';
import { BonusGame } from '../../resources/types/bonusGameTypes';
import baseQuery from './baseQuery';

export const bonusGameApi = createApi({
  reducerPath: 'bonusGameApi',
  baseQuery,
  tagTypes: ['BonusGame'],
  endpoints: builder => ({
    getBonusGames: builder.query<BonusGame[], void>({
      query: () => 'bonus_game/admin',
      providesTags: ['BonusGame'],
      transformResponse: (res: any) => {
        return res?.data ?? []
      },
    }),
    updateBonusGame: builder.mutation({
      query: body => ({
        url: `bonus_game/${body._id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['BonusGame']
    }),
    createBonusGame: builder.mutation({
      query: body => ({
        url: 'bonus_game',
        method: 'POST',
        body
      }),
      invalidatesTags: ['BonusGame']
    }),
    deleteBonusGame: builder.mutation({
      query: body => ({
        url: `bonus_game/${body._id}`,
        method: 'DELETE',
        body
      }),
      invalidatesTags: ['BonusGame']
    })
  })
});

export const {
  useGetBonusGamesQuery,
  useCreateBonusGameMutation,
  useUpdateBonusGameMutation,
  useDeleteBonusGameMutation,
} = bonusGameApi;
