import React, { useRef } from 'react';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import { Button, CircularProgress } from '@mui/material';
import useAsync from '../../hooks/useAsync';
import uploadFiles from '../../resources/uploadFiles';
import { InputChangeEvent } from '../../resources/utils';
import { UploadButtonProps } from './interface';

const PREFIX = 'UploadButton';

const classes = {
  root: `${PREFIX}-root`,
  progress: `${PREFIX}-progress`,
  input: `${PREFIX}-input`
};

const Root = styled('div')({
  [`&.${classes.root}`]: {
    position: 'relative'
  },
  [`& .${classes.progress}`]: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  [`& .${classes.input}`]: {
    position: 'absolute',
    opacity: 0
  }
});

const UploadButton = ({
  loading,
  onChange,
  children,
  className,
  buttonProps,
  onFileUpload,
  ...props
}: UploadButtonProps) => {
  const { execute: uploadFilesAsync, pending: isFilesUploading } =
    useAsync(uploadFiles);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleChange = async (e: InputChangeEvent) => {
    if (onChange) {
      onChange(e);
    }

    if (onFileUpload) {
      const { files } = e.target;

      if (files) {
        const file = files[0];

        const filename = await uploadFilesAsync(file);

        if (filename) {
          onFileUpload(filename);
        }
      }
    }
  };

  const isLoading = isFilesUploading || loading;

  return (
    <Root {...props} className={classNames(classes.root, className)}>
      <input
        className={classes.input}
        ref={inputRef}
        type='file'
        onChange={handleChange}
      />
      {isLoading && (
        <CircularProgress
          className={classes.progress}
          color='primary'
          size={24}
        />
      )}
      <Button
        disabled={isLoading}
        {...buttonProps}
        type='button'
        onClick={handleClick}
      >
        {children}
      </Button>
    </Root>
  );
};

export default UploadButton;
