import React from 'react';
import { Typography } from '@mui/material';
import { TitleProps } from './interface';

const Title = ({ children, ...props }: TitleProps) => {
  return (
    <Typography color='textPrimary' variant='h4' {...props}>
      {children}
    </Typography>
  );
};

export default Title;
