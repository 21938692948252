import { styled } from '@mui/material/styles';

export const PREFIX = 'ZonesPage';

export const classes = {
  header: `${PREFIX}-header`,
  uploadButton: `${PREFIX}-uploadButton`,
  blockContainer: `${PREFIX}-blockContainer`,
  saveButton: `${PREFIX}-saveButton`,
  tabButton: `${PREFIX}-tabButton`,
  infoWindow: `${PREFIX}-infoWindow`,
  collapseLabel: `${PREFIX}-collapseLabel`,
  tableBody: `${PREFIX}-tableBody`,
  filters: `${PREFIX}-filters`,
  counterWrap: `${PREFIX}-counterWrap`,
  lock: `${PREFIX}-lock`,
  scooterIcons: `${PREFIX}-scooterIcons`,
  routeLink: `${PREFIX}-routeLink`,
  contractImages: `${PREFIX}-contractImages`,
  contractImage: `${PREFIX}-contractImage`,
  typeSelector: `${PREFIX}-typeSelector`,
};

export const Root = styled('div')(({ theme }) => ({
  [`& .${classes.header}`]: {
    display: 'flex',
    alignItems: 'center'
  },

  [`& .${classes.uploadButton}`]: {
    marginLeft: 20
  },

  [`& .${classes.blockContainer}`]: {
    marginTop: 20
  },

  [`& .${classes.saveButton}`]: {
    margin: 20
  },

  [`& .${classes.infoWindow}`]: {
    padding: 6,
    paddingRight: 25,
    fontSize: 12,
    color: 'gray',
    whiteSpace: 'nowrap'
  },

  [`& .${classes.collapseLabel}`]: {
    padding: '10px 20px'
  },

  [`& .${classes.tableBody}`]: {
    '& td': {
      verticalAlign: 'top'
    }
  },

  [`& .${classes.filters}`]: {
    padding: '8px 20px !important'
  },

  [`& .${classes.counterWrap}`]: {
    padding: '0 20px'
  },

  [`& .${classes.lock}`]: {
    color: theme.palette.error.main
  },

  [`& .${classes.scooterIcons}`]: {
    display: 'inline-flex'
  },

  [`& .${classes.routeLink}`]: {
    color: theme.palette.primary.main,
    textDecoration: 'underline'
  },

  [`& .${classes.contractImages}`]: {
    display: 'flex'
  },

  [`& .${classes.contractImage}`]: {
    width: 64,
    height: 64,
    borderRadius: 8,
    '& + &': {
      marginLeft: 12
    },
    '& img': {
      width: '100%',
      height: '100%'
    }
  }
}));

export const defaultCenter = {
  lat: 48.9225224,
  lng: 24.7103188
};

export const LIMIT = 20;

export const zonesColors = {
  green: {
    strokeColor: '#0338f6',
    strokeWeight: 2,
    fillColor: '#2FCB75',
    fillOpacity: 0.25
  },
  blue: {
    strokeColor: '#1D6FF2',
    strokeWeight: 2,
    fillColor: '#1D6FF2',
    fillOpacity: 0.26
  },
  yellow: {
    strokeColor: '#0338f6',
    strokeWeight: 2,
    fillColor: '#fffb84',
    fillOpacity: 0.25
  },
  red: {
    strokeColor: '#0338f6',
    strokeWeight: 2,
    fillColor: '#ff6363',
    fillOpacity: 0.25
  },
  grey: {
    strokeColor: '#0338f6',
    strokeWeight: 2,
    fillColor: '#6d6e6f',
    fillOpacity: 0.25
  },
  pink: {
    strokeColor: '#0338f6',
    strokeWeight: 2,
    fillColor: 'rgba(255, 20, 147, 0.35)',
    fillOpacity: 0.25
  },
  brown: {
    strokeColor: '#0338f6',
    strokeWeight: 2,
    fillColor: 'rgba(101, 53, 15, 0.35)',
    fillOpacity: 0.25
  },
  purple: {
    strokeColor: '#0338f6',
    strokeWeight: 2,
    fillColor: 'rgba(130, 17, 168, 0.35)',
    fillOpacity: 0.25
  }
};