import React, { useEffect, useRef } from 'react';
import { AutocompleteProps } from './interface';
import { TextField } from '@mui/material';

const Autocomplete = ({ onSelect, ...props }: AutocompleteProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const autocomplete = useRef<google.maps.places.Autocomplete>();

  if (ref.current) {
    ref.current.autocomplete = 'new-password';
  }

  useEffect(() => {
    if (ref.current && !autocomplete.current) {
      autocomplete.current = new window.google.maps.places.Autocomplete(
        ref.current
      );

      autocomplete.current.setFields(['address_components', 'geometry']);

      autocomplete.current.addListener('place_changed', () => {
        const place = autocomplete.current!.getPlace();
        if (place.address_components && place.geometry) {
          const result = place.address_components[0].short_name;

          const lat = place.geometry.location.lat();
          const lon = place.geometry.location.lng();

          onSelect(result, { lat, lon });
        }
      });
    }
  }, [ref, onSelect]);

  useEffect(() => {
    return () => {
      Array.from(
        document.getElementsByClassName('pac-container')
      ).forEach(item => item.remove());
    };
  }, []);

  return (
    <TextField
      {...props}
      fullWidth
      autoComplete='new-password'
      inputRef={ref}
      margin='normal'
      variant='outlined'
    />
  );
};

export default Autocomplete;
