import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { TextField, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import Title from '../../components/Title';
import UserTypeSelect from '../../containers/UserTypeSelect';
import { InputChangeEvent } from '../../resources/utils';
import { sendPushAsync } from '../../redux/slices/usersSlice';
import { SendPushBody, UserType } from '../../resources/types/usersTypes';

const PREFIX = 'PushesPage';

const classes = {
  header: `${PREFIX}-header`,
  wrapper: `${PREFIX}-wrapper`,
  submit: `${PREFIX}-submit`
};

const Root = styled('div')({
  [`& .${classes.header}`]: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20
  },
  [`& .${classes.wrapper}`]: {
    maxWidth: 400
  },
  [`& .${classes.submit}`]: {
    marginTop: 20
  }
});

const PushesPage = () => {
  const dispatch = useDispatch();

  const [text, setText] = useState('');
  const [userType, setUserType] = useState<UserType>('');

  const submit = async () => {
    if (!text) {
      alert('Введіть текст');
    } else {
      if (!window.confirm('Відправити пуш-повідомлення?')) return;

      try {
        const body: SendPushBody = { text };

        if (userType) body.userType = userType;

        await dispatch(sendPushAsync(body));

        alert('Пуш-повідомлення успішно відправлено');

        setText('');
        setUserType('');
      } catch (e) {
        alert('Помилка');
      }
    }
  };

  return (
    <Root>
      <div className={classes.header}>
        <Title>Пуш-повідомлення</Title>
      </div>
      <div className={classes.wrapper}>
        <UserTypeSelect
          allOption
          value={userType}
          onChange={(e: InputChangeEvent) =>
            setUserType(e.target.value as UserType)
          }
        />
      </div>
      <TextField
        rows={12}
        fullWidth
        multiline
        required
        label='Текст'
        margin='normal'
        value={text}
        onChange={(e: InputChangeEvent) => setText(e.target.value)}
      />
      <Button
        className={classes.submit}
        color='primary'
        variant='contained'
        onClick={submit}
      >
        Відправити
      </Button>
    </Root>
  );
};

export default PushesPage;
