import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Button, FormControl, Input, InputLabel } from '@mui/material';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { Props } from './interface';
import {
  getUserAsync,
  updateUserBalanceAsync
} from '../../redux/slices/usersSlice';
import { InputChangeEvent } from '../../resources/utils';

const PREFIX = 'UserBalance';

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  submit: `${PREFIX}-submit`,
  balance: `${PREFIX}-balance`,
  negative: `${PREFIX}-negative`
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`& .${classes.wrapper}`]: {
    display: 'inline-flex',
    alignItems: 'flex-end'
  },

  [`& .${classes.submit}`]: {
    marginLeft: 20
  },

  [`& .${classes.balance}`]: {
    fontWeight: 600,
    color: theme.palette.primary.main
  },

  [`& .${classes.negative}`]: {
    color: theme.palette.error.main
  }
}));

const UserBalance = ({ balance, margin, fullWidth, userId, onSave }: Props) => {
  const dispatch = useDispatch();

  const [value, setValue] = useState('');

  const submit = () => {
    dispatch(
      updateUserBalanceAsync(userId, { amount: Number(value) }, () => {
        dispatch(getUserAsync(userId));

        if (onSave) {
          onSave();
        }

        setValue('');
      })
    );
  };

  return (
    <StyledFormControl fullWidth={fullWidth} margin={margin}>
      <div className={classes.wrapper}>
        <FormControl fullWidth>
          <InputLabel>
            Баланс (
            <span
              className={clsx(classes.balance, balance < 0 && classes.negative)}
            >
              {balance}
            </span>
            )
          </InputLabel>
          <Input
            fullWidth={fullWidth}
            margin='none'
            type='number'
            value={value}
            onChange={(e: InputChangeEvent) => setValue(e.target.value)}
          />
        </FormControl>
        <Button className={classes.submit} color='primary' onClick={submit}>
          Додати
        </Button>
      </div>
      <div />
    </StyledFormControl>
  );
};

export default UserBalance;
