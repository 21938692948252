import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import { Props } from './interface';
import { useState } from 'react';
import { InputChangeEvent } from '../../resources/utils';
import { useDispatch } from 'react-redux';
import { sendToUserPushAsync } from '../../redux/slices/usersSlice';

const UserPushModal = (props: Props) => {
  const { user, ...restProps } = props;

  const dispatch = useDispatch();

  const [text, setText] = useState<string>('');

  const handleSubmit = async () => {
      if (!text) {
        alert('Введіть текст');
      } else {
        if (!window.confirm('Відправити пуш-повідомлення?')) return;
  
        try {
          dispatch(sendToUserPushAsync(user?._id, text, () => {
            alert('Пуш-повідомлення успішно відправлено');
            setText('');
          }));
        } catch (e) {
          alert('Помилка');
        }
      }
  };

  return (
    <Dialog {...restProps} maxWidth='md' fullWidth>
      <DialogTitle>Пуш повідомлення для: {user?.firstName} {user?.lastName} {user?.phone}</DialogTitle>
      <DialogContent>
      <TextField
        rows={12}
        fullWidth
        multiline
        required
        label='Текст'
        margin='normal'
        value={text}
        onChange={(e: InputChangeEvent) => setText(e.target.value)}
      />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit}>Зберегти</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserPushModal;
