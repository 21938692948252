import { DateRange } from '@material-ui/pickers';
import { Stack, Typography } from '@mui/material';
import { Moment } from 'moment';
import { useCallback } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts';
import DateRangePicker from '../../components/DateRangePicker';

import { BarChartProps } from './types';

const StyledBarChart = ({
  data,
  label,
  barDataKey,
  xDataKey,
  isWaste,
  value,
  setValue
}: BarChartProps) => {
  const handleChangeRange = useCallback(
    (nextValue: DateRange<Moment>) => {
      setValue(nextValue);
    },
    [setValue]
  );

  return (
    <BarChart width={400} height={400} data={data} barSize={50}>
      <CartesianGrid strokeDasharray='3 3' />
      <XAxis dataKey={xDataKey} />
      <YAxis />
      <Tooltip />
      <Legend
        verticalAlign='top'
        height={100}
        content={() => (
          <Stack alignItems='center'>
            <Typography
              sx={{
                fontWeigth: '500',
                fontSize: 20,
                textDecoration: 'underline'
              }}
            >
              {label}
            </Typography>

            <DateRangePicker
              startText='Від'
              endText='До'
              value={value}
              onChange={handleChangeRange}
            />
            {isWaste && (
              <Typography sx={{ fontSize: 12, color: 'red' }}>
                Відлік починається від вчорашньої дати
              </Typography>
            )}
          </Stack>
        )}
      />
      <Bar dataKey={barDataKey} fill='#8884d8' />
    </BarChart>
  );
};

export default StyledBarChart;
