import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack
} from '@mui/material';
import { useMemo } from 'react';

import { Props } from './interface';
import { TripCoordinatesHistory } from './TripCoordinatesHistory';
import { useSelector } from 'react-redux';
import { selectCities } from '../../redux/slices/citiesSlice';
import { selectAdmins } from '../../redux/slices/adminsSlice';

export const TripCoordinatesModal = (props: Props) => {
  const { tripCity, tripId, ...restProps } = props;

  const cities = useSelector(selectCities);
  const adminInfo = useSelector(selectAdmins);

  const cityId = useMemo(() => {
    return tripCity || adminInfo.currentCity;
  }, [adminInfo.currentCity, tripCity]);

  const cityCoords = useMemo(() => {
    const city = cities.items.find(item => item._id === cityId);
    if (city) {
      return {
        lat: city.lat as number,
        lng: city.lon as number
      };
    }
  }, [cities.items, cityId]);

  return (
    <Dialog {...restProps}>
      <DialogTitle>Маршрут поїздки</DialogTitle>
      <DialogContent>
        <Stack spacing={3} minWidth={450}>
          {cityCoords ? (
            <TripCoordinatesHistory mapCenter={cityCoords} tripId={tripId} />
          ) : null}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose?.({}, 'backdropClick')}>
          Вихід
        </Button>
      </DialogActions>
    </Dialog>
  );
};
