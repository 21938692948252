import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid
} from '@mui/material';
import Select from '../../components/Select';
import { IPromoCodeType, Promocode } from '../../resources/types/balanceTypes';
import { createOrUpdatePromocodeAsync } from '../../redux/slices/balanceSlice';
import { RootState } from '../../redux/store';
import { useEffect, useMemo } from 'react';
import { SelectOption } from '../../components/Select/interface';
import { useFormik } from 'formik';
import DatePicker from '../../components/DatePicker/DatePicker';
import moment, { Moment } from 'moment';
import { selectAdmins } from '../../redux/slices/adminsSlice';

interface IPromocodeModalProps {
  selectedPromocode: Promocode | null;
  open: boolean;
  setOpen: (open: boolean) => void;
  setSelectedPromocode: (promocode: Promocode) => void;
  onSuccess?: () => void;
}

const PromocodeModal = ({
  open,
  setOpen,
  selectedPromocode,
  onSuccess,
}: IPromocodeModalProps) => {
  const dispatch = useDispatch();

  const isSuperAdmin = useSelector(
    (state: RootState) => state.auth.info?.type === 'superAdmin'
  );
  const cities = useSelector((state: RootState) => state.cities.items);
  const adminInfo = useSelector(selectAdmins);

  const currentCityId = useMemo(() => {
    const city = cities.find(item => item._id === adminInfo.currentCity);
    if (city) {
      return city?._id;
    }
    return adminInfo?.currentCity;
  }, [adminInfo.currentCity, cities]);

  const citiesOptions = useMemo<SelectOption[]>(
    () =>
      cities.map(city => ({
        value: city._id,
        label: city.cityName
      })),
    [cities]
  );

  const { handleChange, handleSubmit, setFieldValue, setValues, values, resetForm } = useFormik({
    initialValues: {
      code: '',
      type: IPromoCodeType.addBalance,
      amount: 0,
      expiresAt: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      description: '',
      usingLimit: 0,
      city: currentCityId,
      active: true,
    },
    onSubmit: values => {
      dispatch(
        createOrUpdatePromocodeAsync(
          values,
          () => {
            setOpen(false);
            onSuccess?.();
          }
        )
      );

    }
  });

  useEffect(() => {
    if (open && selectedPromocode?._id) {
      setValues({
        code: selectedPromocode.code ?? '',
        type: selectedPromocode.type ?? IPromoCodeType.addBalance,
        amount: selectedPromocode.amount ?? 0,
        expiresAt: selectedPromocode.expiresAt ?? moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        description: selectedPromocode.description ?? '',
        usingLimit: selectedPromocode.usingLimit ?? 0,
        city: selectedPromocode.city ?? currentCityId,
        active: selectedPromocode.active ?? true,
      })
    }

    if (!open) {
      resetForm();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <Dialog fullWidth maxWidth='sm' open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        {selectedPromocode?._id
          ? 'Редагування промокоду'
          : 'Створення промокоду'}
      </DialogTitle>
      <DialogContent>
        {isSuperAdmin && (
          <Grid item marginBottom={1}>
            <Select
              fullWidth
              options={citiesOptions}
              onChange={value => setFieldValue('city', value)}
              value={values.city}
              label='Місто'
            />
          </Grid>
        )}
        <Grid item marginBottom={1}>
          <TextField
            fullWidth
            label='Код'
            name='code'
            onChange={handleChange}
            value={values.code}
          />
        </Grid>
        <Grid item marginBottom={1}>
          <TextField
            fullWidth
            label='Опис'
            name='description'
            onChange={handleChange}
            value={values.description}
          />
        </Grid>
        <Grid item marginBottom={1}>
          <TextField
            fullWidth
            label='Сумма'
            name='amount'
            type='number'
            onChange={handleChange}
            value={values.amount}
          />
        </Grid>
        <Grid item marginBottom={1}>
          <TextField
            fullWidth
            label='Ліміт Використань'
            name='usingLimit'
            type='number'
            onChange={handleChange}
            value={values.usingLimit}
          />
        </Grid>
        <Grid item marginBottom={1}>
        <DatePicker 
          label={'Термін дії'} 
          value={values.expiresAt as unknown as Moment} 
          onChange={(date: Moment | null) => {
            console.log(date);
            setFieldValue('expiresAt', moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"))
          }} 
        />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={() => handleSubmit()}>
          Зберегти
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PromocodeModal;
