import { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from '@mui/material';
import { IZoneConfigModalProps } from './interface';
import Title from '../../components/Title';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import Select from '../../components/Select';
import { SelectOption } from '../../components/Select/interface';
import { setCityZonesConfigAsync } from '../../redux/slices/zonesSlice';
import { ZoneType } from '../../resources/types/zonesTypes';
import { Translation, TranslationLanguages } from '../../resources/types/faqTypes';
import LanguageSelect from '../../components/LanguageSelect';

const descriptionInitialValue = {
  en: '',
  ua: ''
}

const ZoneConfigModal = ({
  config,
  open,
  onClose,
  addedForZones,
  currentCityId
}: IZoneConfigModalProps) => {
  const dispatch = useDispatch();

  const [language, setLanguage] = useState<TranslationLanguages>(
    TranslationLanguages.ua
  );
  const [zoneType, setZoneType] = useState<ZoneType>();
  const [amountForStop, setAmountForStop] = useState<number>(0);
  const [description, setDescription] = useState<Translation>(descriptionInitialValue);

  const types = useSelector(
    (state: RootState) => state.zones.types
  ) as ZoneType[];
  const typesOptions: SelectOption[] = types
    .map((type: ZoneType) => ({
      value: type,
      label: type,
      hidden: config?.type === type ? false : addedForZones.includes(type)
    }))
    .filter(t => !t.hidden);

  useEffect(() => {
    if (config?._id) {
      setZoneType(config?.type);
      setAmountForStop(config?.amountForStop ?? 0);
      setDescription(config?.description ?? descriptionInitialValue);
    }
  }, [config]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle>
        <Title>
          {config?._id ? 'Редагування конфігурації' : 'Додавання конфігурації'}
        </Title>
      </DialogTitle>
      <DialogContent>
        <Grid container direction='column' spacing={2}>
          <Grid item>
            <LanguageSelect value={language} onChange={setLanguage} />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              label='Опис'
              multiline
              margin='normal'
              name={`question`}
              onChange={e => {
                setDescription(state => ({
                  ...state,
                  [language]: e.target.value
                }));
              }}
              value={description[language]}
            />
          </Grid>
          <Grid item>
            <Select
              fullWidth
              options={typesOptions}
              onChange={value => setZoneType(value as ZoneType)}
              value={zoneType}
              label='Тип'
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              type='number'
              label='Вартість паркування'
              value={amountForStop}
              onChange={event =>
                setAmountForStop(Number(event.target.value ?? 0))
              }
            />
          </Grid>
          {/* {isSuperAdmin && (
            <Grid item>
              <Select
                fullWidth
                options={citiesOptions}
                onChange={value => dispatch(setZoneState({ cityId: value }))}
                value={zoneState.cityId}
                label='Місто'
              />
            </Grid>
          )} */}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='primary'
          onClick={async () => {
            dispatch(
              setCityZonesConfigAsync(currentCityId, {
                type: zoneType ?? config?.type,
                city: config?.city ?? currentCityId,
                amountForStop,
                description
              })
            );
            onClose?.({}, 'escapeKeyDown');
          }}
        >
          Зберегти
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ZoneConfigModal;
