import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { DateRange } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DateRangePicker from '../../components/DateRangePicker';
import TableCell from '../../components/TableCell';
import Title from '../../components/Title';
import {
  fetchCitiesBalance,
  selectCitiesBalance
} from '../../redux/slices/balanceSlice';

const CitiesBalancePage = () => {
  const dispatch = useDispatch();

  const [range, setRange] = useState<DateRange<Moment | null>>([
    moment().set({ date: 1, hours: 0, minutes: 0, seconds: 0 }),
    moment().set({ hours: 23, minutes: 59, seconds: 59 })
  ]);

  useEffect(() => {
    dispatch(
      fetchCitiesBalance({
        from: range[0]?.toISOString(),
        to: range[1]?.toISOString()
      })
    );
  }, [dispatch, range]);

  const cities = useSelector(selectCitiesBalance);

  const handleChangeRange = useCallback((nextRange: DateRange<Moment>) => {
    setRange(nextRange);
  }, []);

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <Title>Баланс міст</Title>
      </Grid>
      <Grid item>
        <DateRangePicker
          startText='Від'
          endText='До'
          value={range}
          onChange={handleChangeRange}
        />
      </Grid>
      <Grid item>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Місто</TableCell>
                <TableCell>Баланс</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cities.map(city => (
                <TableRow key={city._id}>
                  <TableCell>{city.cityName}</TableCell>
                  <TableCell>{city.count}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default CitiesBalancePage;
