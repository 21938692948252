import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  MenuItem,
  TextField
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import useBind from '../../hooks/useBind';
import { selectCities } from '../../redux/slices/citiesSlice';
import {
  createAdminAsync,
  getAdminAsync,
  getAdminsAsync,
  selectAdmins,
  updateAdminAsync
} from '../../redux/slices/adminsSlice';
import { CreateAdminBody } from '../../resources/adminsTypes';
import { selectTabs } from '../../redux/slices/tabsSlice';
import { selectAuth } from '../../redux/slices/authSlice';

const initialState: CreateAdminBody = {
  login: '',
  cities: [],
  password: '',
  tabs: []
};

const AdminsDialog = (
  props: DialogProps & { onClose: () => void; adminId?: string }
) => {
  const { open, onClose, adminId } = props;

  const dispatch = useDispatch();

  const citiesStore = useSelector(selectCities);
  const adminInfo = useSelector(selectAdmins);
  const auth = useSelector(selectAuth);

  const isSuperAdmin = useMemo(() => {
    return auth.info?.type === 'superAdmin';
  }, [auth.info?.type]);

  const cities = useMemo(
    () => (isSuperAdmin ? citiesStore.items : adminInfo.info?.cities || []),
    [isSuperAdmin, citiesStore.items, adminInfo.info]
  );
  const tabs = useSelector(selectTabs);
  console.log('-=-=-=- tabs', tabs);

  const [admin, setAdmin] = useState(initialState);

  useEffect(() => {
    if (adminId && open) {
      const getCity = async () => {
        const response: any = await dispatch(getAdminAsync(adminId));
        setAdmin({
          login: response.admin.login,
          cities: response.admin.cities?.map((city: any) => city._id) || [],
          password: response.admin.password,
          tabs: response.admin.tabs.map((tab: any) => tab._id)
        });
      };

      getCity();
    } else {
      setAdmin(initialState);
    }
  }, [adminId, dispatch, open]);

  const bind = useBind(admin, setAdmin);

  const handleSave = useCallback(async () => {
    try {
      if (adminId) {
        await dispatch(updateAdminAsync(adminId, admin));
      } else {
        await dispatch(createAdminAsync(admin));
      }

      dispatch(getAdminsAsync());
      onClose();
    } catch (e) {
      if (e.response) {
        alert(e.response.data.message);
      }
    }
  }, [admin, adminId, dispatch, onClose]);

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={onClose}>
      <DialogTitle>
        {adminId ? 'Редагування адміністратора' : 'Створення адміністратора'}
      </DialogTitle>
      <DialogContent>
        {!adminId && (
          <TextField
            {...bind('login')}
            fullWidth
            autoComplete='new-password'
            label='Логін'
            margin='normal'
            variant='outlined'
          />
        )}
        <TextField
          {...bind('password')}
          fullWidth
          autoComplete='new-password'
          label='Пароль'
          margin='normal'
          type='password'
          variant='outlined'
        />
        <TextField
          {...bind('cities')}
          fullWidth
          select
          label='Міста'
          margin='normal'
          SelectProps={{
            multiple: true
          }}
          variant='outlined'
        >
          {cities.map(city => (
            <MenuItem key={city._id} value={city._id}>
              {city.cityName}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          {...bind('tabs')}
          fullWidth
          select
          label='Таби'
          margin='normal'
          SelectProps={{
            multiple: true
          }}
          variant='outlined'
        >
          {tabs.items.map(tab => (
            <MenuItem key={tab._id} value={tab._id}>
              {tab.name}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={handleSave}>
          Зберегти
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdminsDialog;
