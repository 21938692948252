import axios from './axios';
import { handleDefaultErrors } from './functions';
import { ZoneType } from './types/zonesTypes';

const uploadFiles = async (
  file: File,
  success?: (fileName: string) => void
) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await axios.post('/files', formData);
    if (success) {
      success(response.data.file);
    }
    return response.data.file;
  } catch (error) {
    handleDefaultErrors(error);
  }
};

export const uploadZoneTypeFile = async (
  type: ZoneType,
  city: string | undefined,
  file: File,
  success?: (fileName: string) => void,
  onError?: () => void
) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await axios.put(
      `/zones/file_by_type?type=${type}&city=${city}`,
      formData
    );
    if (success) {
      success(response.data);
    }
    return response.data;
  } catch (error) {
    onError?.();
    handleDefaultErrors(error);
  }
};

export default uploadFiles;
