import React from "react";

interface Props {
    className?: string;
    title?: string;
    count?: string;
    color: string;
    onPress?: () => void
}

const ScooterCounItem = ({ className, title, count, color, onPress }: Props) => {
    return (
        <div className={className} style={{ borderColor: "#848383", borderWidth: 2}} onClick={() => onPress?.()}>
            <span style={{ color: "#848383", height: '35%' }}></span>
            <span style={{ color }}>{count}</span>
            <span style={{ color }}>{title}</span>
        </div>
    )
}

export default ScooterCounItem;
