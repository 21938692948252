import { Stack } from '@mui/material';
import { defaultCenter } from '../../pages/ZonesPage/constants';
import { MapLatLng } from '../../pages/ZonesPage/interface';
import { GoogleMap, Marker, Polyline } from '@react-google-maps/api';
import { darkMapStyles } from '../../resources/constants';
import { useEffect, useState } from 'react';
import { getContractСoordsHistoryAsync } from '../../redux/slices/contractsSlice';
import { ApiResponse } from './interface';

import trip_marker from './../../img/trip_marker.png';

interface Props {
  mapCenter?: MapLatLng;
  tripId?: string;
}

export const TripCoordinatesHistory = ({
  mapCenter = defaultCenter,
  tripId
}: Props) => {
  const [path, setPath] = useState<MapLatLng[]>([]);
  const [startMarker, setStartMarker] = useState<MapLatLng | null>();
  const [endMarker, setEndMarker] = useState<MapLatLng | null>();

  const loadCoordinates = (id: string) => {
    getContractСoordsHistoryAsync(id).then((res: ApiResponse) => {
      console.log('-=-=- getContractСoordsHistoryAsync', res);
      if (res.contract.coordsHistory) {
        const polyLinePath: MapLatLng[] = res.contract.coordsHistory
          .sort((a, b) => new Date(a.ts).getTime() - new Date(b.ts).getTime())
          .map(coord => ({
            lat: coord.lat,
            lng: coord.lon
          }));
        setPath(polyLinePath);
      }

      if (res.contract.startCoords?.lat && res.contract.startCoords?.lon) {
        setStartMarker({
          lat: res.contract.startCoords?.lat,
          lng: res.contract.startCoords?.lon
        });
      }

      if (res.contract.exitCoords?.lat && res.contract.exitCoords?.lon) {
        setEndMarker({
          lat: res.contract.exitCoords?.lat,
          lng: res.contract.exitCoords?.lon
        });
      }
    });
  };

  useEffect(() => {
    if (tripId) {
      loadCoordinates(tripId);
    }
  }, [tripId]);

  return (
    <Stack direction='row' alignItems='center' spacing={2}>
      <Stack width={'100%'}>
        <GoogleMap
          center={mapCenter}
          mapContainerStyle={{
            width: '100%',
            height: 500,
            marginTop: 20
          }}
          options={{
            disableDefaultUI: true,
            zoomControl: true,
            fullscreenControl: true,
            styles: darkMapStyles
          }}
          zoom={11}
        >
          {startMarker ? (
            <Marker
              position={startMarker}
              icon={{
                url: trip_marker,
                labelOrigin: new window.google.maps.Point(16, 15)
              }}
              label={{
                text: 'A',
                fontSize: '13px'
              }}
              zIndex={10}
            />
          ) : null}

          {endMarker ? (
            <Marker
              position={endMarker}
              icon={{
                url: trip_marker,
                labelOrigin: new window.google.maps.Point(16, 15)
              }}
              label={{
                text: 'B',
                fontSize: '13px'
              }}
            />
          ) : null}

          <Polyline
            path={path}
            visible
            options={{
              strokeWeight: 1,
              strokeColor: 'red'
            }}
          />
        </GoogleMap>
      </Stack>
    </Stack>
  );
};
