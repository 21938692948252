import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  TextField
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  getUserAsync,
  selectUsers,
  clearUser,
  updateUser,
  updateUserAsync
} from '../../redux/slices/usersSlice';
import useAsync from '../../hooks/useAsync';
import UserTypeSelect from '../../containers/UserTypeSelect';
import { User } from '../../resources/types/usersTypes';
import { InputChangeEvent } from '../../resources/utils';
import MaskedInput from '../../containers/MaskedInput';
import { formatDate } from '../../resources/functions';
import UserBalance from '../../containers/UserBalance';
import { RootState } from '../../redux/store';

const PREFIX = 'UsersDialog';

const classes = {
  dialogContent: `${PREFIX}-dialogContent`,
  spinner: `${PREFIX}-spinner`,
  inputGroup: `${PREFIX}-inputGroup`
};

const StyledDialog = styled(Dialog)({
  [`& .${classes.dialogContent}`]: {
    position: 'relative'
  },
  [`& .${classes.spinner}`]: {
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    background:
      'linear-gradient(180deg, rgba(255,255,255,0.05) 0%, rgba(0,0,0,0.10) 50%, rgba(255,255,255,0.05) 100%)',
    zIndex: 1
  },
  [`& .${classes.inputGroup}`]: {
    display: 'flex',
    '& > div + div': {
      marginLeft: 10
    }
  }
});

interface Props extends DialogProps {
  userId: string | null;
  onClose: () => void;
  onSave: () => void;
}

const UsersDialog = ({ open, onClose, userId, onSave }: Props) => {
  const { userById } = useSelector(selectUsers);
  const dispatch = useDispatch();

  const isSuperAdmin = useSelector(
    (state: RootState) => state.auth.info?.type === 'superAdmin'
  );

  const getUser = useCallback(async () => {
    if (userId) {
      await dispatch(getUserAsync(userId));
    }
  }, [dispatch, userId]);

  const { execute, pending } = useAsync(getUser);

  useEffect(() => {
    if (open) {
      execute();
    } else {
      dispatch(clearUser());
    }
  }, [dispatch, execute, open]);

  const [birthday, setBirthday] = useState('');
  useEffect(() => {
    setBirthday(formatDate(userById.birthday));
  }, [userById.birthday]);

  const changeBirthday = (e: InputChangeEvent) => {
    setBirthday(e.target.value);
  };

  const handleChange = useCallback(
    (name: keyof User) => (e: InputChangeEvent) => {
      dispatch(updateUser({ [name]: e.target.value }));
    },
    [dispatch]
  );

  const handleSubmit = () => {
    if (userId) {
      const body = {
        ...userById,
        birthday: birthday
          ? moment(birthday, 'DD.MM.YYYY').format('YYYY-MM-DD')
          : ''
      };

      dispatch(
        updateUserAsync(userId, body, () => {
          onSave();
          onClose();
        })
      );
    }
  };

  // const onBlockUser = () => {
  //   // eslint-disable-next-line no-restricted-globals
  //   const confirmation = confirm("Ви впевнені що хочете забанити користувача?");
  //     if (confirmation) {
  //       // dispatch(deleteCityAsync(cityId));
  //     }
  // }

  // const onUnBlockUser = () => {
  //   // eslint-disable-next-line no-restricted-globals
  //   const confirmation = confirm("Ви впевнені що хочете завершити всі сеанси користувача?");
  //     if (confirmation) {
  //       // dispatch(deleteCityAsync(cityId));
  //     }
  // }

  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogTitle>Користувач</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {pending && (
          <div className={classes.spinner}>
            <CircularProgress color='primary' />
          </div>
        )}
        <div className={classes.inputGroup}>
          <TextField
            label='Прізвище'
            value={userById.lastName}
            onChange={handleChange('lastName')}
          />
          <TextField
            label="Ім'я"
            value={userById.firstName}
            onChange={handleChange('firstName')}
          />
          <TextField
            label='По-батькові'
            value={userById.middleName}
            onChange={handleChange('middleName')}
          />
        </div>
        <UserTypeSelect value={userById.type} onChange={handleChange('type')} />
        {userId && (
          <UserBalance
            fullWidth
            balance={userById.balance}
            margin='normal'
            userId={userId}
            onSave={onSave}
          />
        )}
        <TextField
          fullWidth
          label='Пошта'
          margin='normal'
          value={userById.email}
          onChange={handleChange('email')}
        />
        <TextField
          fullWidth
          inputProps={{ readOnly: true }}
          label='Телефон'
          margin='normal'
          value={userById.phone}
        />
        <MaskedInput
          fullWidth
          shrinkLabel
          label='Дата нарождення'
          margin='normal'
          maskProps={{
            mask: '99.99.9999'
          }}
          placeholder='ДД.ММ.ГГГГ'
          value={birthday}
          onChange={changeBirthday}
        />
      </DialogContent>
      <DialogActions>
        {/* {isSuperAdmin ? (
          <Grid
            item
            marginRight={'auto'}
            flexDirection={'row'}
            display={'flex'}
          >
            <Grid marginX={'5px'}>
              <Button color='warning' variant='contained' onClick={() => onCloseSessions()}>
                Завершити сеанси
              </Button>
            </Grid>
            <Grid marginX={'5px'}>
              <Button color='error' variant='contained' onClick={() => onBlockUser()}>
                Заблокувати
              </Button>
            </Grid>
          </Grid>
        ) : null} */}

        <Grid
          item
          marginLeft={'auto'}
          flexDirection={isSuperAdmin ? 'row' : 'row-reverse'}
          display={'flex'}
        >
          <Grid marginX={'5px'}>
            <Button color='primary' variant='contained' onClick={handleSubmit}>
              Зберегти
            </Button>
          </Grid>
          <Grid marginX={'5px'}>
            <Button onClick={onClose}>Вийти</Button>
          </Grid>
        </Grid>
      </DialogActions>
    </StyledDialog>
  );
};

export default UsersDialog;
