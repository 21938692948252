import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Title from '../../components/Title';
import { useDispatch, useSelector } from 'react-redux';
import {
  getNewsOnceAsync,
  selectNews,
  updateNewsAsync
} from '../../redux/slices/newsSlice';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import TableCell from '../../components/TableCell';
import ExpandableTableRow from '../../components/ExpandableTableRow';
import {
  Delete,
  KeyboardArrowDown,
  KeyboardArrowUp
} from '@mui/icons-material';
import Input from '../../components/Input';
import { NewsState } from './interface';
import { NewsItem } from '../../resources/types/newsTypes';
import deepCopy from 'deep-copy';

const PREFIX = 'NewsPage';

const classes = {
  newsItem: `${PREFIX}-newsItem`,
  newsItemDelete: `${PREFIX}-newsItemDelete`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.newsItem}`]: {
    position: 'relative',
    '& + &': {
      marginTop: 20,
      paddingTop: 20,
      borderTop: `1px solid ${theme.palette.divider}`
    }
  },

  [`& .${classes.newsItemDelete}`]: {
    position: 'absolute',
    right: 0,
    top: 0
  }
}));

const NewsPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNewsOnceAsync());
  }, [dispatch]);

  const news = useSelector(selectNews);

  const [currentNews, setCurrentNews] = useState<NewsState>({
    items: [],
    timeScooter: '',
    priceScooter: ''
  });

  useEffect(() => {
    if (news.once) {
      setCurrentNews({
        ...news.once,
        items: [...news.once.items, { context: '', title: '' }]
      } as NewsState);
    }
  }, [news.once]);

  const createChangeNewsHandler = useCallback(
    (property: keyof Omit<NewsState, 'items'>) => (event: any) => {
      event.persist();
      setCurrentNews(prevState => {
        prevState[property] = event.target.value;
        return { ...prevState };
      });
    },
    []
  );

  const createChangeNewsItemHandler = useCallback(
    (property: keyof NewsItem, index: number) => (event: any) => {
      event.persist();
      setCurrentNews(prevState => {
        const nextState = deepCopy(prevState);

        nextState.items[index][property] = event.target.value;

        if (index === nextState.items.length - 1) {
          nextState.items.push({ title: '', context: '' });
        }

        return nextState;
      });
    },
    []
  );

  const createDeleteNewsItemHandler = useCallback(
    (index: number) => () => {
      setCurrentNews(prevState => {
        const nextState = deepCopy(prevState);
        nextState.items.splice(index, 1);
        return nextState;
      });
    },
    []
  );

  const saveNews = useCallback(() => {
    dispatch(
      updateNewsAsync({
        ...currentNews,
        items: currentNews.items.filter(item => item.title || item.context)
      })
    );
  }, [currentNews, dispatch]);

  return (
    <Root>
      <Title style={{ marginBottom: 20 }}>Новини</Title>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Перший рядок</TableCell>
              <TableCell>Другий рядок</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <ExpandableTableRow
              colSpan={3}
              renderRow={rowProps => {
                const { expanded, toggleExpand } = rowProps;
                return (
                  <TableRow>
                    <TableCell>
                      <IconButton size='small' onClick={toggleExpand}>
                        {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <Input
                        onBlur={saveNews}
                        value={currentNews.priceScooter}
                        onChange={createChangeNewsHandler('priceScooter')}
                      />
                    </TableCell>
                    <TableCell>
                      <Input
                        onBlur={saveNews}
                        value={currentNews.timeScooter}
                        onChange={createChangeNewsHandler('timeScooter')}
                      />
                    </TableCell>
                  </TableRow>
                );
              }}
              renderExpandableRow={() => {
                return (
                  <Box margin={2}>
                    <Typography
                      variant='h6'
                      component='div'
                      style={{ marginBottom: 16 }}
                    >
                      Блоки
                    </Typography>
                    {currentNews.items.map((item, index) => {
                      return (
                        <div className={classes.newsItem} key={index}>
                          {currentNews.items.length !== 1 && (
                            <IconButton
                              color='secondary'
                              className={classes.newsItemDelete}
                              onClick={createDeleteNewsItemHandler(index)}
                              size='large'
                            >
                              <Delete />
                            </IconButton>
                          )}
                          <Input
                            onBlur={saveNews}
                            InputLabelProps={{ shrink: true }}
                            label='Заголовок'
                            style={{ marginBottom: 16 }}
                            value={item.title}
                            onChange={createChangeNewsItemHandler(
                              'title',
                              index
                            )}
                          />
                          <Input
                            onBlur={saveNews}
                            InputLabelProps={{ shrink: true }}
                            label='Контент'
                            multiline
                            fullWidth
                            value={item.context}
                            onChange={createChangeNewsItemHandler(
                              'context',
                              index
                            )}
                          />
                        </div>
                      );
                    })}
                  </Box>
                );
              }}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Root>
  );
};

export default NewsPage;
