import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Title from '../../../components/Title';
import { formatDate } from '../../../resources/functions';
import TableCell from '../../../components/TableCell';
import {
  IPromoCodeType,
  Promocode
} from '../../../resources/types/balanceTypes';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../../redux/slices/authSlice';

const PREFIX = 'PromocodesPage';

const classes = {
  header: `${PREFIX}-header`,
  addButton: `${PREFIX}-addButton`,
  tableContainer: `${PREFIX}-tableContainer`
};

interface IPromoCodesListWrapperprops {
  title: string;
  createButtonTitle: string;
  onCreate: () => void;
  onEdit: (promocode: Promocode) => void;
  promocodes: Promocode[];
  includeReferal: boolean;
  setIncludeReferal: (includeReferal: boolean) => void;
}

const PromoCodesListWrapper = ({
  onCreate,
  onEdit,
  promocodes,
  title,
  createButtonTitle,
  includeReferal,
  setIncludeReferal
}: IPromoCodesListWrapperprops) => {
  const auth = useSelector(selectAuth);
  const isSuperAdmin = auth.info?.type === 'superAdmin';

  return (
    <>
      <div className={classes.header}>
        <Title>{title}</Title>
        <Button
          className={classes.addButton}
          color='primary'
          startIcon={<AddIcon />}
          variant='text'
          onClick={() => onCreate()}
        >
          {createButtonTitle}
        </Button>

        <Grid item marginLeft={'auto'}>
          <FormControlLabel
            control={
              <Checkbox
                checked={includeReferal ?? false}
                color='primary'
                onChange={(event, checked) => setIncludeReferal(checked)}
              />
            }
            label='Додати реферальні коди'
          />
        </Grid>
      </div>
      {promocodes.length ? (
        <div>
          <TableContainer className={classes.tableContainer} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Код</TableCell>
                  <TableCell>Опис</TableCell>
                  <TableCell>Знижка</TableCell>
                  <TableCell>Кількість використань</TableCell>
                  <TableCell>Тип промокоду</TableCell>
                  <TableCell>Дійсний до</TableCell>
                  <TableCell>Дата створення</TableCell>
                  <TableCell>Дата оновлення</TableCell>
                  <TableCell>Активний</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {promocodes.map(promocode => (
                  <TableRow
                    hover
                    key={promocode._id}
                    onClick={() => {
                      if (
                        promocode.type === IPromoCodeType.addBalance &&
                        isSuperAdmin
                      ) {
                        onEdit(promocode);
                      }
                    }}
                  >
                    <TableCell>{promocode.code}</TableCell>
                    <TableCell>{promocode.description}</TableCell>
                    <TableCell>{promocode.amount}</TableCell>
                    <TableCell>
                      {promocode.type === IPromoCodeType.addBalance
                        ? `${promocode.usedTimes || 0} / ${
                            promocode.usingLimit || 0
                          }`
                        : promocode.usedTimes}
                    </TableCell>
                    <TableCell>{promocode.type}</TableCell>
                    <TableCell>
                      {promocode.expiresAt
                        ? moment(promocode.expiresAt).format('DD.MM.YYYY')
                        : ''}
                    </TableCell>
                    {/* <TableCell>{promocode.salePercent}</TableCell>
                    <TableCell>{promocode.contractStatus}</TableCell> */}
                    <TableCell>{formatDate(promocode.createdAt)}</TableCell>
                    <TableCell>{formatDate(promocode.updatedAt)}</TableCell>
                    <TableCell>
                      <Checkbox
                        disableRipple
                        readOnly
                        checked={promocode.active}
                        color='primary'
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : null}
    </>
  );
};

export default PromoCodesListWrapper;
