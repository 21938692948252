import { TableCell as MuiTableCell, tableCellClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  }
}));

export default TableCell;
