import React, { useMemo, useState } from 'react';
import { Props, RenderRowProps } from './inderface';
import { Collapse, TableRow } from '@mui/material';
import TableCell from '../TableCell';

const ExpandableTableRow = (props: Props) => {
  const { renderRow, renderExpandableRow, colSpan } = props;
  const [expanded, setExpanded] = useState(false);

  const renderRowProps = useMemo<RenderRowProps>(() => {
    return {
      expanded,
      toggleExpand: () => {
        setExpanded(prevState => !prevState);
      }
    };
  }, [expanded]);

  return (
    <React.Fragment>
      {renderRow(renderRowProps)}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={colSpan}>
          <Collapse in={expanded} timeout='auto' unmountOnExit>
            {renderExpandableRow()}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default ExpandableTableRow;
