import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSound from 'use-sound';
import { setNeedPlayAlert, setNeedPlayStolenAlert } from '../../redux/slices/noticesSlice';
import { RootState } from '../../redux/store';

const AudioAlert = () => {
  const dispatch = useDispatch();
  const needPlayAlert = useSelector(
    ({ notices }: RootState) => notices.needPlayAlert
  );

  const needPlayStolenAlert = useSelector(
    ({ notices }: RootState) => notices.needPlayStolenAlert
  );

  const [playAlert] = useSound('/notification.wav');
  const [playStolenAlert, exposedData] = useSound('/notificationStolen.wav');

  useEffect(() => {
    if (needPlayAlert) {
      playAlert();
      dispatch(setNeedPlayAlert(false));
    }
  }, [dispatch, needPlayAlert, playAlert]);

  useEffect(() => {
    if (needPlayStolenAlert) {
      playStolenAlert();
      dispatch(setNeedPlayStolenAlert(false));
      setTimeout(() => exposedData.stop(), 1600);
    }
  }, [dispatch, exposedData, needPlayStolenAlert, playStolenAlert]);

  return null;
};

export default AudioAlert;
