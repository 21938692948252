import { useState } from 'react';
import { InputChangeEvent } from '../resources/utils';

const useFormInput = (initValue: string) => {
  const [value, setValue] = useState(initValue);

  const onChange = (e: InputChangeEvent) => {
    setValue(e.target.value);
  };

  return {
    value,
    onChange
  };
};

export default useFormInput;
