import React from 'react';
import { Switch, Route } from 'react-router-dom';
import useRoutes from './useRoutes';

const Router = () => {
  const routes = useRoutes();

  const nestedRoutes = routes
    .filter(route => route.nested)
    .map(route => route.nested)
    .flat();

  return (
    <Switch>
      {nestedRoutes.map(route => {
        return (
          <Route
            component={route!.component}
            key={route!.path}
            path={route!.path}
          />
        );
      })}
      {routes.map(route => {
        return (
          <Route
            component={route.component}
            key={route.path}
            path={route.path}
          />
        );
      })}
    </Switch>
  );
};

export default Router;
