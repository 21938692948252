import { selectAuth } from './../redux/slices/authSlice';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectAdmins } from '../redux/slices/adminsSlice';

const usePermissions = () => {
  const { info } = useSelector(selectAdmins);
  const auth = useSelector(selectAuth);

  return React.useCallback(
    (code: string) =>
      auth.info?.type === 'superAdmin' ||
      info?.tabs.findIndex(tab => tab.code === code) !== -1,
    [auth.info, info]
  );
};

export default usePermissions;
