import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { Pagination } from '@mui/material';
import {
  getUserHistoryAsync,
  clearUserHistory,
  selectBalance
} from '../../redux/slices/balanceSlice';
import { Props } from './interface';
import { formatDate } from '../../resources/functions';
import TableCell from '../../components/TableCell';

const limit = 10;

const UserHistory = ({ userId, open, onClose }: Props) => {
  const dispatch = useDispatch();

  const { history } = useSelector(selectBalance);

  const [page, setPage] = useState(1);

  const [pending, setPending] = useState(false);

  useEffect(() => {
    if (userId) {
      (async () => {
        setPending(true);
        await dispatch(
          getUserHistoryAsync({ user_id: userId, limit, page, active: false })
        );
        setPending(false);
      })();
    }
  }, [dispatch, userId, page]);

  useEffect(() => {
    if (!open) {
      dispatch(clearUserHistory());
    }
  }, [dispatch, open]);

  const paginationCount = useMemo(
    () => Math.ceil(history.count / limit),
    [history.count]
  );

  const changePage = useCallback(
    (event: any, nextPage: number) => setPage(nextPage),
    []
  );

  return (
    <Dialog fullWidth maxWidth='md' open={open} scroll='body' onClose={onClose}>
      <DialogTitle>Історія користувача</DialogTitle>
      <DialogContent>
        {history.items.length ? (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Тип</TableCell>
                    <TableCell>Сума</TableCell>
                    <TableCell>Дата</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {history.items.map(item => (
                    <TableRow key={item.createdAt}>
                      <TableCell>{item.type}</TableCell>
                      <TableCell>{item.amount}</TableCell>
                      <TableCell>
                        {formatDate(item.createdAt, { time: true })}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              count={paginationCount}
              style={{ marginTop: 10 }}
              onChange={changePage}
            />
          </>
        ) : (
          !pending && (
            <Typography color='textSecondary' variant='h5'>
              Історія транзакцій порожня
            </Typography>
          )
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Закрити</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserHistory;
