import { GoogleMap, Polygon } from '@react-google-maps/api';
import {
  Button,
  Grid,
  Paper,
  TextField,
  MenuItem} from '@mui/material';
import {
  Delete
} from '@mui/icons-material';

import usePermissions from '../../../hooks/usePermissions';
import { classes, defaultCenter, zonesColors } from '../../../pages/ZonesPage/constants';
import Collapse from '../../Collapse';
import { MapLatLng } from '../../../pages/ZonesPage/interface';
import { useDispatch, useSelector } from 'react-redux';
import { createZoneMarkerAsync, deleteZoneMarkerAsync, getZoneMarkersAsync, selectZones, updateZoneMarkerAsync } from '../../../redux/slices/zonesSlice';
import UserMapMarker from '../../../containers/UserMapMarker';
import { useEffect, useMemo, useState } from 'react';
import ZoneMapMarker from './ZoneMapMarker';
import { ZoneMarker, ZoneMarkerType } from '../../../resources/types/zonesTypes';

interface Props { 
  mapCenter?: MapLatLng
  currentCityId?: string;
}

const zoneMarkerTypes = [
  {
    name: 'Заборонено паркування',
    value: 'no-parking'
  },
  {
    name: 'Дозволено паркування',
    value: 'parking'
  },
  {
    name: 'Кешбек',
    value: 'cashback'
  },
  {
    name: 'Штраф',
    value: 'payzone'
  },
  {
    name: 'Повільно',
    value: 'slow'
  },
  {
    name: 'Стоп',
    value: 'stop'
  },
];

const defaultZoneMarkerType = 'no-parking';

const MapMarkersSection = ({ mapCenter = defaultCenter, currentCityId }: Props) => {
  // const mapRef 
  const dispatch = useDispatch();
  const checkPermission = usePermissions();
  const { zones, zoneMarkers } = useSelector(selectZones);

  const [type, setType] = useState<ZoneMarkerType>(defaultZoneMarkerType);
  const [selectedMarker, setSelectedMarker] = useState<ZoneMarker | null>(null);
  const [selectedMarkerCoords, setSelectedMarkerCoords] = useState<MapLatLng | null>(null);
  const [createMarker, setCreateMarker] = useState<MapLatLng | null>(null);

  const isEditMarker = useMemo(() => Boolean(selectedMarker), [selectedMarker]);
  useEffect(() => {
    if (isEditMarker && selectedMarker) {
      setType(selectedMarker?.type ?? defaultZoneMarkerType);
      setSelectedMarkerCoords({ lat: selectedMarker?.coordinates[0], lng: selectedMarker?.coordinates[1]});
    }
  }, [isEditMarker, selectedMarker])

  useEffect(() => {
    dispatch(getZoneMarkersAsync());
  }, []);

  const handleCreateMarker = () => {
    if (createMarker) {
      dispatch(createZoneMarkerAsync({ cityId: currentCityId ,type, coordinates: [createMarker?.lat, createMarker?.lng] }))
      setCreateMarker(null);
      setSelectedMarker(null);
    }
  }

  const handleUpdateMarker = () => {
    if (selectedMarker?._id && selectedMarkerCoords) {
      dispatch(updateZoneMarkerAsync(selectedMarker?._id, { cityId: currentCityId, type, coordinates: [selectedMarkerCoords?.lat, selectedMarkerCoords?.lng] }))
      setCreateMarker(null);
      setSelectedMarker(null);
    }
  }

  const handleDeleteMarker = () => {
    if (selectedMarker?._id) {
      dispatch(deleteZoneMarkerAsync(selectedMarker?._id));
      setCreateMarker(null);
      setSelectedMarker(null);
    }
  }

  return (
      <>
        {checkPermission('map') && (
          <Paper className={classes.blockContainer}>
            <Collapse
              classes={{
                labelRoot: classes.collapseLabel
              }}
              label='Маркери на карті'
            >
              {
                createMarker || isEditMarker ? (
                  <Grid item padding={"10px"}>
                    <TextField
                      select
                      className={classes.typeSelector}
                      label='Тип'
                      value={type}
                      onChange={e => setType(e.target.value as ZoneMarkerType)}
                    >
                      {zoneMarkerTypes.map(zoneMarkerType => (
                        <MenuItem key={zoneMarkerType.value} value={zoneMarkerType.value}>
                          {zoneMarkerType.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Button
                      className={classes.saveButton}
                      color='primary'
                      variant='contained'
                      onClick={() => isEditMarker ? handleUpdateMarker() : handleCreateMarker()}
                    >
                      {isEditMarker ? "Оновити" : "Створити"}
                    </Button>
                    {
                      isEditMarker ? (
                        <>
                          <Button
                            className={classes.saveButton}
                            color='error'
                            variant='contained'
                            onClick={() => handleDeleteMarker()}
                          >
                            <Delete />
                          </Button>
                        </>
                      ) : null
                    }
                    <Button
                      className={classes.saveButton}
                      color='info'
                      variant='contained'
                      onClick={() => {
                        setCreateMarker(null);
                        setSelectedMarker(null);
                      }}
                    >
                      Скасувати
                    </Button>
                  </Grid>
                ) : null
              }
              <GoogleMap
                center={mapCenter}
                mapContainerStyle={{
                  width: '100%',
                  height: 500,
                  marginTop: 20
                }}
                options={{
                  disableDefaultUI: true,
                  zoomControl: true,
                  fullscreenControl: true
                }}
                zoom={11}
                onZoomChanged={() => {

                }}
              >
                <UserMapMarker />
                {
                  zoneMarkers && zoneMarkers?.map((marker) => (
                    <ZoneMapMarker 
                      key={marker._id} 
                      marker={marker} 
                      isEdit={selectedMarker?._id === marker._id}
                      updatedCoords={selectedMarkerCoords}
                      updateType={type}
                      onSelect={() => {
                        setSelectedMarker(marker);
                        setCreateMarker(null);
                      }} 
                    />
                  ))
                }
                {zones && zones?.map(zone => (
                  <Polygon
                    key={zone._id}
                    options={zonesColors[zone.type] || zonesColors.green}
                    paths={zone.coordinates.map(coordinate => ({
                      lat: coordinate[0],
                      lng: coordinate[1]
                    }))}
                    onDblClick={(e) => {
                      if (createMarker === null) {
                        e.stop();
                        console.log('-=-=- Polygon onDblClick', {lat: e.latLng.lat(), lng: e.latLng.lng()})
                        setCreateMarker({ lat: e.latLng.lat(), lng: e.latLng.lng()})
                      }
                    }}
                    onClick={(e) => {
                      if (createMarker !== null || selectedMarker !== null) {
                        e.stop();
                        console.log('-=-=- Polygon onClick', {lat: e.latLng.lat(), lng: e.latLng.lng()})
                        if (selectedMarker !== null) {
                          setSelectedMarkerCoords({ lat: e.latLng.lat(), lng: e.latLng.lng()})
                        } else {
                          setCreateMarker({ lat: e.latLng.lat(), lng: e.latLng.lng()})
                        }
                      }
                    }}
                  />
                ))}
                {createMarker !== null ? (<ZoneMapMarker marker={{ coordinates: [createMarker.lat, createMarker.lng]}}  />) : null}
              </GoogleMap>
            </Collapse>
          </Paper>
        )}
      </>
  )
}

export default MapMarkersSection;
