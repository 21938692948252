import React from 'react';
import { Button, Paper, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { signInAsync } from '../../redux/slices/authSlice';
import useFormInput from '../../hooks/useFormInput';
import Logo from '../../components/Logo';

const PageContainer = styled('div')({
  width: '100vw',
  minHeight: '100vh',
  padding: '0 20px',
  background: 'linear-gradient(180deg, #3ae783 0%, #0e734b 100%)',
  textAlign: 'center'
});

const FormContainer = styled(Paper)({
  maxWidth: 400,
  padding: '24px 16px',
  marginTop: 100,
  display: 'inline-block'
});

const SubmitButton = styled(Button)({
  marginTop: 20
});

const SignInPage = () => {
  const dispatch = useDispatch();
  const login = useFormInput('');
  const password = useFormInput('');

  return (
    <PageContainer>
      <FormContainer>
        <Logo />
        <form
          onSubmit={event => {
            event.preventDefault();
            dispatch(
              signInAsync({ login: login.value, password: password.value })
            );
          }}
        >
          <TextField
            {...login}
            fullWidth
            label='Логин'
            placeholder='Введите логин'
          />
          <TextField
            {...password}
            fullWidth
            label='Пароль'
            margin='normal'
            placeholder='Введите пароль'
            type='password'
          />
          <SubmitButton
            fullWidth
            color='primary'
            type='submit'
            variant='contained'
          >
            Авторизоваться
          </SubmitButton>
        </form>
      </FormContainer>
    </PageContainer>
  );
};

export default SignInPage;
