import { createSlice } from '@reduxjs/toolkit';
import {
  AddScooterNoteParams,
  GetScooterNotesParams,
  GetScootersParams,
  Scooter,
  UpdateScooterInfoBody
} from '../../resources/types/scootersTypes';
import { AppThunk, RootState } from '../store';
import axios from '../../resources/axios';
import { handleDefaultErrors } from '../../resources/functions';

export interface ScootersState {
  items: Scooter[];
  total: number;
  free: number;
  onContract: number;
  transporting: number;
  charging: number;
  maintenance: number;
  offline: number;
  unlocked: number;
  lowBattery: number;
}

const initialState: ScootersState = {
  items: [],
  total: 0,
  free: 0,
  onContract: 0,
  transporting: 0,
  charging: 0,
  maintenance: 0,
  offline: 0,
  unlocked: 0,
  lowBattery: 0
};

export const scootersSlice = createSlice({
  name: 'scooters',
  initialState,
  reducers: {
    getScooters: (state, action) => {
      console.log('-=-=- getScooters', action.payload);
      state.items = action.payload.scooters;
      if (action?.payload?.counters?.total) {
        state.total = action?.payload?.counters?.total ?? 0;
      }

      if (action?.payload?.counters?.free) {
        state.free = action?.payload?.counters?.free ?? 0;
      }

      if (action?.payload?.counters?.onContract) {
        state.onContract = action?.payload?.counters?.onContract ?? 0;
      }

      if (action?.payload?.counters?.transporting) {
        state.transporting = action?.payload?.counters?.transporting ?? 0;
      }

      if (action?.payload?.counters?.charging) {
        state.charging = action?.payload?.counters?.charging ?? 0;
      }

      if (action?.payload?.counters?.maintenance) {
        state.maintenance = action?.payload?.counters?.maintenance ?? 0;
      }

      if (action?.payload?.counters?.offline) {
        state.offline = action?.payload?.counters?.offline ?? 0;
      }

      if (action?.payload?.counters?.unlocked) {
        state.unlocked = action?.payload?.counters?.unlocked ?? 0;
      }

      if (action?.payload?.counters?.lowBattery) {
        state.lowBattery = action?.payload?.counters?.lowBattery ?? 0;
      }
    }
  }
});

export const { getScooters } = scootersSlice.actions;

export const selectScooters = (state: RootState) => state.scooters;

export const getScootersAsync =
  (params?: GetScootersParams): AppThunk =>
  async dispatch => {
    try {
      const response = await axios.get('/scooters?version=2', { params });
      dispatch(getScooters(response.data));
    } catch (e) {
      handleDefaultErrors(e);
    }
  };

export const updateScooterInfoAsync =
  (id: string, body: UpdateScooterInfoBody, success?: () => void): AppThunk =>
  async dispatch => {
    try {
      await axios.put(`/scooters/${id}/info`, body);
      dispatch(getScootersAsync());
      if (success) {
        success();
      }
    } catch (e) {
      handleDefaultErrors(e);
    }
  };

export const updateScooterStatusAsync =
  (id: string, body: UpdateScooterInfoBody, success?: () => void): AppThunk =>
  async dispatch => {
    try {
      await axios.put(`/scooters/${id}/status`, body);
      dispatch(getScootersAsync());
      if (success) {
        success();
      }
    } catch (e) {
      handleDefaultErrors(e);
    }
  };

export const lockScooterAsync =
  (id: string, body: { lock: boolean }): AppThunk =>
  async () => {
    try {
      await axios.put(`/scooters/${id}/locking`, body);
    } catch (e) {
      handleDefaultErrors(e);
    }
  };

export const signalScooterAsync =
  (id: string, body: { value: boolean }): AppThunk =>
  async () => {
    try {
      await axios.put(`/scooters/${id}/signal`, body);
    } catch (e) {
      handleDefaultErrors(e);
    }
  };

export const deleteScooterAsync =
  (id: string): AppThunk =>
  async () => {
    try {
      await axios.delete(`/scooters/${id}`);
    } catch (e) {
      handleDefaultErrors(e);
    }
  };

export const formatCDScooterAsync =
  (id: string): AppThunk =>
  async () => {
    try {
      await axios.put(`/scooters/${id}/reset_device`);
    } catch (e) {
      handleDefaultErrors(e);
    }
  };

export const getScooterNotesAsync = async (params?: GetScooterNotesParams) => {
  try {
    const response = await axios.get('/scooters/note', { params });
    return response.data;
  } catch (e) {
    handleDefaultErrors(e);
  }
};

export const addScooterNoteAsync = async (params?: AddScooterNoteParams) => {
  try {
    const response = await axios.post('/scooters/note', params);
    return response.data;
  } catch (e) {
    handleDefaultErrors(e);
  }
};

export default scootersSlice.reducer;
