import { createApi } from '@reduxjs/toolkit/query/react';
import {
  GetScootersLockHistoryParams,
  GetScootersLockHistoryResponse,
  GetScootersLockHistoryResultType,
  ScootersLockHistoryItem
} from '../../resources/types/scootersTypes';
import baseQuery from './baseQuery';

export const scootersApi = createApi({
  reducerPath: 'scootersApi',
  baseQuery,
  endpoints: builder => ({
    getScootersLockHistory: builder.query<
      GetScootersLockHistoryResultType,
      GetScootersLockHistoryParams
    >({
      query: params => ({
        params,
        url: 'scooters/lock_history'
      }),
      transformResponse(response: GetScootersLockHistoryResponse) {
        const locked: ScootersLockHistoryItem[] = [];
        const unlocked: ScootersLockHistoryItem[] = [];

        for (const item of response.lockHistory) {
          if (item.lock) {
            locked.push(item);
          } else {
            unlocked.push(item);
          }
        }

        return {
          locked,
          unlocked,
          pages: response.pagination.totalPages
        };
      }
    })
  })
});

export const { useGetScootersLockHistoryQuery } = scootersApi;
