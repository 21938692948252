import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import axios from '../../resources/axios';
import { handleDefaultErrors } from '../../resources/functions';

interface EditedBy {
  _id: string;
  admin: string;
  adminLogin: string;
  prevText: string;
  updatedAt: string;
}

interface Note {
  _id: string;
  scooter: {
    name: string;
    _id: string;
  };
  admin: string;
  adminLogin: string;
  text: string;
  id: number;
  createdAt: string;
  updatedAt: string;
  editedBy: EditedBy[];
}

interface NotesState {
  items: Note[];
  shouldUpdate: boolean;
  limit: number;
  page: number;
  total: number;
}

const initialState: NotesState = {
  items: [],
  shouldUpdate: true,
  limit: 20,
  page: 1,
  total: 1
};

export const scooterNotesSlice = createSlice({
  name: 'scooterNotes',
  initialState,
  reducers: {
    getNotes: (state, { payload }) => {
      state.items = payload?.notes ?? [];
      state.shouldUpdate = false;
      state.limit = payload?.pagination?.limit ?? 20;
      state.page = payload?.pagination?.page ?? 1;
      state.total = payload?.pagination?.totalPages ?? 1;
    }
  }
});

const { getNotes } = scooterNotesSlice.actions;

export const getScooterNotesAsync =
  (params: {
    page: number;
    limit: number;
    scooterName?: string;
    dateTo?: string;
    dateFrom?: string;
    adminLogin?: string;
  }): AppThunk =>
  async dispatch => {
    try {
      const response = await axios.get('/scooters/note', { params });
      dispatch(
        getNotes({
          notes: response?.data?.data?.notes ?? [],
          pagination: response?.data?.pagination ?? {}
        })
      );
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const selectNotes = (state: RootState) => state.scooterNotes;

export default scooterNotesSlice.reducer;
