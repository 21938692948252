export const styles = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 890,
    p: 3,
    borderRadius: 2.5,
    bgcolor: 'white'
  },
  title: {
    mb: 3,
    fontSize: '1.25rem',
    fontWeight: '500',
    lineHeight: 1.6,
    letterSpacing: '0.0075em'
  },
  tableContainer: {
    maxHeight: 500,
    maxWidth: 845
  },
  tableCell: {
    textAlign: 'center'
  },
  pagination: {
    mt: 3
  },
  exitButtonContainer: {
    alignItems: 'flex-end',
    mt: 3
  }
};
