import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import axios from '../../resources/axios';
import { City, CreateCityBody } from '../../resources/types/citiesTypes';
import { setCurrentCity } from './adminsSlice';

interface CitiesState {
  items: City[];
}

const initialState: CitiesState = {
  items: []
};

const citiesSlice = createSlice({
  name: 'cities',
  initialState,
  reducers: {
    getCities: (state, action) => {
      state.items = action.payload.cities;
    }
  }
});

const { getCities } = citiesSlice.actions;

export const getCitiesAsync = (): AppThunk => async (dispatch, getState) => {
  const response = await axios.get('/cities');
  dispatch(getCities(response.data));

  if (!getState().admins.currentCity && response.data.cities[0]) {
    dispatch(setCurrentCity(response.data.cities[0]._id));
  }
};

export const getCityAsync =
  (cityId: string): AppThunk =>
  async () => {
    const response = await axios.get(`/cities/${cityId}`);
    return response.data;
  };

export const createCityAsync =
  (body: CreateCityBody): AppThunk =>
  async () => {
    await axios.post('/cities', body);
  };

export const updateCityAsync =
  (cityId: string, body: CreateCityBody): AppThunk =>
  async () => {
    await axios.put(`/cities/${cityId}`, body);
  };

export const deleteCityAsync =
  (cityId: string): AppThunk =>
  async () => {
    await axios.delete(`/cities/${cityId}`);
  };

export const getCityLiqPayConfigAsync =
  (cityId: string): AppThunk =>
  async () => {
    const response = await axios.get(`/liqpayconfigs/settings?city=${cityId}`);
    return response.data?.data;
  };

export const setCityLiqPayConfigAsync =
  (data: {city: string, allowStatusWaitAccept: boolean}): AppThunk =>
  async () => {
    const response = await axios.put(`/liqpayconfigs/settings`, data);
    return response.data?.data;
  };

export const selectCities = (state: RootState) => state.cities;

export default citiesSlice.reducer;
