import { Grid, TextField } from '@mui/material';
import { Pagination } from '@mui/material';
import { DateRange } from '@material-ui/pickers';
import { Moment } from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';

import DateRangePicker from '../../components/DateRangePicker';
import ScootersLockHistoryTable from '../../components/ScootersLockHistoryTable';
import Select from '../../components/Select';
import { SelectOption } from '../../components/Select/interface';
import Title from '../../components/Title';
import { useGetAdminsQuery } from '../../redux/api/adminsApi';
import { useGetCitiesQuery } from '../../redux/api/citiesApi';
import { useGetScootersLockHistoryQuery } from '../../redux/api/scootersApi';
import { InputChangeEvent } from '../../resources/utils';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../redux/slices/authSlice';
import { selectAdmins } from '../../redux/slices/adminsSlice';
import { selectCities } from '../../redux/slices/citiesSlice';

const ScootersLockHistoryPage = () => {
  const { data: admins } = useGetAdminsQuery();
  const { data: cities } = useGetCitiesQuery();

  const citiesList = useSelector(selectCities);
  const adminInfo = useSelector(selectAdmins);
  const userCityId = useMemo(() => {
    const city = citiesList.items.find(item => item._id === adminInfo.currentCity);
    if (city) {
      return city._id;
    }
  }, [adminInfo.currentCity, citiesList.items]);

  const [adminId, setAdminId] = useState('');
  const [city, setCity] = useState('');
  const [scooterName, setScooterName] = useState('');
  const [scooterId, setScooterId] = useState('');
  const [dateRange, setDateRange] = useState<DateRange<Moment | null>>([
    null,
    null
  ]);
  const [page, setPage] = useState(1);
  const auth = useSelector(selectAuth);
  const isSuperAdmin = auth.info?.type === 'superAdmin';

  const clearPageWrapper = useCallback(
    <T extends (...args: any[]) => void>(fn: T) => {
      return (...args: Parameters<T>) => {
        fn(...args);
        setPage(1);
      };
    },
    []
  );

  const { data: scootersLockHistory } = useGetScootersLockHistoryQuery({
    page,
    adminId,
    city: isSuperAdmin ? city : userCityId,
    type: 'admin,driver',
    from: dateRange[0]?.toISOString(),
    to: dateRange[1]?.toISOString(),
    scooterName
  });

  const adminsSelectOptions = useMemo<SelectOption[]>(
    () =>
      admins
        ? [
            {
              value: '',
              label: 'Усі'
            },
            ...admins.map(admin => ({
              value: admin._id,
              label: admin.login
            }))
          ]
        : [],
    [admins]
  );

  const citiesSelectOptions = useMemo<SelectOption[]>(
    () =>
      cities
        ? [
            {
              value: '',
              label: 'Усі'
            },
            ...cities.map(city => ({
              value: city._id,
              label: city.cityName
            }))
          ]
        : [],
    [cities]
  );

  const handleScooterName = (e: InputChangeEvent) => {
    setScooterName(e.target.value);
  };

  useEffect(() => {
    if (scootersLockHistory && scooterName) {
      const history = [
        ...scootersLockHistory.locked,
        ...scootersLockHistory.unlocked
      ];

      const scooterId = history.find(
        scooter => scooter.scooterId?.name === scooterName
      )?.scooterId._id;

      if (scooterId) setScooterId(scooterId);
    }

    if (!scooterName) setScooterId('');
  }, [scooterId, scooterName, scootersLockHistory]);

  if (!scootersLockHistory) {
    return null;
  }

  return (
    <Grid container direction='column' spacing={4}>
      <Grid item>
        <Title>Історія блокувань/розблокувань самокатів</Title>
      </Grid>
      <Grid container item spacing={2}>
        {
          adminsSelectOptions?.length ? (
            <Grid item>
            <Select
              style={{ minWidth: 140 }}
              fullWidth
              label='Користувач'
              options={adminsSelectOptions}
              onChange={clearPageWrapper(setAdminId)}
              value={adminId}
            />
          </Grid>
          ) : null
        }
        {
          isSuperAdmin ? (
            <Grid item>
            <Select
              style={{ minWidth: 140 }}
              fullWidth
              label='Місто'
              options={citiesSelectOptions}
              onChange={clearPageWrapper(setCity)}
              value={city}
            />
          </Grid>
          ) : null
        }

        <Grid item>
          <DateRangePicker
            startText='Від'
            endText='До'
            value={dateRange}
            onChange={(date: DateRange<Moment>) => {
              date[1]?.add('23:59', 'hours');
              setDateRange(date);
              setPage(1);
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            label='Назва скутера'
            value={scooterName}
            onChange={handleScooterName}
          />
        </Grid>
      </Grid>
      <Grid item>
        <ScootersLockHistoryTable
          isLocked
          rows={scootersLockHistory.locked}
          isScooterId={Boolean(scooterId)}
        />
      </Grid>
      <Grid item>
        <ScootersLockHistoryTable
          rows={scootersLockHistory.unlocked}
          isScooterId={Boolean(scooterId)}
        />
      </Grid>
      <Grid item>
        <Pagination
          page={page}
          count={scootersLockHistory.pages}
          onChange={(event, page) => setPage(page)}
        />
      </Grid>
    </Grid>
  );
};

export default ScootersLockHistoryPage;
