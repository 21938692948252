import { GoogleMap, Polygon, HeatmapLayer } from '@react-google-maps/api';
import {
  Button,
  Grid,
  Paper
} from '@mui/material';
import usePermissions from '../../../hooks/usePermissions';
import { DateRange } from '@material-ui/pickers';
import { classes, defaultCenter, zonesColors } from '../../../pages/ZonesPage/constants';
import Collapse from '../../Collapse';
import { MapLatLng } from '../../../pages/ZonesPage/interface';
import { useDispatch, useSelector } from 'react-redux';
import { getCityHeatMapAsync, selectZones } from '../../../redux/slices/zonesSlice';
import { useEffect, useState } from 'react';
import { ZoneHeatMap, ZoneHeatMapResponceRow } from '../../../resources/types/zonesTypes';
import { Moment } from 'moment';
import { darkMapStyles } from '../../../resources/constants';
import DateTimeRangePicker from '../../DateTimeRangePicker';

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

interface Props { 
  mapCenter?: MapLatLng
  currentCityId?: string;
}

const prepareHeatMapData = (heatMapData: ZoneHeatMapResponceRow[]) => {
  const pharsedData: ZoneHeatMap[] = [];
  for (let key in heatMapData) {
    const row = heatMapData[key];
    if (row?.lat && row?.lon) {
      pharsedData.push({
        location: new google.maps.LatLng(row.lat, row.lon), 
        weight: Number(row?.weight ?? 0)
      })
    }
  }
  return pharsedData;
}

const RentHeatMapSection = ({ mapCenter = defaultCenter, currentCityId }: Props) => {
  const dispatch = useDispatch();
  const checkPermission = usePermissions();
  const { zones, zoneHeatMap } = useSelector(selectZones);
  const [heatMapData, setHeatMapData] = useState<ZoneHeatMap[]>([]);
  const [allowZones, setAllowZones] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<DateRange<Moment | null>>([
    null,
    null
  ]);

  useEffect(() => {
    if (currentCityId) {
      // 2023-09-05T10:22:03.671Z
      const isValid = dateRange?.[0]?.isValid() && dateRange?.[1]?.isValid();
      const startDate = dateRange?.[0]?.format?.(DATE_FORMAT) ?? null;
      const endDate = dateRange?.[1]?.format?.(DATE_FORMAT) ?? null;
      dispatch(getCityHeatMapAsync(currentCityId, isValid ? startDate : null, isValid ? endDate : null));
    }
  }, [currentCityId, dateRange, dispatch]);

  useEffect(() => {
    const prepared = prepareHeatMapData(zoneHeatMap);
    setHeatMapData(prepared);
  }, [zoneHeatMap]);

  return (
      <>
        {checkPermission('get_heatmap') && (
          <Paper className={classes.blockContainer}>
            <Collapse
              classes={{
                labelRoot: classes.collapseLabel
              }}
              label='Мапа оренди'
            >
              <Grid item display={"flex"} flexDirection={"row"} justifyContent={"space-between"} flexWrap={"wrap"} marginLeft={2}>
                <DateTimeRangePicker
                  startText='Від'
                  endText='До'
                  value={dateRange}
                  onChange={(date: DateRange<Moment>) => {
                    setDateRange(date);
                  }}
                />
                <Button
                  className={classes.saveButton}
                  color='primary'
                  variant='contained'
                  onClick={() => setAllowZones(!allowZones)}
                >
                  {allowZones ? "Показати зони" : "Приховати зони"}
                </Button>
              </Grid>
              <GoogleMap
                center={mapCenter}
                mapContainerStyle={{
                  width: '100%',
                  height: 500,
                  marginTop: 20
                }}
                options={{
                  disableDefaultUI: true,
                  zoomControl: true,
                  fullscreenControl: true,
                  styles: darkMapStyles,
                }}
                zoom={11}
              >
                {allowZones && zones && zones?.map(zone => (
                  <Polygon
                    key={zone._id}
                    options={zonesColors[zone.type] || zonesColors.green}
                    paths={zone.coordinates.map(coordinate => ({
                      lat: coordinate[0],
                      lng: coordinate[1]
                    }))}
                    onDblClick={(e) => {
                      e.stop();
                    }}
                    onClick={(e) => {
                      e.stop();
                    }}
                  />
                ))}
                <HeatmapLayer 
                  data={heatMapData} 
                />
              </GoogleMap>
            </Collapse>
          </Paper>
        )}
      </>
  )
}

export default RentHeatMapSection;
