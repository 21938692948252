import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Switch,
  TextField,
  TextFieldProps
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Title from '../../components/Title';
import {
  createTimingTariffAsync,
  deleteTariffAsync,
  getTariffsAsync,
  selectBalance,
  updateTariffAsync
} from '../../redux/slices/balanceSlice';
import { InputChangeEvent } from '../../resources/utils';
import {
  getContractsStatusesAsync,
  selectContracts
} from '../../redux/slices/contractsSlice';
import { Tariff } from '../../resources/types/balanceTypes';
import TariffsTable from './TariffsTable';
import UserTypeSelect from '../../containers/UserTypeSelect';
import TableLagend from './TableLagend';
import InputMask from 'react-input-mask';
import { formatNumberToDate } from '../../resources/functions';

const PREFIX = 'TariffsPage';

const classes = {
  header: `${PREFIX}-header`,
  addButton: `${PREFIX}-addButton`,
  tableContainer: `${PREFIX}-tableContainer`
};

const Root = styled('div')({
  [`& .${classes.header}`]: {
    display: 'flex',
    alignItems: 'center'
  },
  [`& .${classes.addButton}`]: {
    marginLeft: 30
  },
  [`& .${classes.tableContainer}`]: {
    marginTop: 40
  }
});

const newTariff: Tariff = {
  userType: 'normal',
  price: '',
  type: '',
  name: '',
  maxTime: '',
  from: '',
  to: ''
};

const parsedIsWeekendMode = JSON.parse(
  String(localStorage.getItem('IS_WEEKEND_MODE'))
);

const WEEKENDS = 'weekends';
const WEEKDAYS = 'weekdays';

const TariffsPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTariffsAsync());
    dispatch(getContractsStatusesAsync());
  }, [dispatch]);

  const { tariffs } = useSelector(selectBalance);
  const contracts = useSelector(selectContracts);

  const [open, setOpen] = useState(false);
  const [selectedTariff, setSelectedTariff] = useState<Tariff | null>(null);
  const [isWeekendMode, setIsWeekendMode] = useState<boolean>(
    parsedIsWeekendMode === WEEKENDS
  );
  const [isWeekendTariffState, setIsWeekendTariffState] = useState<
    Record<string, boolean>
  >({});
  const [isCurrentTarrifOpened, setIsCurrentTarrifOpened] =
    useState<boolean>(false);
  const [filteredTariffsTimingInterval, setFilteredTariffsTimingInterval] =
    useState<Tariff[] | []>([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<Tariff | null>();
  const [tariffTime, setTariffTime] = useState<{
    from: string | number | undefined;
    to: string | number | undefined;
  }>({ from: '', to: '' });
  const [isShowedTimeFields, setIsShowedTimeFields] = useState<boolean>(false);

  useEffect(() => {
    selectedTimeSlot &&
      setTariffTime({ from: selectedTimeSlot?.from, to: selectedTimeSlot?.to });
  }, [selectedTimeSlot]);

  const toggleWeekendMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isWeekends = event.target.checked;

    setIsWeekendMode(isWeekends);

    toggleWeekendTariff(event, selectedTariff?._id as string);

    localStorage.setItem(
      'IS_WEEKEND_MODE',
      JSON.stringify(isWeekends ? WEEKENDS : WEEKDAYS)
    );

    tariffs.items.map(tariff => {
      if (!tariff.from && !tariff.to) {
        return dispatch(
          updateTariffAsync(
            tariff.type,
            {
              ...tariff,
              price: isWeekends
                ? (tariff.weekendsPrice as number)
                : (tariff.weekdaysPrice as number),
              currentMode: isWeekends ? WEEKENDS : WEEKDAYS
            },
            () => {}
          )
        );
      }
      return null;
    });
  };

  const toggleWeekendTariff = (
    event: React.ChangeEvent<HTMLInputElement>,
    tariffId: string
  ) => {
    const isWeekends = event.target.checked;

    setIsWeekendMode(isWeekends);
    setIsWeekendTariffState(prev => ({
      ...prev,
      [tariffId]: event.target.checked
    }));
  };

  const toggleDialog = useCallback(
    (tariff: Tariff | null) => () => {
      setOpen(prevState => !prevState);
      if (tariff !== null) {
        setSelectedTariff(tariff);
        setIsCurrentTarrifOpened(true);
      } else {
        setSelectedTariff(newTariff);
      }
    },
    []
  );

  const handleChangeTariff =
    (prop: keyof Tariff, price?: boolean) => (e: InputChangeEvent) => {
      if (prop === 'from' || 'to') {
        setTariffTime({ ...tariffTime, [prop]: e.target.value });
      }
      if (selectedTariff) {
        setSelectedTariff({
          ...selectedTariff,
          [prop]: e.target.value,
          price: price ? e.target.value : selectedTariff.price
        });
      }
    };

  const updateTariff = () => {
    if (selectedTariff) {
      const tarrifStartFrom =
        selectedTariff?.from && String(selectedTariff?.from).replace(':', '');
      const tarrifFinishTo =
        selectedTariff?.to && String(selectedTariff?.to).replace(':', '');
      const reqBody = {
        ...selectedTariff,
        ...(isWeekendTariffState[selectedTariff._id as string]
          ? { weekendsPrice: selectedTariff.price }
          : { weekdaysPrice: selectedTariff.price }),
        currentMode: isWeekendTariffState[selectedTariff._id as string]
          ? WEEKENDS
          : WEEKDAYS,
        from: tarrifStartFrom,
        to: tarrifFinishTo
      };
      if (tarrifStartFrom && tarrifFinishTo && !selectedTimeSlot) {
        dispatch(
          createTimingTariffAsync(selectedTariff.type, reqBody, () => {
            setOpen(false);
            setIsCurrentTarrifOpened(false);
          })
        );
      } else {
        dispatch(
          updateTariffAsync(selectedTariff.type, reqBody, () => {
            setOpen(false);
            setIsCurrentTarrifOpened(false);
            setTariffTime({ from: '', to: '' });
          })
        );
      }
    }
  };

  const deleteTariff = (_id: string | undefined) => {
    dispatch(deleteTariffAsync(_id!));
  };

  const normalTariffs = useMemo(
    () => tariffs.items.filter(item => item.userType === 'normal'),
    [tariffs.items]
  );

  const vipTariffs = useMemo(
    () => tariffs.items.filter(item => item.userType === 'vip'),
    [tariffs.items]
  );

  useEffect(() => {
    setFilteredTariffsTimingInterval(
      selectedTariff?.userType === 'normal'
        ? normalTariffs.filter(el => {
            return (
              selectedTariff?.name === el.name &&
              selectedTariff?.type === el.type &&
              el.from &&
              el.to
            );
          })
        : vipTariffs.filter(el => {
            return (
              selectedTariff?.name === el.name &&
              selectedTariff?.type === el.type &&
              el.from &&
              el.to
            );
          })
    );
  }, [selectedTariff, normalTariffs, vipTariffs]);

  const addTimeFields = () => {
    setIsShowedTimeFields(true);
    setTariffTime({ from: '', to: '' });
    setSelectedTimeSlot(null);
  };

  const handleClickOnTimeInterval = (_id: string | undefined) => {
    setSelectedTimeSlot(
      filteredTariffsTimingInterval.find(el => el._id === _id)
    );
  };

  return (
    <Root>
      <div className={classes.header}>
        <Title>Тарифи</Title>

        <Button
          className={classes.addButton}
          color='primary'
          startIcon={<AddIcon />}
          variant='text'
          onClick={toggleDialog(null)}
        >
          Додати
        </Button>

        <FormGroup sx={{ marginLeft: 'auto' }}>
          <FormControlLabel
            control={
              <Switch checked={isWeekendMode} onChange={toggleWeekendMode} />
            }
            label='Вихідні дні'
          />
        </FormGroup>
      </div>

      {Boolean(normalTariffs.length) && (
        <div className={classes.tableContainer}>
          <Title variant='h6'>Налаштування для звичайного клієнта</Title>
          <TableLagend />
          <TariffsTable
            items={normalTariffs}
            openDialog={toggleDialog}
            isWeekendMode={isWeekendMode}
            onDelete={deleteTariff}
          />
        </div>
      )}

      {Boolean(vipTariffs.length) && (
        <div className={classes.tableContainer}>
          <Title variant='h6'>Налаштування для VIP клієнта</Title>
          <TableLagend />
          <TariffsTable
            items={vipTariffs}
            openDialog={toggleDialog}
            isWeekendMode={isWeekendMode}
            onDelete={deleteTariff}
          />
        </div>
      )}

      <Dialog
        fullWidth
        maxWidth='sm'
        open={open}
        onClose={() => {
          setOpen(false);
          setTariffTime({ from: '', to: '' });
          setIsCurrentTarrifOpened(false);
          setSelectedTimeSlot(null);
          setIsShowedTimeFields(false);
        }}
      >
        <DialogTitle>
          Налаштування тарифу
          <FormGroup sx={{ position: 'absolute', top: 16, right: 24 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={
                    isWeekendTariffState[selectedTariff?._id as string] || false
                  }
                  onChange={event => {
                    toggleWeekendTariff(event, selectedTariff?._id as string);
                  }}
                />
              }
              label='Вихідні дні'
            />
          </FormGroup>
        </DialogTitle>
        <DialogContent>
          <Box mb={2}>
            {filteredTariffsTimingInterval.map(el => {
              return isWeekendMode === true && el.currentMode === 'weekends' ? (
                <Chip
                  key={el._id}
                  label={`${formatNumberToDate(el.from)}-${formatNumberToDate(
                    el.to
                  )}`}
                  variant='outlined'
                  sx={{
                    borderColor: '#9C27B0',
                    cursor: 'pointer',
                    marginRight: 1,
                    marginBottom: 1
                  }}
                  onClick={() => handleClickOnTimeInterval(el._id)}
                  onDelete={() => deleteTariff(el._id)}
                />
              ) : isWeekendMode === false && el.currentMode === 'weekdays' ? (
                <Chip
                  key={el._id}
                  label={`${formatNumberToDate(el.from)}-${formatNumberToDate(
                    el.to
                  )}`}
                  variant='outlined'
                  sx={{
                    borderColor: '#2FCB75',
                    cursor: 'pointer',
                    marginRight: 1,
                    marginBottom: 1
                  }}
                  onClick={() => handleClickOnTimeInterval(el._id)}
                  onDelete={() => deleteTariff(el._id)}
                />
              ) : (
                ''
              );
            })}
          </Box>
          <TextField
            fullWidth
            label='Назва'
            value={selectedTariff?.name}
            onChange={handleChangeTariff('name')}
            disabled={!!selectedTimeSlot}
          />
          <TextField
            fullWidth
            label='Ціна'
            margin='normal'
            type='number'
            disabled={!!selectedTimeSlot}
            value={
              selectedTimeSlot
                ? selectedTimeSlot.price
                : isWeekendTariffState[selectedTariff?._id as string]
                ? selectedTariff?.weekendsPrice
                : selectedTariff?.weekdaysPrice
            }
            onChange={handleChangeTariff(
              isWeekendTariffState[selectedTariff?._id as string]
                ? 'weekendsPrice'
                : 'weekdaysPrice',
              true
            )}
          />
          <UserTypeSelect
            value={selectedTariff?.userType}
            onChange={handleChangeTariff('userType')}
          />
          <TextField
            fullWidth
            select
            disabled={Boolean(selectedTariff?._id)}
            label='Тип'
            margin='normal'
            value={selectedTariff?.type}
            onChange={handleChangeTariff('type')}
          >
            {contracts.statuses.items.map(status => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </TextField>
          {((isCurrentTarrifOpened && selectedTimeSlot) ||
            isShowedTimeFields) && (
            <Box>
              <InputMask
                mask='99:99'
                value={formatNumberToDate(tariffTime.from!)}
                onChange={handleChangeTariff('from')}
              >
                {(inputProps: JSX.IntrinsicAttributes & TextFieldProps) => (
                  <TextField
                    {...inputProps}
                    label='З'
                    margin='normal'
                    sx={{ marginRight: 5 }}
                  ></TextField>
                )}
              </InputMask>
              <InputMask
                mask='99:99'
                value={formatNumberToDate(tariffTime.to!)}
                onChange={handleChangeTariff('to')}
              >
                {(inputProps: JSX.IntrinsicAttributes & TextFieldProps) => {
                  return (
                    <TextField
                      {...inputProps}
                      label='До'
                      margin='normal'
                    ></TextField>
                  );
                }}
              </InputMask>
            </Box>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: isShowedTimeFields ? 'flex-end' : 'space-between'
          }}
        >
          {!isShowedTimeFields && (
            <Button color='primary' onClick={addTimeFields}>
              Додати підтариф з часовими проміжками
            </Button>
          )}
          <Button color='primary' onClick={updateTariff}>
            Зберегти
          </Button>
        </DialogActions>
      </Dialog>
    </Root>
  );
};

export default TariffsPage;
