import React from 'react';
import logoImg from '../../img/logo.png';
import styled from 'styled-components';
import { Props } from './interface';

const Container = styled.div<{ alignSelf?: Props['alignSelf'] }>`
  flex: none;
  display: inline-flex;
  align-self: ${props => props.alignSelf};
  width: 200px;
  height: 130px;

  img {
    width: 100%;
    height: 100%;
  }
`;

const Logo = (props: Props) => {
  const { alignSelf } = props;

  return (
    <Container alignSelf={alignSelf}>
      <img alt='logo' src={logoImg} />
    </Container>
  );
};

export default Logo;
