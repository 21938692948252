import React from 'react';

const DEV_HOSTS = ['localhost:3000', 'ecosdev.top'];

const DevLabel = () => {
  console.log(window.location.host);

  if (!DEV_HOSTS.includes(window.location.host)) {
    return null;
  }

  return (
    <div
      style={{
        position: 'absolute',
        width: 160,
        height: 20,
        backgroundColor: '#FF004D',
        top: 25,
        right: -45,
        transform: 'rotate(45deg)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold'
      }}
    >
      DEV
    </div>
  );
};

export default DevLabel;
