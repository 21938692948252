import { Tab } from './types/TabsTypes';
import moment from 'moment';

export function setLocalStorage(key: string, value: any) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function getLocalStorage<T, D = null>(
  key: string,
  defaultValue?: D
): T | D {
  const value = localStorage.getItem(key);

  return value ? JSON.parse(value) : defaultValue || null;
}

export const formatDate = (
  date?: Date | string | null,
  options?: { time?: boolean }
) => {
  if (!date) return '';
  let format = 'DD.MM.YYYY';
  if (options?.time) format += ' HH:mm';
  return moment(date).format(format);
};

export const formatNumberToDate = (number: string | number) => {
  return String(number).length === 3
    ? '0' + String(number).slice(0, 1) + ':' + String(number).slice(-2)
    : String(number).slice(0, 2) + ':' + String(number).slice(-2);
};

export const handleDefaultErrors = (error: any) => {
  const { response } = error;

  if (response) {
    alert(response.data?.message || 'Помилка сервера');
  } else {
    alert('Помилка');
  }
};

export const checkPermission = (tabs: Tab[], code: string) =>
  tabs.findIndex(tab => tab.code === code) !== -1;

export const canConvertToNumber = (str: string): boolean => {
  return !isNaN(Number(str));
}