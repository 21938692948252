import { Scooter } from '../../resources/types/scootersTypes';

export interface Props {}

export interface ScootersModalState {
  open: boolean;
  scooter?: Scooter;
}

export enum ScooterStatus {
  AVAILABLE = 'AVAILABLE',
  TRANSPORTING = 'TRANSPORTING',
  CHARGING = 'CHARGING',
  MAINTENANCE = 'MAINTENANCE',
  BROKEN_IN_CITY = 'BROKEN_IN_CITY',
  LOW_BATTERY = 'LOW_BATTERY',
  HIDDEN = 'HIDDEN'
}

export type ScooterColorKeys = ScooterStatus | 'all' | 'free' | 'unlocked' | 'inRent' | 'offline' | 'discharged' | 'hidden';

export interface MapFilters {
  offline?: boolean;
  status?: string;
  free?: boolean | string;
  unlocked?: boolean;
}

export interface MapLatLng {
  lat: number,
  lng: number
}