import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { formatDate } from '../../resources/functions';
import TableCell from '../TableCell';
import Title from '../Title';
import { ScootersLockHistoryTableProps } from './ScootersLockHistoryTableTypes';

const ScootersLockHistoryTable = ({
  isLocked,
  rows,
  isScooterId
}: ScootersLockHistoryTableProps) => {
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <Title variant='h5'>{isLocked ? 'Заблоковані' : 'Розблоковані'}</Title>
      </Grid>
      <Grid item>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Самокат</TableCell>
                <TableCell>Час</TableCell>
                <TableCell>Статус</TableCell>
                {!isScooterId && <TableCell>Користувач</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row._id}>
                  <TableCell>{row.scooterId?.name}</TableCell>
                  <TableCell>
                    {formatDate(row.createdAt, { time: true })}
                  </TableCell>
                  <TableCell>
                    {row?.newStatus?.length === 0
                      ? row.lock
                        ? 'BLOCKED'
                        : 'UNLOCKED'
                      : row?.newStatus ?? ''}
                  </TableCell>
                  {/* users and admins */}
                  {/* {!isScooterId && (
                    <TableCell>
                      {row.type === 'user'
                        ? typeof row?.contractId === 'string'
                          ? row?.contractId
                          : `${row?.contractId?.user?.firstName}: ${row?.contractId?.user?.phone}`
                        : row?.adminId?.login}
                    </TableCell>
                  )} */}
                  <TableCell>
                    {row?.adminId ? row?.adminId?.login ?? row.type : ''}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default ScootersLockHistoryTable;
