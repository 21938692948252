import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import axios from '../../resources/axios';
import { Zone, ZoneState, ZoneMarker, ZoneHeatMapResponceRow, ZoneConfigResponceRow } from '../../resources/types/zonesTypes';
import { handleDefaultErrors } from '../../resources/functions';

interface ZonesState {
  zones: Zone[];
  zoneMarkers: ZoneMarker[];
  types: string[];
  zone: ZoneState;
  shouldUpdate: boolean;
  zoneHeatMap: ZoneHeatMapResponceRow[]
  zonesConfig: ZoneConfigResponceRow[]
}

const initialZoneState: ZoneState = {
  coordinates: [[]],
  type: 'green',
  name: '',
  cityId: '',
  prohibitStop: false,
};

const initialState: ZonesState = {
  zones: [],
  zoneMarkers: [],
  types: [],
  shouldUpdate: true,
  zone: initialZoneState,
  zoneHeatMap: [],
  zonesConfig: [],
};

export const zonesSlice = createSlice({
  name: 'zones',
  initialState,
  reducers: {
    getZones: (state, action) => {
      state.zones = action.payload.zones;
      state.shouldUpdate = false;
    },
    updateZones: state => {
      state.shouldUpdate = true;
    },
    updateZone: (state, action) => {
      state.zones[action.payload.index] = {
        ...state.zones[action.payload.index],
        ...action.payload.data
      };
      state.shouldUpdate = true;
    },
    getZoneTypes: (state, action) => {
      state.types = action.payload.data;
    },
    setZoneState: (state, action: PayloadAction<Partial<ZoneState>>) => {
      state.zone = Object.assign(state.zone, action.payload);
    },
    clearZoneState: state => {
      state.zone = initialZoneState;
    },
    getZoneMarkers: (state, action) => {
      state.zoneMarkers = action.payload;
    },
    setZoneHeatMap: (state, action) => {
      state.zoneHeatMap = action.payload;
    },
    setZoneConfig: (state, action) => {
      state.zonesConfig = action.payload;
    },
  }
});

const { getZones, updateZones, getZoneTypes, getZoneMarkers, setZoneHeatMap, setZoneConfig } = zonesSlice.actions;

export const { setZoneState, clearZoneState } = zonesSlice.actions;

export const getZonesAsync = (forceUpdate?: boolean): AppThunk => async (dispatch, getState) => {
  if (!getState().zones.shouldUpdate && !forceUpdate) return;
  try {
    const response = await axios.get('/zones');
    dispatch(getZones(response.data));
  } catch (error) {
    handleDefaultErrors(error);
  }
};

export const updateZonesAsync = (
  zonesData: Zone[]
): AppThunk => async dispatch => {
  try {
    await axios.put('/zones', { zonesData });
    dispatch(updateZones());
    await dispatch(getZonesAsync());
  } catch (error) {
    handleDefaultErrors(error);
  }
};

export const parseZonesAsync = async (
  fileName: string,
  success: (zones: Zone[]) => void
) => {
  try {
    const response = await axios.put('/zones/parse_zone', { fileName });
    success(response.data.zonesData);
  } catch (error) {
    handleDefaultErrors(error);
  }
};

export const updateZone = (
  index: number,
  data: Partial<Zone>
): AppThunk => dispatch => {
  dispatch(zonesSlice.actions.updateZone({ index, data }));
};

export const getZoneTypesAsync = (): AppThunk => async dispatch => {
  const response = await axios.get('/zones/zone_types');
  dispatch(getZoneTypes(response.data));
};

export const createZoneAsync = (data: ZoneState): AppThunk => async () => {
  await axios.post('/zones', data);
};

export const updateZoneAsync = (
  id: string,
  data: ZoneState
): AppThunk => async () => {
  await axios.put(`/zones/${id}`, data);
};

export const deleteZoneAsync = (id: string): AppThunk => async () => {
  await axios.delete(`/zones/${id}`);
};

export const getZoneMarkersAsync = (): AppThunk => async dispatch => {
  const response = await axios.get('/zones/markers');
  dispatch(getZoneMarkers(response?.data?.markers ?? []));
};

export const getCityHeatMapAsync = (cityId: string, from: string | null, to: string | null): AppThunk => async dispatch => {
  const dateDuration = from && to ? `&dateFrom=${from}&dateTo=${to}` : '';
  const response = await axios.get(`/zones/contract_start_coords?cityId=${cityId}${dateDuration}`);
  dispatch(setZoneHeatMap(response?.data?.data ?? []));
};

export const createZoneMarkerAsync = (data: ZoneMarker): AppThunk => async dispatch => {
  await axios.post('/zones/markers', data).then(() => {
    dispatch(getZoneMarkersAsync());
  });
};

export const updateZoneMarkerAsync = (
  id: string,
  data: ZoneMarker
): AppThunk => async dispatch => {
  await axios.put(`/zones/markers/${id}`, data).then(() => {
    dispatch(getZoneMarkersAsync());
  });
};

export const deleteZoneMarkerAsync = (id: string): AppThunk => async dispatch => {
  await axios.delete(`/zones/markers/${id}`).then(() => {
    dispatch(getZoneMarkersAsync());
  });
};

export const getCityZonesConfigAsync = (cityId: string): AppThunk => async dispatch => {
  const response = await axios.get(`/zones/config?cityId=${cityId}`);
  dispatch(setZoneConfig(response?.data?.data ?? []));
};

export const setCityZonesConfigAsync = (cityId: string, data: ZoneConfigResponceRow): AppThunk => async dispatch => {
  await axios.put(`/zones/config`, data).then(() => {
    dispatch(getCityZonesConfigAsync(cityId));
  });
};

export const selectZones = (state: RootState) => state.zones;

export default zonesSlice.reducer;
