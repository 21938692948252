import axios from 'axios';
import { STORE_CURRENT_CITY, STORE_USER_INFO } from './storageVariables';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  responseType: 'json'
});

api.interceptors.response.use(
  response => response,
  error => {
    const { response } = error;

    if (response) {
      if (response.status === 401) {
        localStorage.removeItem(STORE_USER_INFO);
        localStorage.removeItem(STORE_CURRENT_CITY);
        delete axios.defaults.headers.common['x-access-token'];
        delete axios.defaults.headers.common['city'];
        if (window.location.pathname !== '/') {
          window.location.reload();
        }
      }
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default api;
