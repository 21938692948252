import React from 'react';

const TrackitPage = () => {
  return (
    <iframe
      title='trackit'
      src='https://trackit.flespi.io/'
      style={{
        width: 'calc(100% + 48px)',
        height: '100vh',
        border: 'none',
        margin: -24,
        padding: 0,
        overflow: 'hidden'
      }}
    />
  );
};

export default TrackitPage;
