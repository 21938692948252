import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import axios from '../../resources/axios';
import { handleDefaultErrors } from '../../resources/functions';
import { Promocode } from '../../resources/types/balanceTypes';

interface PromocodesSlice {
  items: Promocode[],
  shouldUpdate: boolean,
  limit: number;
  page: number;
  total: number;
}

const initialState: PromocodesSlice = {
  items: [],
  shouldUpdate: true,
  limit: 20,
  page: 1,
  total: 1,
};

export const promocodesSlice = createSlice({
  name: 'promocodes',
  initialState,
  reducers: {
    getPromocodes: (state, { payload }) => {
      state.items = payload?.items ?? [];
      state.shouldUpdate = false;
      state.limit = payload?.limit ?? 20;
      state.page = payload?.page ?? 1;
      state.total = payload?.count ?? 1;
    },
  }
});

const {
  getPromocodes,
} = promocodesSlice.actions;


export const getPromocodesAsync =
  (params: { page: number; limit: number; includeReferal?: boolean}): AppThunk => async (dispatch) => {
    try {
      const response = await axios.get('/bonuscodes', { params: { ...params, type: `addBalance,subscription${params?.includeReferal ? ',referal' : ''}`} });
      console.log('-=- feedbacks res', response)
      console.log('-=- feedbacks res', response.data.pagination)
      dispatch(getPromocodes({
        items: response?.data?.data?.bonusCodes ?? [],
        ...response?.data?.pagination,
      }));
    } catch (error) {
      handleDefaultErrors(error);
    }
  };

export const selectPromocodes = (state: RootState) => state.promocodes;

export default promocodesSlice.reducer;
